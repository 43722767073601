import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
    <footer className="footer">
  <Container fluid={true}>
    <Row>
      <Col xs={12} sm={6} className="text-center text-sm-start">
        {new Date().getFullYear()} © LuxBilling.
      </Col>
      <Col xs={12} sm={6} className="text-center text-sm-end mt-3 mt-sm-0">
        <div className="text-sm-end d-none d-sm-block">
          Crafted with <i className="mdi mdi-heart text-danger"></i> by Dev4Health
        </div>
      </Col>
    </Row>
  </Container>
</footer>
    </React.Fragment>

  );
}

export default Footer;