// Imports

import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
import { Container } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print'
import ReactSelect from "react-select";

import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useAlert } from "react-alert";
import PrintIcon from '@mui/icons-material/Print';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ButtonGroup from '@mui/material/ButtonGroup'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import Tooltip from '@mui/material/Tooltip';
import Documents from '../Documents'
import Headroom from "react-headroom";
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import OSAPAGE from '../Osapage'
import DialogTitle from '@mui/material/DialogTitle';
import Prior_auth from '../Prior_auth'
import VOBPAGE1 from '../Vobpage1'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import Moment from 'moment'
import InputMask from 'react-input-mask';
import logoLight from "../../assets/images/logo-removebg-preview.png";

import {
  Modal,
  Typography,
  TextField,
  Divider,
  NativeSelect,
  Autocomplete,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AuthorizationRegistration from "../AuthorizationRegistration";
import "../Button.css"
import { ArrowDropDown, ArrowDropDownCircle, ImportExport } from '@mui/icons-material'
import Popover from '@mui/material/Popover';
import Tmdform from "../Tmdform";
import AuthorizationFormByCPT from "../AuthorizationFormByCPT";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const Authorization = (props) => {

  document.title = "Authorization | LuxBilling";

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const [isAllPractics, setisAllPractics] = useState(false)
console.log(isAllPractics,'isAllPractics___')
  const [patientID, setPatientID] = React.useState("");
  const [columnID, setColumnID] = React.useState("");
  const [columnIDTMD, setColumnIDTMD] = React.useState("");
  const [columnIDICD, setColumnIDICD] = React.useState("");
  const [AutpattientIcd, setAutpattientIcd] = React.useState("");
  const [AutpattientIcd1, setAutpattientIcd1] = React.useState("");
  const [approvedcpt, setapprovedcpt] = React.useState(false);
  const [typeOfprocedureCode1, settypeOfprocedureCode1] = useState("");

  console.log('eeeetypeOfprocedureCode1__+', typeOfprocedureCode1)


  console.log('AutpattientIcd', AutpattientIcd)


  const [tmdcolumnID, settmdColumnID] = React.useState("");
  const [AutpattientID, setAutpattientID] = React.useState("");
  const [patientId, setpatientId] = useState(props.PatientId !== undefined ? props.PatientId : 0)
  const testpateint = props.PatientName  // comes From patient Registration

  const [patientName, setPatientName] = React.useState("");
  const [patientNametmd, setPatientNametmd] = React.useState("");
  const [patientNameicd, setPatientNameicd] = React.useState("");
  const [patientNameicd1, setPatientNameicd1] = React.useState("");
  const [PatientdateTMD, setPatientdateTMD] = React.useState("");


  console.log('patient Registration', testpateint);
  console.log('patientNametmd', patientNametmd);


  const [patientNamee, setPatientNamee] = React.useState("");
  const [AuthRegistration, setAuthRegistration] = React.useState(null);


  console.log('patientNameeee', patientNamee)

  const [AutpattientID1, setAutpattientID1] = React.useState("");
  const [AuthRequestid, setAuthRequestid] = React.useState("");
  const [OsaRequestid, setOSARequestid] = React.useState("");
  const [HealthRequestid, setHealthRequestid] = React.useState("");
  const [LomnRequestid, setLomnRequestid] = React.useState("");



  const [Markaporoved, setMarkaporoved] = React.useState(false);
  const [dobAuth, setdobAuth] = React.useState("");
  const [dosValues, setDosValues] = useState([]);
  const [Refresh, setRefresh] = useState(false)
  const getData = (data) => {
    // Handle the data in the parent component
    console.log('Received data in parent:', data);
    // You can do additional processing or set state here
    // For example, setDosValues(data);
  };


  const alert = useAlert();

  const [isLoading, setLoading] = useState(false)


  // const validationSchema = Yup.object({
  //   firstName: Yup.string().required('First Name is required'),

  // })

  const [AuthRequest, setAuthRequest] = React.useState(false);
  const handleAuthRequest = () => {
    setAuthRequest(true)
  };
  const setAuthId = (_id) => {
    setColumnID(_id)
  };
  const handlecloseAuthRequest = () => {
    setColumnID('')
    setAuthRequestid('')
    setPatientNamee('')

    //. 
    const postData = AuthRegistration
    postData.authRequest = true;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization?patientId=${patientID}`,
        postData,
        { headers }
      )

      .then((response) => {


      })
      .catch((error) => {
        // setdisable(true);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });

    setAuthRequest(false)
  };

  const [osaRequest, setOsaRequest] = React.useState(false);
  const handleOsaRequest = () => {
    setOsaRequest(true)
  };
  const handlecloseOsaRequest = () => {
    setColumnID('')
    setOSARequestid('')
    setPatientNamee('')
    const postData = AuthRegistration
    postData.questionnaire = true;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization?patientId=${patientID}`,
        postData,
        { headers }
      )

      .then((response) => {


      })
      .catch((error) => {
        // setdisable(true);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
    setOsaRequest(false)
  };
  const [healthRequest, setHealthRequest] = React.useState(false);
  const handleHealthRequest = () => {
    setHealthRequest(true)
  };
  const handlecloseHealthRequest = () => {
    setColumnID('')
    setHealthRequestid('')
    setPatientNamee('')
    const postData = AuthRegistration
    postData.healthHistory = true;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization?patientId=${patientID}`,
        postData,
        { headers }
      )

      .then((response) => {


      })
      .catch((error) => {
        // setdisable(true);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
    setHealthRequest(false)
  };

  const [lomnRequest, setLomnRequest] = React.useState(false);
  const handleLomnRequest = () => {
    setLomnRequest(true)
  };
  const handlecloseLomnRequest = () => {
    setColumnID('')
    setLomnRequestid('')
    setPatientNamee('')
    const postData = AuthRegistration
    postData.lomn = true;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization?patientId=${patientID}`,
        postData,
        { headers }
      )

      .then((response) => {


      })
      .catch((error) => {
        // setdisable(true);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
    setLomnRequest(false)
  };
  ////cptopen
  const [approvecpt, setapprovecpt] = React.useState(false);
  const handleapprovecpt = () => {
    setapprovecpt(true)
  };
  const handlecloseapprovecpt = () => {
    setColumnIDICD('')
    setAutpattientIcd('')
    setPatientNameicd('')


    setapprovecpt(false)
  };
  ////
  const [approve, setapprove] = React.useState(false);
  const handleapprove = () => {
    setapprove(true)
  };

  const handlecloseapprove = () => {
    setColumnID('')
    setAutpattientID1('')
    setPatientNamee('')

    setapprove(false)
  };


  const fields = [
    "id",
    "dos",
    "patientId",
    "patientInfo",
    "healthHistory",
    "questionnaire",
    "progressNotes",
    "AuthRequest",
    "approved",
    "patientName"
  ];
  const defValues = {
    dos: "",
    patientId: 0,
    patientInfo: "",
    healthHistory: "",
    questionnaire: "",
    progressNotes: "",
    AuthRequest: "",
    approved: "",
    patientName: "",
  };
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [Delbyid, setDelbyid] = useState(false);
  const handleDelete = (cell) => {
    // Perform your delete logic here
    const postData = {

      id: Delbyid,
      inactive: true,
    };
    axios
    .post(`${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization`, postData, {
      headers,
    })
      .then((response) => {
        setrefresh(!refresh);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    // Close the dialog
    setDialogOpen(false);
    setrefreshcpt(true)
  };
  const handleDelete1 = (cell) => {
    // Perform your delete logic here
    const postData = {

      id: Delbyid,
      inactive: true,
    };
    axios
    .post(`${process.env.REACT_APP_API_URL}/PatientNote/AddAuthorizationFormByCPT`, postData, {
      headers,
    })
      .then((response) => {
        setrefresh(!refresh);
        setrefreshcpt(true)
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    // Close the dialog
    setDialogOpen(false);
  };
  //////cptudeeffect
  useEffect(() => {
    if (AutpattientIcd > 0 && patientNameicd.length > 0) {
      handleapprovecpt()
    }


  }, [AutpattientIcd, patientNameicd]);
  ////

  useEffect(() => {
    if (AutpattientID1 > 0 && patientNamee.length > 0) {
      handleapprove()
    }


  }, [AutpattientID1, ]);
  useEffect(() => {
    if (AuthRequestid > 0 && patientNamee.length > 0) {
      handleAuthRequest()
    }


  }, [AuthRequestid, patientNamee]);
  useEffect(() => {
    if (OsaRequestid > 0 && patientNamee.length > 0) {
      handleOsaRequest()
    }


  }, [OsaRequestid, patientNamee]);
  useEffect(() => {
    if (HealthRequestid > 0 && patientNamee.length > 0) {
      handleHealthRequest()
    }


  }, [HealthRequestid, patientNamee]);
  useEffect(() => {
    if (LomnRequestid > 0 && patientNamee.length > 0) {
      handleLomnRequest()
    }


  }, [LomnRequestid, patientNamee]);
  const UserType = localStorage.getItem('User_Type')
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.3,
    },
    {
      field: "PatientName",
      headerName: "Patient Name",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.7,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setColumnID(cellValues.row.id)
            setAutpattientID(cellValues.row.patientId)
            setPatientName(cellValues.row.PatientName)
            setOpenModal(true)
          }}
        >{`${cellValues.row.PatientName}`}</Button>
      ),
    },
    {
      field: "patientInfo",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Info",
      flex: 0.5,
      hide: false,
      renderCell: (params) =>
        params.row.patientInfo ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        ),
    },
    {
      field: "AuthRequest",
      headerClassName: "super-app-theme--header",
      headerName: "Auth Request",
      // minWidth: 145,
      flex: 0.5,
      hide: false,
      // renderCell: (params) =>
      // params.row.AuthRequest ? (
      //   <CheckIcon style={{ color: "green" }} />
      // ) : (
      //   <ClearIcon style={{ color: "red" }} />
      // ),

      renderCell: (cell) => {
        if (cell.row.AuthRequest) {

          return (
            <TaskAltIcon
              onClick={(e) => {
                setColumnID(cell.row.id)
                setAuthRequestid(cell.row.patientId)
                setPatientNamee(cell.row.PatientName)
                setMarkaporoved(cell.row.approved)
                setAuthRegistration(cell.row)
                // handleapprove()
              }}
              style={{
                // marginRight: "5px",
                // float: "right",
                // marginTop: "5px",
                color: "green",

                // cursor: "not-allowed",
              }}></TaskAltIcon>
          );
        } else {
          // If approved is false, render the button that can be clicked
          return (
            <>

              <TaskAltIcon
                style={{
                  marginRight: "5px",
                  float: "right",
                  marginTop: "5px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={(cellValues) => {

                  setColumnID(cell.row.id)
                  setAuthRequestid(cell.row.patientId)
                  setPatientNamee(cell.row.PatientName)
                  setMarkaporoved(cell.row.approved)
                  setAuthRegistration(cell.row)

                }}
              ></TaskAltIcon>
            </>
          );
        }
      },

    },
    {
      field:  isAllPractics==true? "PracticeName":'',
      headerName: isAllPractics==true?"Practice Name":'',
      headerClassName: "super-app-theme--header",
      cellClassName: isAllPractics==true?"gridCell":''  ,
      hide: false,   
      sortable: false,
      flex: isAllPractics==true?0.6:'',
    },
    {
      field: "questionnaire",
      headerClassName: "super-app-theme--header",
      headerName: "Questionnaire",
      flex: 0.7,
      // renderCell: (params ) =>
      //   params.row.questionnaire ? (
      //     <CheckIcon style={{ color: "green" }} />
      //   ) : (
      //     <ClearIcon style={{ color: "red" }} />
      //   ),
      renderCell: (cell) => {
        if (cell.row.questionnaire) {

          return (
            <TaskAltIcon
              onClick={(e) => {
                setColumnID(cell.row.id)
                setOSARequestid(cell.row.patientId)
                setPatientNamee(cell.row.PatientName)
                setMarkaporoved(cell.row.approved)
                setAuthRegistration(cell.row)

                // handleapprove()
              }}
              style={{
                // marginRight: "5px",
                // float: "right",
                // marginTop: "5px",
                color: "green",

                // cursor: "not-allowed",
              }}></TaskAltIcon>
          );
        } else {
          // If approved is false, render the button that can be clicked
          return (
            <>

              <TaskAltIcon
                style={{
                  marginRight: "5px",
                  float: "right",
                  marginTop: "5px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={(cellValues) => {

                  setColumnID(cell.row.id)
                  setOSARequestid(cell.row.patientId)
                  setPatientNamee(cell.row.PatientName)
                  setMarkaporoved(cell.row.approved)
                  setAuthRegistration(cell.row)

                }}
              ></TaskAltIcon>
            </>
          );
        }
      },

    },
    // {
    //   field: "HealthHistory",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Health History",
    //   flex: 0.7,
    //   hide: false,
    //   // renderCell: (params ) =>
    //   // params.row.HealthHistory ? (
    //   //   <CheckIcon style={{ color: "green" }} />
    //   // ) : (
    //   //   <ClearIcon style={{ color: "red" }} />
    //   renderCell: (cell) => {
    //     if (cell.row.HealthHistory) {

    //       return (
    //         <TaskAltIcon
    //           onClick={(e) => {
    //             setColumnID(cell.row.id)
    //             setHealthRequestid(cell.row.patientId)
    //             setPatientNamee(cell.row.PatientName)
    //             setMarkaporoved(cell.row.approved)
    //             setAuthRegistration(cell.row)

    //             // handleapprove()
    //           }}
    //           style={{
    //             // marginRight: "5px",
    //             // float: "right",
    //             // marginTop: "5px",
    //             color: "green",

    //             // cursor: "not-allowed",
    //           }}></TaskAltIcon>
    //       );
    //     } else {
    //       // If approved is false, render the button that can be clicked
    //       return (
    //         <>

    //           <TaskAltIcon
    //             style={{
    //               marginRight: "5px",
    //               float: "right",
    //               marginTop: "5px",
    //               color: "red",
    //               cursor: "pointer",
    //             }}
    //             onClick={(cellValues) => {

    //               setColumnID(cell.row.id)
    //               setHealthRequestid(cell.row.patientId)
    //               setPatientNamee(cell.row.PatientName)
    //               setMarkaporoved(cell.row.approved)
    //               setAuthRegistration(cell.row)

    //             }}
    //           ></TaskAltIcon>
    //         </>
    //       );
    //     }
    //   },
    //   // ),



    // },

    {
      field: "LOMN",
      headerClassName: "super-app-theme--header",
      headerName: "LOMN",
      flex: 0.5,
      hide: false,
      // renderCell: (params) =>
      //   params.row.LOMN ? (
      //     <CheckIcon style={{ color: "green" }} />
      //   ) : (
      //     <ClearIcon style={{ color: "red" }} />
      //   ),
      renderCell: (cell) => {
        if (cell.row.LOMN) {

          return (
            <TaskAltIcon
              onClick={(e) => {
                setColumnID(cell.row.id)
                setLomnRequestid(cell.row.patientId)
                setPatientNamee(cell.row.PatientName)
                setAuthRegistration(cell.row)

                // handleapprove()
              }}
              style={{
                // marginRight: "5px",
                // float: "right",
                // marginTop: "5px",
                color: "green",

                // cursor: "not-allowed",
              }}></TaskAltIcon>
          );
        } else {
          // If approved is false, render the button that can be clicked
          return (
            <>

              <TaskAltIcon
                style={{
                  marginRight: "5px",
                  float: "right",
                  marginTop: "5px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={(cellValues) => {

                  setColumnID(cell.row.id)
                  setLomnRequestid(cell.row.patientId)
                  setPatientNamee(cell.row.PatientName)

                  setAuthRegistration(cell.row)

                }}
              ></TaskAltIcon>
            </>
          );
        }
      },

    },


    {
      field: "Approve",
      headerName: "Approve",
      sortable: true,
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      renderCell: (cell) => {
        if (cell.row.approved) {
          // If approved is true, render a disabled button or some other content
          return (
            <TaskAltIcon
              onClick={(e) => {
                setColumnID(cell.row.id)
                setAutpattientID1(cell.row.patientId)
                setPatientNamee(cell.row.PatientName)
                setMarkaporoved(cell.row.approved)
                // handleapprove()
              }}
              style={{
                marginRight: "5px",
                float: "right",
                marginTop: "5px",
                color: "green",
                // cursor: "not-allowed",
              }}></TaskAltIcon>
          );
        } else {
          // If approved is false, render the button that can be clicked
          return (
            <>

              <TaskAltIcon
                style={{
                  marginRight: "5px",
                  float: "right",
                  marginTop: "5px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={(cellValues) => {

                  setColumnID(cell.row.id)
                  setAutpattientID1(cell.row.patientId)
                  setPatientNamee(cell.row.PatientName)
                  setMarkaporoved(cell.row.approved)
                }}
              ></TaskAltIcon>
            </>
          );
        }
      },
    },
    {
      field: 'delete',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cell) => (
       <>
       {UserType == 'Front Office' || UserType == 'Front Office Admin'?(<></>):(<>



       {UserType == 'Back Office Admin' || UserType == 'Back Office User'?(<></>):(<>
        <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
        }}
        color="black"
        onClick={() => {setDialogOpen(true)
          setDelbyid(cell.row.id)
        }}
      ></DeleteIcon>
       
       
       
       </>)}
       
       
       
       
       </>)}
      

      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {
            marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
          },
        }}
      >
       <div style={{
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center horizontally
  justifyContent: 'center', // Center vertically
  borderRadius: '8px',
  width: '500px'
}}>
  
  <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
  <DialogTitle style={{ fontWeight: 'bold',color:'black',fontSize:' 1.25rem' }}>Are you sure?</DialogTitle>
  <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',color:'#444444' }}>
    You won't be able to revert this!
  </h5>
  <DialogContent>
    <DialogContentText>

      <Grid style={{ marginTop: '1rem' }}>
        <Button onClick={() => setDialogOpen(false)} style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}>No,cancel!</Button>
        <Button  onClick={() => handleDelete(cell)} style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem',border:'1px solid #b4acec' }}>Yes,delete it!</Button>
      </Grid>
    </DialogContentText>
  </DialogContent>
</div>

      </Dialog>
    </>
      ),
    },
  ];
  const [Delbyid1, setDelbyid1] = useState(false);

  const handleDeleteById1 = () => {
    const postData = {

    
      id: Delbyid1,
      inactive: true,
    };

    axios.
      post(`${process.env.REACT_APP_API_URL}/PatientNote/AddTMDisorders`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {

        setrefresh(!refresh);
        setDialogOpen(false)      })
      .catch((error) => {
        console.error(
          "There was an error!",
          error
        );
      });
  };

  const tmdcolumns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      hide: false,
      sortable: false,
      flex: 0.1,
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      hide: false,
      sortable: false,
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="text"
          color="info"
          onClick={() => {
            setColumnIDTMD(cellValues.row.id)
            setAutpattientID(cellValues.row.patientId)
            setPatientNametmd(cellValues.row.patientName)
            setOpenModaltmd(true)
            console.log(cellValues.row, "testtoken__");
          }}
        >
          {cellValues.row.patientName}
        </Button>
      ),
    },
    {
      field: "dateTMD",
      headerName: "TMD Date",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      hide: false,   
      sortable: false,
      flex: 0.3,
    },
    {
      field:  isAllPractics==true?"PracticeName":'',
      headerName:  isAllPractics==true?"Practice Name":'',
      headerClassName: "super-app-theme--header",
      cellClassName: isAllPractics==true?"gridCell":'',
      hide: false,   
      sortable: false,
      flex: isAllPractics==true?0.3:'',
    },
    {
      field: "inactive",
      headerClassName: "super-app-theme--header",
      headerName: "",
      sortable: true,
      flex: 0.1,
      renderCell: (cell) => (
        <>
          <DeleteIcon
            style={{
              marginRight: "5px",
              float: "right",
              marginTop: "5px",
              color: "red",
              cursor: "pointer",
            }}
            onClick={() => {
              setDialogOpen(true);
              setDelbyid1(cell.row.id);
            }}
          ></DeleteIcon>

          <Dialog
            open={isDialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="delete-dialog-title"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center horizontally
                justifyContent: "center", // Center vertically
                borderRadius: "8px",
                width: "500px",
              }}
            >
              <ErrorOutlineIcon style={{ fontSize: "100px", color: "red" }} />
              <DialogTitle style={{ fontWeight: "bold", color: "black", fontSize: "1.25rem" }}>
                Are you sure?
              </DialogTitle>
              <h5
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "normal",
                  color: "#444444",
                }}
              >
                You won't be able to revert this!
              </h5>
              <DialogContent>
                <DialogContentText>
                  <Grid style={{ marginTop: "1rem" }}>
                    <Button
                      onClick={() => setDialogOpen(false)}
                      style={{
                        backgroundColor: "#6c7c84",
                        color: "white",
                        marginBottom: "1rem",
                      }}
                    >
                      No, cancel!
                    </Button>
                    <Button
                      onClick={() => handleDeleteById1(cell)}
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        marginLeft: "1rem",
                        marginBottom: "1rem",
                        border: "1px solid #b4acec",
                      }}
                    >
                      Yes, delete it!
                    </Button>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </div>
          </Dialog>
        </>
      ),
    },
  ];

  

  const cptcolumns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.1,
    },
    {
      field: "patientName",
      headerName: "PatientName",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.4,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setColumnIDICD(cellValues.row.id)
            // setAutpattientIcd(cellValues.row.patientId)
            setAutpattientIcd1(cellValues.row.patientId)
            setapprovedcpt(cellValues.row.approved)
            setPatientNameicd1(cellValues.row.patientName)
            // setPatientNameicd(cellValues.row.patientName)
            setOpenModalicd(true)

            // opentmdRegistration(event,cellValues.row.id)
            // setAutpattientID(cellValues.row.patientId)
            // setPatientName(cellValues.row.PatientName)
            // setOpenModal(true)
          }}
        >{`${cellValues.row.patientName}`}</Button>
      ),
    },
    {
      field: "cptcode",
      headerName: "Cpt Code",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.3,
    },
    {
      field: "cptcharges",
      headerName: "Charges",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.3,
    },
    {
      field: isAllPractics==true?"PracticeName":"",
      headerName: isAllPractics==true?"Practice Name":'',
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex:isAllPractics==true? 0.3:'',
    },
    {
      field: "dateofSubmission",
      headerName: "Date of Submission",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.3,
    },

    {
      field: "approve",
      headerName: "Approve",
      sortable: true,
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      renderCell: (cell) => {
        if (cell.row.approved) {
          // If approved is true, render a disabled button or some other content
          return (
            <TaskAltIcon
              onClick={(e) => {
                setColumnIDICD(cell.row.id)
                setAutpattientIcd(cell.row.patientId)
                setPatientNameicd(cell.row.patientName)
                setMarkaporoved(cell.row.approved)
                // handleapprove()
              }}
              style={{
                marginRight: "5px",
                float: "right",
                marginTop: "5px",
                color: "green",
                // cursor: "not-allowed",
              }}

            ></TaskAltIcon>
          );
        } else {
          // If approved is false, render the button that can be clicked
          return (
            <>

              <TaskAltIcon
                style={{
                  marginRight: "5px",
                  float: "right",
                  marginTop: "5px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={(cellValues) => {
                  setColumnIDICD(cell.row.id)
                  setAutpattientIcd(cell.row.patientId)
                  setPatientNameicd(cell.row.patientName)
                  setMarkaporoved(cell.row.approved)
                }}
              ></TaskAltIcon>
            </>
          );
        }
      },
    },
    {
      field: 'delete',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      flex: 0.3,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cell) => (
       <>
        {UserType == 'Front Office' || UserType == 'Front Office Admin'?(<></>):(<>



{UserType == 'Back Office Admin' || UserType == 'Back Office User'?(<></>):(<>
  <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
        }}
        color="black"
        onClick={() => {setDialogOpen(true)
          setDelbyid(cell.row.id)
        }}
      ></DeleteIcon>



</>)}




</>)}
     

      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {
            marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
          },
        }}
      >
       <div style={{
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center horizontally
  justifyContent: 'center', // Center vertically
  borderRadius: '8px',
  width: '500px'
}}>
  
  <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
  <DialogTitle style={{ fontWeight: 'bold',color:'black',fontSize:' 1.25rem' }}>Are you sure?</DialogTitle>
  <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',color:'#444444' }}>
    You won't be able to revert this!
  </h5>
  <DialogContent>
    <DialogContentText>

      <Grid style={{ marginTop: '1rem' }}>
        <Button onClick={() => setDialogOpen(false)} style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}>No,cancel!</Button>
        <Button  onClick={() => handleDelete1(cell)} style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem',border:'1px solid #b4acec' }}>Yes,delete it!</Button>
      </Grid>
    </DialogContentText>
  </DialogContent>
</div>

      </Dialog>
    </>
      ),
    },



  ];
  const [submitting, setSubmitting] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });

  // Define Variables
  const navigate = useNavigate();
  const [rows, setRows] = useState(null);
  const [tmdrows, setTmdrows] = useState(null);
  const [Cptsrows, setCptsrows] = useState(null);

  const [rowsID, setRowsID] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const [refreshtmd, setrefreshtmd] = useState(false);
  const [refreshcpt, setrefreshcpt] = useState(false);

  const [patientSelected, setPatientSelected] = React.useState(false);
  const [patientData, setPatientData] = React.useState([]);
  const [patient, setPatient] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [error, seterror] = useState(false)
  const [usertype, setUsertype] = useState(localStorage.getItem('User_Type'))
  const [openAlert, setOpenAlert] = useState(false);
  const [cellid, setcellid] = useState('');
  const [Dos, setDos] = useState('');
  const UserTypes=localStorage.getItem("User_Type");

  const handleClickOpen = () => {

    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);

  };


  const handleDeleteById = () => {
    const postData = {

      id: cellid,
      deleted: true,
      patientId: patientID
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {

        setrefresh(!refresh);
        setOpenAlert(false);
      })
      .catch((error) => {
        console.error(
          "There was an error!",
          error
        );
      });
  };

  // useEffect(() => {
  //   console.log('_______________',columnID)
  //   handleOpen()
  // }, [columnID])
  // API's Calling

  useEffect(() => {
    if (columnID > 0) {

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/AutherizationRequest/AuthoorizationById?Id=${columnID}`,
          { headers }
        )
        .then(response => {
          console.log('response.data1: ', response)
          fields.forEach(field => {
            setValue(field, response[field])
          })
        })

        .catch(error => {
          console.error('There was an error!', error)
        })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [columnID])

  useEffect(() => {
if(typeOfprocedureCode1!=='E0486-Custom Oral Appliance'){
  return
}
setLoading(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/Authoorization?patientId=${patientId}&showAllPractices=${isAllPractics}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response) {
          const dosValues = response.map((item) => item.patientId);
          setRows(response);
          setRowsID(response.id);
setLoading(false)
          setDos(dosValues)
          props.getData1(dosValues)

        }
        console.log(response, 'response')


        setLoading(false)
        // setrefresh(!refresh)
      })

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [refresh,lomnRequest ,OsaRequestid,AuthRequestid ,typeOfprocedureCode1]);

  useEffect(() => {
if(typeOfprocedureCode1!=='21085-Temporomandibular Disorders (TMD)'){
  return
}
setLoading(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientNote/TMDisorders?patientId=${patientId}&showAllPractices=${isAllPractics}`,
        {
          headers,
        }
      )
      .then((response) => {
        console.log(response, 'response tdn')
        setTmdrows(response);
        // setRowsID(response.id);
        setLoading(false)
        // setrefresh(!refresh)
      })

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [refreshtmd,refresh,typeOfprocedureCode1]);
  // const [procedureCode1ID, setProcedureCode1ID] = useState("");
  const [Charge1, setCharge1] = useState("");
 
  useEffect(() => {
    if (Charge1.length > 0 || patientId > 0) {
      setLoading(true)
      console.log('Charge1', Charge1)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientNote/AuthorizationFormByCPT?patientId=${patientId}&cptcode=${Charge1}&showAllPractices=${isAllPractics}`,
          {
            headers,
          }
        )
        .then((response) => {
          console.log(response, 'response tdn')
          setCptsrows(response);
          // setRowsID(response.id);
          setLoading(false)
          // setrefresh(!refresh)
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [refreshcpt, Charge1,columnIDICD,isAllPractics]);

  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    const sendData = {
      id: value.patientId,
      lastName: "",
      firstName: value,
      ssn: "",
      status: "",
      cellPhone: "",
      address: "",
      dob: "",
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(true)
      )
      .then((response) => {
        setPatientData(response);
        setPatient(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleInputChange = (event, newValue) => {
    const trimmedValue = newValue.trim();
    if (trimmedValue.length > 0) {
      onChangeHandle(trimmedValue);
    }
  };
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    // Filter patientData to remove the selected patient
    if (selectedPatient) {
      setFilteredOptions(
        patientData.filter(
          (option) =>
            option.firstName !== selectedPatient.firstName ||
            option.lastName !== selectedPatient.lastName
        )
      );
    } else {
      // If no patient is selected, show all options
      setFilteredOptions(patientData);
    }
  }, [selectedPatient, patientData]);

  const handleOptionChange = (event, value) => {
    setSelectedPatient(value);
    console.log("Selected ID:", value.id);
    setPatientID(value.id);
  };

  // Add new Modal Code

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    applyRefresh()
    setOpenModal(false)

  };

  const handleOpen1 = (value) => {
    reset();
    setOpenModal(true);
    setColumnID(0)
    setColumnIDTMD(0)
    setColumnIDICD(0)

    if (testpateint == '' && patientName != '') {
      setPatientName('')
    }
    else {
      setPatientName(testpateint)
    }
  };
  const [openModalicd, setOpenModalicd] = useState(false);
  const handleCloseModalicd = () => {
    applyRefreshcpt()
    setOpenModalicd(false)

  };

  const handleOpenicd = (value) => {
    reset();
    setOpenModalicd(true);
    setColumnIDICD(0)
    // setAutpattientIcd(0)
    if (testpateint == '' && patientNameicd1 != '') {
      setPatientNameicd1('')
    }
    else {
      setPatientNameicd1(testpateint)
    }
  };

  const [openModaltmd, setOpenModaltmd] = useState(false);
  const handleCloseModaltmd = () => {
    applyRefreshtmd()
    setOpenModaltmd(false)

  };

  const handleOpentmd = (value) => {
    reset();
    setOpenModaltmd(true);
    setColumnIDTMD(0)
    
    if (testpateint == '' && patientNametmd != '') {
      setPatientNametmd('')
    }
    else {
      setPatientNametmd(testpateint)
    }
  };
  function applyRefresh() {
    setrefresh(!refresh)
    setrefreshtmd(!refreshtmd)
    setrefreshcpt(!refreshcpt)
  }
  function applyRefreshtmd() {
    setrefreshtmd(!refreshtmd)
  }
  function applyRefreshcpt() {
    setrefreshcpt(!refreshcpt)
  }

  // Styling

  const doccheckstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #377562",
    boxShadow: 24,

  };
  const [type, settype] = useState('')

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    minHeight: '10px',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
      lineHeight: "20px",
    },
    '& .MuiDataGrid-colCell': {
      lineHeight: "20px", // Adjust this value to decrease the header height
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const checkstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #356F60",
    boxShadow: 24,
    // overflow: "auto",
    // overflow: "auto",
    /* Other styles */
    scrollbarWidth: 'thin', // For Firefox
    scrollbarColor: '#356F60 transparent', // thumb color and track color
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 8, /* Height of horizontal scrollbar */
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#356F60', /* Color of the thumb */
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent', /* Color of the track */
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    // zIndex: "10",
  };
  const [Charge, setCharge] = useState("");
  const [procedureCode1ID, setProcedureCode1ID] = useState("");

  const [procedureCode1List, setProcedureCode1List] = useState([]);

  useEffect(() => {

    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Procedures/Procedures`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: `${field.code}-${field.description}`,
            value: field.code,
            qasim: field.code,
            charges: field.default_charges

          });

        });
        setProcedureCode1List(prac);
        console.log(prac,'prac____+')

      })
      .catch(error => { });

  }, []);
  const [selectedValue, setSelectedValue] = useState('current Month')
  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    console.log("Selected value my kya ha : ", newValue)
  }

  return (
    <>
      <Modal
        open={lomnRequest}
        onClose={handlecloseLomnRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={doccheckstyle}>
          <Grid container spacing={1}>
            <Grid item xs={11} style={{ fontWeight: 500, marginTop: "10px", }}>
              <Typography style={{ fontWeight: 'bold', marginLeft: "5px", fontSize: '18px', color: 'white', backgroundColor: '#377562', }}>
                Add patient document
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={handlecloseLomnRequest}
                variant="gradient"
                // disabled={submitting}
                style={{
                  marginTop: "10px",
                  float: "right",
                  marginBottom: "5px",
                  // width: "80px",
                  height: "35px",
                  backgroundColor: "#fff",
                  color: "black",
                  fontSize: "14px",
                  cursor: 'pointer'
                }}

              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
              >
                <CancelOutlinedIcon
                  fontSize="medium"
                  style={{
                    color: "red",

                    paddingBottom: "2px",
                  }}
                ></CancelOutlinedIcon>

              </Button>
            </Grid>
          </Grid>




          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }}>
              <Documents callingFrom={"HealthRequest"} type={'lomn'} columnID={columnID} handleClose222={handlecloseLomnRequest} patientName={patientNamee} AutpattientID={LomnRequestid}
                updateHealthHistory={(id, authId) => {
                  console.log('setting', id)
                  setValue('lomn', true)
                  setColumnID(authId)
                  // setapproveid(authId)
                }}
                style={{ height: '40vh' }}

              ></Documents>
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <Modal
        open={healthRequest}
        onClose={handlecloseHealthRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={doccheckstyle}>
          <Grid container spacing={1}>
            <Grid item xs={11} style={{ fontWeight: 500, marginTop: "10px", }}>
              <Typography style={{ fontWeight: 'bold', marginLeft: "5px", fontSize: '18px', color: 'white', backgroundColor: '#377562', }}>
                Add patient document
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={handlecloseHealthRequest}
                variant="gradient"
                // disabled={submitting}
                style={{
                  marginTop: "10px",
                  float: "right",
                  marginBottom: "5px",
                  // width: "80px",
                  height: "35px",
                  backgroundColor: "#fff",
                  color: "black",
                  fontSize: "14px",
                  cursor: 'pointer'
                }}

              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
              >
                <CancelOutlinedIcon
                  fontSize="medium"
                  style={{
                    color: "red",

                    paddingBottom: "2px",
                  }}
                ></CancelOutlinedIcon>

              </Button>
            </Grid>
          </Grid>




          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }}>
              <Documents callingFrom={"HealthRequest"} type={'healthHistory'} columnID={columnID} handleClose222={handlecloseHealthRequest} patientName={patientNamee} AutpattientID={HealthRequestid}
                updateHealthHistory={(id, authId) => {
                  console.log('setting', id)
                  setValue('healthHistory', true)
                  setColumnID(authId)
                  // setapproveid(authId)
                }}
                style={{ height: '40vh' }}

              ></Documents>
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <Modal
        open={AuthRequest}
        onClose={handlecloseAuthRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={checkstyle1}>

          <Headroom>
            <Grid
              container
              border={2}
              // borderRadius={1}
              borderColor="#3C7F6B"
              style={{ backgroundColor: 'white', position: 'sticky', }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '3rem'
                  }}
                >
                  {/* <Grid container spacing={1}>
                        <Grid item xs={1} sm={1} xl={1}>

                        </Grid>
                      </Grid> */}
                  <img src={logoLight} alt="logo-light" style={{ width: '4%', marginLeft: '5px' }} />

                  <DialogTitle
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'black',
                        // textDecoration: 'underline',
                        fontSize: '2rem'

                      }}
                    >
                      VOB/ Prior-auth Request

                    </h1>
                  </DialogTitle>
                  <Typography style={{ color: 'black', marginRight: '10px' }}>Patient Name:</Typography>
                  <Typography style={{ color: 'black', fontWeight: 'bold', marginRight: '60px' }}>{patientNamee}</Typography>

                  <Typography style={{ color: 'black', marginRight: '10px' }}>ID:</Typography>
                  <Typography style={{ color: 'black', fontWeight: 'bold', marginRight: '60px' }}>{columnID}</Typography>

                  <CancelOutlinedIcon
                    onClick={handlecloseAuthRequest}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>


          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }}>
              <VOBPAGE1 callingFrom={"AuthRequest"} columnID={columnID} handleClose3={handlecloseAuthRequest} AuthRequestpatientName={patientNamee} AutpattientID={AuthRequestid} Markaporoved={Markaporoved}
                updateHealthHistory={(id, authId) => {
                  console.log('setting', id)
                  setValue('AuthRequest', true)
                  setColumnID(authId)
                  // setapproveid(authId)
                }}
                style={{ height: '40vh' }}

              ></VOBPAGE1>
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <Modal
        open={osaRequest}
        onClose={handlecloseOsaRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={checkstyle1}>

          <Headroom>
            <Grid
              container
              border={2}
              // borderRadius={1}
              borderColor="#3C7F6B"
              style={{ backgroundColor: 'white', position: 'sticky', }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '3rem'
                  }}
                >
                  {/* <Grid container spacing={1}>
                        <Grid item xs={1} sm={1} xl={1}>

                        </Grid>
                      </Grid> */}
                  <img src={logoLight} alt="logo-light" style={{ width: '4%', marginLeft: '5px' }} />

                  <DialogTitle
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'black',
                        // textDecoration: 'underline',
                        fontSize: '2rem'

                      }}
                    >
                      Snoring and obstructive sleep apnea (OSA) Screener{" "}

                    </h1>
                  </DialogTitle>


                  <CancelOutlinedIcon
                    onClick={handlecloseOsaRequest}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>


          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }}>
              <OSAPAGE callingFrom={"OSARequest"} columnID={columnID} handleClose1={handlecloseOsaRequest} OSARequestpatientName={patientNamee} AutpattientID={OsaRequestid} Markaporoved={Markaporoved}
                updateHealthHistory={(id, authId) => {
                  console.log('setting', id)
                  setValue('questionnaire', true)
                  setColumnID(authId)
                  // setapproveid(authId)
                }}
                style={{ height: '40vh' }}

              ></OSAPAGE>
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <Modal
        open={approve}
        onClose={handlecloseapprove}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={checkstyle1}>

          <Headroom>
            <Grid
              container
              border={2}
              // borderRadius={1}
              borderColor="#3C7F6B"
              style={{ backgroundColor: 'white', position: 'sticky', }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '3rem'
                  }}
                >
                  {/* <Grid container spacing={1}>
                        <Grid item xs={1} sm={1} xl={1}>

                        </Grid>
                      </Grid> */}
                  <img src={logoLight} alt="logo-light" style={{ width: '4%', marginLeft: '5px' }} />

                  <DialogTitle
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'black',
                        // textDecoration: 'underline',
                        fontSize: '2rem'

                      }}
                    >
                      Prior-auth & VOB

                    </h1>
                  </DialogTitle>
                  <Typography style={{ color: 'black', marginRight: '10px' }}>Patient Name:</Typography>
                  <Typography style={{ color: 'black', fontWeight: 'bold', marginRight: '60px' }}>{patientNamee}</Typography>

                  <Typography style={{ color: 'black', marginRight: '10px' }}>ID:</Typography>
                  <Typography style={{ color: 'black', fontWeight: 'bold', marginRight: '60px' }}>{columnID}</Typography>

                  <CancelOutlinedIcon
                    onClick={handlecloseapprove}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>


          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }}>
              <Prior_auth Dos={Dos} columnID={columnID} handleClose4={handlecloseapprove} patientNameee={patientNamee} AutpattientID1={AutpattientID1} Markaporoved={Markaporoved}
                updateHealthHistory={(id, authId) => {
                  console.log('setting', id)
                  setValue('approved', true)
                  setColumnID(authId)
                  // setapproveid(authId)
                }}
                style={{ height: '40vh' }}

              ></Prior_auth>
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>


      {/* ///// modal Cpt open */}
      <Modal
        open={approvecpt}
        onClose={handlecloseapprovecpt}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={checkstyle1}>

          <Headroom>
            <Grid
              container
              border={2}
              // borderRadius={1}
              borderColor="#3C7F6B"
              style={{ backgroundColor: 'white', position: 'sticky', }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '3rem'
                  }}
                >
                  {/* <Grid container spacing={1}>
                        <Grid item xs={1} sm={1} xl={1}>

                        </Grid>
                      </Grid> */}
                  <img src={logoLight} alt="logo-light" style={{ width: '4%', marginLeft: '5px' }} />

                  <DialogTitle
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'black',
                        // textDecoration: 'underline',
                        fontSize: '2rem'

                      }}
                    >
                      Prior-auth & VOB

                    </h1>
                  </DialogTitle>
                  <Typography style={{ color: 'black', marginRight: '10px' }}>Patient Name:</Typography>
                  <Typography style={{ color: 'black', fontWeight: 'bold', marginRight: '60px' }}>{patientNameicd}</Typography>

                  <Typography style={{ color: 'black', marginRight: '10px' }}>ID:</Typography>
                  <Typography style={{ color: 'black', fontWeight: 'bold', marginRight: '60px' }}>{columnIDICD}</Typography>

                  <CancelOutlinedIcon
                    onClick={handlecloseapprovecpt}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>


          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }}>
              <Prior_auth Dos={Dos} columnID={columnIDICD} handleClose4={handlecloseapprovecpt} patientNameee={patientNameicd} AutpattientID1={AutpattientIcd} Markaporoved={Markaporoved}
                updateHealthHistory={(id, authId, authCPTId) => {
                  console.log('setting', id)
                  setValue('approved', true)
                  setColumnIDICD(authCPTId)
                  // setapproveid(authId)
                }}
                style={{ height: '40vh' }}

              ></Prior_auth>
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      {openModal ? (
        <>

          <Grid
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: '50px',
              borderRadius: '20px',
              border: '1px solid rgba(5, 152, 236, 0.637)',
              overflow: 'scroll'
            }}
          >
            <Grid container >
              <Grid item xs={0.3}></Grid>

              <Grid item xs={11.2}>
                {patientId > 0 ? (<></>) : (
                  <>
                    <Typography variant="h6" fontWeight="bold" style={{ marginTop: '2rem' }}> Add Authorization</Typography>



                  </>)}
              </Grid>
              <Grid item xs={0.5}></Grid>
            </Grid>

            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ width: '100%', marginTop: '1px', borderRadius: '20px', height: '40px', }}
              bgcolor={'#356F60'}
            >


              <Grid item xs={2.8}>
                <Button
                  className="Button"
                  onClick={handleCloseModal}
                  variant='contained'
                  color='info'
                  size='small'
                  style={{
                    width: '20px',
                    float: 'left',
                    marginLeft: '15px',
                    //  marginRight: '10px',
                    height: '23px',
                    marginTop: '8px',
                    //  backgroundColor:'#E87426'
                  }}
                >
                  <ArrowBackIosIcon
                    fontSize='medium'
                    style={{
                      color: '#1a82ad',
                      //  padding: "5px",
                      //  marginLeft: '5px'
                    }}
                  ></ArrowBackIosIcon>
                  Back
                </Button>

              </Grid>
              <Grid item xs={2.2}>
                <Typography style={{ color: 'white', marginTop: '8px', float: 'right', fontWeight: 'bold' }}>Patient Name:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ color: 'white', marginTop: '8px', }}>{patientName}</Typography>


              </Grid>
              <Grid item xs={0.5}>
                <Typography style={{ color: 'white', marginTop: '8px', float: 'right', fontWeight: 'bold' }}>ID:</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ color: 'white', marginTop: '8px', }}>{columnID}</Typography>


              </Grid>
              <Grid item xs={2.8}></Grid>



            </Grid>
            <Grid item xs={12} >
              <AuthorizationRegistration callingFrom={"Patient"} columnID={columnID} 
              setAuthId={setAuthId}
              AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : AutpattientID} 
              patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientName} patientId={patientId} 
              CloseModal={handleCloseModal}></AuthorizationRegistration>


            </Grid>
          </Grid>
        </>

      ) : openModaltmd ? (<>
        <>

          <Grid
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: '50px',
              borderRadius: '20px',
              border: '1px solid rgba(5, 152, 236, 0.637)',
              overflow: 'scroll'
            }}
          >
            <Grid container >
              <Grid item xs={0.3}></Grid>

              <Grid item xs={11.2}>
                {patientId > 0 ? (<></>) : (
                  <>
                    <Typography variant="h6" fontWeight="bold" style={{ marginTop: '2rem' }}> Temporomandibular Disorders (TMD)</Typography>



                  </>)}
              </Grid>
              <Grid item xs={0.5}></Grid>
            </Grid>

            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ width: '100%', marginTop: '1px', borderRadius: '20px', height: '40px', }}
              bgcolor={'#356F60'}
            >


              <Grid item xs={10.5}>
                <Button
                  className="Button"
                  onClick={handleCloseModaltmd}
                  variant='contained'
                  color='info'
                  size='small'
                  style={{
                    width: '20px',
                    float: 'left',
                    marginLeft: '15px',
                    //  marginRight: '10px',
                    height: '23px',
                    marginTop: '8px',
                    //  backgroundColor:'#E87426'
                  }}
                >
                  <ArrowBackIosIcon
                    fontSize='medium'
                    style={{
                      color: '#1a82ad',
                      //  padding: "5px",
                      //  marginLeft: '5px'
                    }}
                  ></ArrowBackIosIcon>
                  Back
                </Button>

              </Grid>
              <Grid item xs={0.5}>
                <Typography style={{ color: 'white', marginTop: '8px', float: 'right', fontWeight: 'bold' }}>ID:</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ color: 'white', marginTop: '8px', }}>{columnIDTMD}</Typography>

              </Grid>



            </Grid>
            <Grid item xs={12} >
              <Tmdform PatientdateTMD={PatientdateTMD} callingFrom={"Patient"} columnID={columnIDTMD} patientId={patientId} AutpattientID={AutpattientID} patientNametmd={patientNametmd} CloseModal={handleCloseModaltmd}></Tmdform>


            </Grid>
          </Grid>
        </>

      </>) : openModalicd ? (<>
        <>

          <Grid
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: '50px',
              borderRadius: '20px',
              border: '1px solid rgba(5, 152, 236, 0.637)',
              overflow: 'scroll'
            }}
          >
            <Grid container >
              <Grid item xs={0.3}></Grid>

              <Grid item xs={11.2}>
                {patientId > 0 ? (<></>) : (
                  <>
                    <Typography variant="h6" fontWeight="bold" style={{ marginTop: '2rem' }}>  Authorization Registration</Typography>



                  </>)}
              </Grid>
              <Grid item xs={0.5}></Grid>
            </Grid>

            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ width: '100%', marginTop: '1px', borderRadius: '20px', height: '100%', }}
              bgcolor={'#356F60'}
            >


              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button
                  className="Button"
                  onClick={handleCloseModalicd}
                  variant='contained'
                  color='info'
                  size='small'
                  style={{
                    width: '20px',
                    float: 'left',
                    marginLeft: '15px',
                    //  marginRight: '10px',
                    height: '23px',
                    marginTop: '8px',
                    //  backgroundColor:'#E87426'
                  }}
                >
                  <ArrowBackIosIcon
                    fontSize='medium'
                    style={{
                      color: '#1a82ad',
                      //  padding: "5px",
                      //  marginLeft: '5px'
                    }}
                  ></ArrowBackIosIcon>
                  Back
                </Button>

              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Typography style={{ color: 'white', marginTop: '8px', fontWeight: 'bold', textAlign: 'center' }}>VOB/Prior-auth</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Typography style={{ color: 'white', marginTop: '8px', fontWeight: 'bold', marginRight: '1rem', textAlign: 'center' }}>ID: {columnIDICD}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <AuthorizationFormByCPT columnID={columnIDICD} Charge={Charge} Charge1={Charge1} approvedcpt={approvedcpt} patientId={patientId} AutpattientIcd={AutpattientIcd1} patientNametmd={patientNameicd1} CloseModal={handleCloseModalicd}></AuthorizationFormByCPT>


            </Grid>
          </Grid>
        </>

      </>) : (
        <>
          {patientId > 0 ? (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography style={{ color: 'black', fontSize: '18px' }}>
                        Type:
                      </Typography>

                      <Controller
                        name="procedureCode1"
                        // {...register("procedureCode1")}
                        control={control}
                        render={({ field }) =>
                          <ReactSelect
                            {...field}
                            fullWidth
                            value={procedureCode1List.filter(function (option) {
                              return (
                                option.value === getValues("procedureCode1")
                              );
                            })}
                            onChange={e => {
                              console.log('eeee', e)
                              setValue("procedureCode1", e.value);
                              setCharge1(e.qasim);
                              setCharge(e.charges)
                              // console.log('eeee',e)
                              setProcedureCode1ID(e.value);

                            }}
                            // styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                            options={procedureCode1List}
                          />

                        }
                      />
            
                    </Grid>
                    
                    <Grid item xs={4}style={{ display: 'flex' }}></Grid>
                    <Grid item xs={4}style={{ display: 'flex',marginTop:'21px' }}>
                      {procedureCode1ID == '21085' ? (<>
                        <Button className="Button" variant="contained" onClick={handleOpentmd}
                          style={{ float: 'right',height:'35px',marginRight:'3px' }}
                        >
                          <AddOutlinedIcon
                            fontSize='small'
                            style={{
                              color: 'white',
                          
                            }}
                          ></AddOutlinedIcon>
                          Add New
                        </Button>

                      </>) : (
                        <>
                          {procedureCode1ID == 'E0486' ? (<>
                            <Button className="Button" variant="contained" onClick={handleOpen1}
                              style={{ float: 'right',height:'35px',marginRight:'3px' }}
                            >
                              <AddOutlinedIcon
                                fontSize='small'
                                style={{
                                  color: 'white',

                          
                                }}
                              ></AddOutlinedIcon>
                              Add New
                            </Button>

                          </>) : (
                            <>
                              <Button className="Button" variant="contained" onClick={handleOpenicd}
                                style={{ float: 'right' ,height:'35px',marginRight:'3px'  }}
                              >
                                <AddOutlinedIcon
                                  fontSize='small'
                                  style={{
                                    color: 'white',

                          
                                  }}
                                ></AddOutlinedIcon>
                                Add New
                              </Button>
                            </>
                          )}


                        </>
                      )}

                      <Button
                        className="Button"
                        variant="contained"
                        style={{height:'35px'}}
                        onClick={event => {
                          applyRefresh()
                        }}
                      //  style={{ marginLeft: '1rem',float:'right',marginRight:'0.5rem',   marginTop:'0.5rem'}}
                      >
                        <CachedOutlinedIcon
                          fontSize='small'
                          style={{
                            color: 'white',
                  
                          }}
                        ></CachedOutlinedIcon>
                        Refresh
                      </Button>

                    </Grid>
                  </Grid>
                  <>

                    {procedureCode1ID == '21085' ? (<>
                      <Box mb={3} marginTop={2}>
                        {isLoading ? (
                          <div style={{ margin: '5rem', textAlign: 'center' }}>
                            <CircularProgress />
                          </div>
                        ) : (
                          <div style={{ height: 400, width: "100%" }}>
                            {tmdrows === null || tmdrows.length === 0 ? (
                              <Typography>No Record found</Typography>
                            ) : (
                              <DataGrid
                                rows={tmdrows}
                                rowHeight={28}
                                columns={tmdcolumns}
                                headerHeight={28}
                                sx={gridRowStyle}
                                pagination
                                autoPageSize
                              />
                            )}
                          </div>
                        )}
                      </Box>

                    </>
                    ) : (
                      <>
                        {procedureCode1ID == 'E0486' ? (<>
                          <Box mb={3} marginTop={2}>
                            {isLoading ? (
                              <div style={{ margin: '5rem', textAlign: 'center' }}>
                                <CircularProgress />
                              </div>
                            ) : (
                              <div style={{ height: 400, width: "100%" }}>
                                {rows === null || rows.length === 0 ? (
                                  <Typography>No Record found</Typography>
                                ) : (
                                  <DataGrid
                                    rows={rows}
                                    rowHeight={28}
                                    columns={columns}
                                    headerHeight={28}
                                    sx={gridRowStyle}
                                    pagination
                                    autoPageSize
                                  />
                                )}
                              </div>
                            )}
                          </Box>


                        </>) : (
                          <>
                            <Box mb={3} marginTop={2}>
                              {isLoading ? (
                                <div style={{ margin: '5rem', textAlign: 'center' }}>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <div style={{ height: 400, width: "100%" }}>
                                  {Cptsrows === null || Cptsrows.length === 0 ? (
                                    <Typography>No Record found</Typography>
                                  ) : (
                                    <DataGrid
                                      rows={Cptsrows}
                                      rowHeight={28}
                                      columns={cptcolumns}
                                      headerHeight={28}
                                      sx={gridRowStyle}
                                      pagination
                                      autoPageSize
                                    />
                                  )}
                                </div>
                              )}
                            </Box>
                          </>
                        )}
                      </>
                    )}

                  </>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container className="page-content"  >
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Typography style={{ color: 'black', fontSize: '18px', marginTop: '10px' }}>
                        Type:
                      </Typography>
                      <Controller
                        name="procedureCode1"
                        // {...register("procedureCode1")}
                        control={control}
                        render={({ field }) =>
                          <ReactSelect
                            {...field}
                            fullWidth
                            value={procedureCode1List.filter(function (option) {
                              return (
                                option.value === getValues("procedureCode1")
                              );
                            })}
                            onChange={e => {
                              console.log('eeee11', e)
                              setValue("procedureCode1", e.value);
                              setCharge1(e.qasim);
                              setCharge(e.charges)
                              // console.log('eeee',e)
                              settypeOfprocedureCode1(e.label)

                              setProcedureCode1ID(e.value);

                            }}
                            // styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                            options={procedureCode1List}
                          />

                        }
                      />
                   
                    </Grid>




                    {/* <Grid item xs={3}  >
                    </Grid> */}


 <Grid item xs={3} style={{marginTop:'1.7rem'}} >
 <div style={{ display: 'flex' ,height:'27px' }}>
                    {UserTypes=='Front Office'?'':(<>
                             <Checkbox
                                  value={isAllPractics}
                                  style={{ marginTop: '20px' }}
                                  onChange={e => {
                                    setisAllPractics(e.target.checked)
                                  }}
                                />
                       <Typography style={{marginLeft:'10px',marginTop:'15px',fontWeight:'bold'}}>All Practices</Typography>

                       </>  
                       )}       
                            
                  </div>
        
                    </Grid>





                   

                    <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
                      {procedureCode1ID == '21085' ? (<>
                        <Button className="Button" variant="contained" onClick={handleOpentmd}
                          style={{ float: 'right', marginTop: '9px' }}
                        >
                          <AddOutlinedIcon
                            fontSize='small'
                            style={{
                              color: 'white',
                              paddingRight: '4px',
                              paddingBottom: '2px',

                            }}
                          ></AddOutlinedIcon>
                          Add New
                        </Button>

                      </>) : (
                        <>
                          {procedureCode1ID == 'E0486' ? (<>
                            <Button className="Button" variant="contained" onClick={handleOpen1}
                              style={{ float: 'right', marginTop: '9px' }}
                            >
                              <AddOutlinedIcon
                                fontSize='small'
                                style={{
                                  color: 'white',

                                  paddingRight: '4px',
                                  paddingBottom: '2px'

                                }}
                              ></AddOutlinedIcon>
                              Add New
                            </Button>

                          </>) : (
                            <>
                              <Button className="Button" variant="contained" onClick={handleOpenicd}
                                style={{ float: 'right', marginTop: '10px' }}
                              >
                                <AddOutlinedIcon
                                  fontSize='small'
                                  style={{
                                    color: 'white',

                                    paddingRight: '4px',
                                    paddingBottom: '2px',

                                  }}
                                ></AddOutlinedIcon>
                                Add New
                              </Button>
                            </>
                          )}


                        </>
                      )}

                      <Button
                        className="Button"
                        variant="contained"
                        onClick={event => {
                          applyRefresh()
                        }}
                        style={{ marginLeft: '1rem', float: 'right', marginRight: '25px', marginTop: '10px' }}
                      >
                        <CachedOutlinedIcon
                          fontSize='small'
                          style={{
                            color: 'white',
                            paddingRight: '0px',
                            paddingBottom: '1px'
                          }}
                        ></CachedOutlinedIcon>
                        Refresh
                      </Button>

                    </Grid>

                  </Grid>
                  <>
                    {procedureCode1ID == '21085' ? (<>
                      <Box mb={3} marginTop={2}>
                        {isLoading ? (
                          <div style={{ margin: '5rem', textAlign: 'center' }}>
                            <CircularProgress />
                          </div>
                        ) : (
                          <div style={{ height: 400, width: "100%" }}>
                            {tmdrows === null || tmdrows.length === 0 ? (
                              <Typography>No Record found</Typography>
                            ) : (
                              <DataGrid
                                rows={tmdrows}
                                rowHeight={28}
                                columns={tmdcolumns}
                                headerHeight={28}
                                sx={gridRowStyle}
                                pagination
                                autoPageSize
                              />
                            )}
                          </div>
                        )}
                      </Box>

                    </>
                    ) : (
                      <>
                        {procedureCode1ID == 'E0486' ? (<>
                          <Box mb={3} marginTop={2}>
                            {isLoading ? (
                              <div style={{ margin: '5rem', textAlign: 'center' }}>
                                <CircularProgress />
                              </div>
                            ) : (
                              <div style={{ height: 400, width: "100%" }}>
                                {rows === null || rows.length === 0 ? (
                                  <Typography>No Record found</Typography>
                                ) : (
                                  <DataGrid
                                    rows={rows}
                                    rowHeight={28}
                                    columns={columns}
                                    headerHeight={28}
                                    sx={gridRowStyle}
                                    pagination
                                    autoPageSize
                                  />
                                )}
                              </div>
                            )}
                          </Box>


                        </>) : (
                          <>
                            <Box mb={3} marginTop={2}>
                              {isLoading ? (
                                <div style={{ margin: '5rem', textAlign: 'center' }}>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <div style={{ height: 400, width: "100%" }}>
                                  {Cptsrows === null || Cptsrows.length === 0 ? (
                                    <Typography>No Record found</Typography>
                                  ) : (
                                    <DataGrid
                                      rows={Cptsrows}
                                      rowHeight={28}
                                      columns={cptcolumns}
                                      headerHeight={28}
                                      sx={gridRowStyle}
                                      pagination
                                      autoPageSize
                                    />
                                  )}
                                </div>
                              )}
                            </Box>
                          </>
                        )}
                      </>
                    )}



                  </>
                </Grid>


              </Grid>
            </>
          )}



        </>
      )}
    </>
  );
};

export default Authorization;
