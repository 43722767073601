import { useState, useEffect } from 'react'
import React from 'react'
import ReactSelect from 'react-select'
import axios from 'axios'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import md5 from 'md5'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputAdornment from '@mui/material/InputAdornment'

import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from '@mui/icons-material/Send';
import Breadcrumbs from "../components/Common/Breadcrumb";
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tab from '@mui/material/Tab'
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useAlert } from 'react-alert'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputMask from 'react-input-mask'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import PrintIcon from '@mui/icons-material/Print';
import fileDownload from 'js-file-download';
import { useLocation, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Download from '@mui/icons-material/Download'


const ClaimRegistration = (props) => {
  document.title = "Claim Registration | LuxBilling";
  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const [mod1, setmod1] = useState('')
  const [mod11, setmod11] = useState('')
  const [mod2, setmod2] = useState('')
  const [mod22, setmod22] = useState('')
  const [mod3, setmod3] = useState('')
  const [mod33, setmod33] = useState('')
  const [mod4, setmod4] = useState('')
  const [mod44, setmod44] = useState('')
  const [Pointer1, setPointer1] = useState()
  const [Pointer11, setPointer11] = useState()
  const [Pointer2, setPointer2] = useState()
  const [Pointer22, setPointer22] = useState()
  const [Pointer3, setPointer3] = useState()
  const [Pointer33, setPointer33] = useState()
  const [Pointer4, setPointer4] = useState()
  const [Pointer44, setPointer44] = useState()
  const [charges, setcharges] = useState()
  const [charges1, setcharges1] = useState()
  const [charges2, setcharges2] = useState()


  const [units, setunits] = useState()
  const [units1, setunits1] = useState()
  const [placeOfService, setplaceOfService] = useState('')
  const [code, setcode] = useState('')
  const [code1, setcode1] = useState('')
  const [code2, setcode2] = useState('')

  const [d, setd] = useState('')
  const [d1, setd1] = useState('')
  const [d2, setd2] = useState('')


  const [placeOfService1, setplaceOfService1] = useState('')
  const [proid, setproid] = useState(0)
  const [proid1, setproid1] = useState(0)
  const [proid2, setproid2] = useState(0)



  const [dateOfServiceFrom, setdateOfServiceFrom] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateOfServiceFrom1, setdateOfServiceFrom1] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateOfServiceTo, setdateOfServiceTo] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateOfServiceTo1, setdateOfServiceTo1] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateOfServiceTo2, setdateOfServiceTo2] = React.useState(Moment().format('YYYY-MM-DD'))

  const [dos, setdos] = React.useState('')
  const [dos1, setdos1] = React.useState('')

  const [dos2, setdos2] = React.useState('')


  const patientName = props.callingFrom == 'Patient' ? props.patientName : '';
  const claimpatientid = props.callingFrom == 'Patient' ? props.claimpatientId : '';
  const patientid = props.callingFrom == 'Patient' && props.claimpatientId > 0 ? props.claimpatientId : props.patientId ;
  
  //  const [patientid, setpatientid] = React.useState(props.patientId)

  console.log('patientid', patientid)


  console.log('mod11', mod11)
  console.log('mod1', mod1)
  const fields = [
    'id',
    "patientId",
    "insuranceName",
    "patientName",
    "billingProvider",
    "attendingPhysician",
    "referringPhysicianName",
    "dos",
    "dos1",
    "dos2",
    "pos",
    "referringPhysicianId",
    "memberId",
    "cpt1",
    "cpt2",
    "cpt3",
    "charges1",
    "charges2",
    "charges3",
    "acceptAssignment",
    "dontAcceptAssignment",
    "submitted",
    "payment",
    "referringPhysician",
    "authNumber",
    "frontnote",
    "backnote",
    "renderingProvider"
     

  ]
  const defValues = {
    id: 0,
    patientId: 0,
    billingProvider: 0,
    attendingPhysician: 0,
    insuranceName: "",
    memberId: 0,
    referringPhysician:0,
    patientName: "",
    patientName: patientName,
    dos: "",
    dos1: "",
    dos2: "",
    pos: "",
    frontnote:"",
    backnote:"",
    referringPhysicianName: "",
    referringPhysicianId: 0,
    renderingProvider:'',
    cpt1: "",
    cpt2: "",
    cpt3: "",
    charges1: "",
    charges2: "",
    charges3: "",
 authNumber:"",
    acceptAssignment: "",
    dontAcceptAssignment: "",
    submitted:false,
    payment:false,
    diagnosis: {
      id: 0,
      claimId: 0,
      diagSequence: 0,
      description: "",
      code: "",
      dx1: "",
      dx2: "",
      dx3: "",
      dx4: "",
      dx5: "",
      dx6: "",
      dx7: "",
      dx8: "",
      dx9: "",
      dx10: "",
      dx11: "",
      dx12: "",
      patientId: 0,
    },
    //     procedures: [{
    //         id: 0,
    //         claimId: 0,
    //         code:'',
    //         mod1: '',
    //         mod2:'',
    //         mod3:'',
    //         mod4:'',
    //         dxPointer1:'',
    //         dxPointer2:'',
    //         dxPointer3:'',
    //         dxPointer4:'',
    //         pos:'',
    //         dosTo:'',
    //         charges:0,
    //         procedureSequence:0,

    //         units:0,
    //         dateOfServiceFrom,
    //         dateOfServiceTo,

    //     },

    // ],

  }
  const navigate = useNavigate();
  const alert = useAlert();
  const location = useLocation()
  const [claimId, setclaimId] = useState(props.claimId)

  //  const claimId = location.state.claimId;
  //  const insuranceName = location.state.payerName;

  const UserType = localStorage.getItem('User_Type')
  const [patientNamee, setPatientNamee] = useState('');
  const [selectpatientid, setselectpatientid] = useState('');


  const [patient, setPatient] = React.useState(false);
  const [submittedprop, setsubmittedprop] = React.useState(false);

  console.log('testsubmited',submittedprop)
  const [patientData, setPatientData] = React.useState([]);


  const [searchText, setSearchText] = useState('');

  const [submitting, setSubmitting] = useState(false)
  const diagArr = ["Diag1", "Diag2", "Diag3", "Diag4", "Diag5", "Diag6",
    "Diag7", "Diag8", "Diag9", "Diag10", "Diag11", "Diag12"]
  const [diagId0, setdiagId0] = useState('')
  const [diagId, setdiagId] = useState(0)


  console.log('diagId0', diagId0)
  const [diagId1, setdiagId1] = useState('')
  const [diagId2, setdiagId2] = useState('')
  const [diagId3, setdiagId3] = useState('')
  const [diagId4, setdiagId4] = useState('')
  const [diagId5, setdiagId5] = useState('')
  const [diagId6, setdiagId6] = useState('')
  const [diagId7, setdiagId7] = useState('')
  const [diagId8, setdiagId8] = useState('')
  const [diagId9, setdiagId9] = useState('')
  const [diagId10, setdiagId10] = useState('')
  const [diagId11, setdiagId11] = useState('')
  const [refresh, setRefresh] = useState(false);
  //  const [isLoading, setisLoading] = useState(true);
  const [PayerData, setPayerData] = React.useState([{ 'label': 'Medicaid Massachusetts', 'PayerId': 'SKMA0', 'PayerType': 'MC' }, { 'label': 'Community Health Plan', 'PayerId': '22254', 'PayerType': 'Cl' }]);
  const validationSchema = Yup.object({
    // patientName: Yup.string().required('Patient Name is required'),
    dos: Yup.string().required('DOS is required'),
    // renderingProvider: Yup.string().required('Provider is required'),
    patientName: Yup.string().required("Patient is required"),


    //  SubscriberLastName: Yup.string().required('Last Name is required'),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const [value1, setValue1] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };
  const patientListStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  };
  const [patientinsurancename, setpatientinsurancename] = useState("");
  const [patientiaddress, setpatientiaddress] = useState("");
  const [patientDOB, setpatientDOB] = React.useState(Moment().format('YYYY-MM-DD'),)
  const [patientDetails, setPatientDetails] = useState({
    address: "",
    city: "",
    state: "",
    zipcode: "",
  });
 const Namee=props.Namee
  const [memberid, setmemeberid] = useState('');
  const [Nameo, setnameo] = useState('');
  useEffect(() => {
    if (selectpatientid > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${selectpatientid}`,
          {
            headers,
          }
        )
        .then((response) => {

          setpatientinsurancename(response.planName)
          setpatientiaddress(response.address)
          setpatientDOB(Moment(response.dob).format('YYYY-MM-DD'));
          setmemeberid(response.memberId)
          setnameo(response.patientName)
          setPatientDetails({
            address: response.address,
            city: response.city,
            state: response.state,
            zipcode: response.zipCode,
          });

        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [selectpatientid]);
  useEffect(() => {
    if (patientid > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${patientid}`,
          {
            headers,
          }
        )
        .then((response) => {

          setpatientinsurancename(response.planName)
          setpatientiaddress(response.address)
          setpatientDOB(Moment(response.dob).format('YYYY-MM-DD'));

          setmemeberid(response.memberId)


        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [patientid]);
  useEffect(() => {
    if (claimpatientid > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${claimpatientid}`,
          {
            headers,
          }
        )
        .then((response) => {

          setpatientinsurancename(response.planName)
          setpatientiaddress(response.address)

          setpatientDOB(Moment(response.dob).format('YYYY-MM-DD'));
          setmemeberid(response.memberId)
          setPatientDetails({
            address: response.address,
            city: response.city,
            state: response.state,
            zipcode: response.zipCode,
          });

        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [claimpatientid]);
  ///Calling ClaimsByID
  useEffect(() => {
    if (claimId > 0) {
      var postdate = [claimId]
      let isComponentMounted = true
      const fetchData = async () =>
        axios
          .get(`${process.env.REACT_APP_API_URL}/claims/ClaimsById?claimId=${claimId}`, {
            headers
          })
          .then((response) => {
            console.log('response.data: ', response)
            if (response) {
              fields.forEach((field) => setValue(field, response[field]))
              // diagArr= getValues(response.data[0].ICDs);
              setProvider(response.providerName)
              setsubmittedprop(response.submitted)
              // setcharges(response.charges1)
              // setcharges1(response.charges2)
              // setcharges2(response.charges3)
              var ProcedureData = response.procedures
              setcharges(ProcedureData[0].charges)
              setcharges1(ProcedureData[1].charges)
              setcharges2(ProcedureData[2].charges)
              setcode(ProcedureData[0].code)
              setcode1(ProcedureData[1].code)
              setcode2(ProcedureData[2].code)
              setd(ProcedureData[0].dxPointer1)
              setd1(ProcedureData[1].dxPointer1)
              setd2(ProcedureData[2].dxPointer1)
              var dateto1 = Moment(ProcedureData[0].dosTo).format('YYYY-MM-DD')
              setdos(dateto1)
              var dateto12 = Moment(ProcedureData[1].dosTo).format('YYYY-MM-DD')
              setdos1(dateto12)
              var dateto123 = Moment(ProcedureData[2].dosTo).format('YYYY-MM-DD')
              setdos2(dateto123)
              var diagnosis = response.diagnosis
              setselectpatientid(response.patientId)
              // setcode(response.cpt1)
              // setcode1(response.cpt2)
              // setcode2(response.cpt3)
              // setdiagId(diagnosis.id)
              // setdiagId0(diagnosis.dx1)
              // setcharges(response.charges1)
              // setcharges1(response.charges2)
              // setcharges2(response.charges3)
              // setdos(response.dos)
              // var duedateYMD = Moment(response.dos).format('YYYY-MM-DD')
              // setdos(duedateYMD)
              // var DOS = Moment(response.dos1).format('YYYY-MM-DD')
              // setdos1(DOS)
              // var DOS1 = Moment(response.dos2).format('YYYY-MM-DD')
              // setdos2(DOS1)

              // setd(diagnosis.dx1)
              // setd1(diagnosis.dx2)
              // setd2(diagnosis.dx3)
           
        
              setdiagId1(diagnosis.dx2 != undefined ? diagnosis.dx2 : '')
              setdiagId2(diagnosis.dx3 != undefined ? diagnosis.dx3 : '')
              setdiagId3(diagnosis.dx4 != undefined ? diagnosis.dx4 : '')
              setdiagId4(diagnosis.dx5 != undefined ? diagnosis.dx5 : '')
              setdiagId5(diagnosis.dx6 != undefined ? diagnosis.dx6 : '')
              setdiagId6(diagnosis.dx7 != undefined ? diagnosis.dx7 : '')
              setdiagId7(diagnosis.dx8 != undefined ? diagnosis.dx8 : '')
              setdiagId8(diagnosis.dx9 != undefined ? diagnosis.dx9 : '')
              setdiagId9(diagnosis.dx10 != undefined ? diagnosis.dx10 : '')
              setdiagId10(diagnosis.dx11 != undefined ? diagnosis.dx11 : '')
              setdiagId11(diagnosis.dx12 != undefined ? diagnosis.dx12 : '')

              // var duedateYMD = Moment(response.dos).format('YYYY-MM-DD')
              // setdos(duedateYMD)
              // var duedateYMD = Moment(response.dos1).format('YYYY-MM-DD')
              // setdos1(duedateYMD)
              // var duedateYMD = Moment(response.dos2).format('YYYY-MM-DD')
              // setdos2(duedateYMD)
              // typeof data[1].diagId != undefined ? setdiagId2(data[1].diagId) : null
              //  var CPTs = response.procedures
              //  if(CPTs.length>0)
              //   {
              //     setproid(CPTs[0].id)
              //     setcode(CPTs[0].dxPointer1)
              //     setcode1(CPTs[0].dxPointer2)
              //     setcode2(CPTs[0].dxPointer3)
              //     // setcharges(CPTs[0].charges)
              //     var dateto = Moment(CPTs[0].dosTo).format('YYYY-MM-DD')
              //     setdos(dateto)
              //     // setd(CPTs[0].dxPointer1)
              //   }
              //   if(CPTs.length>1)
              //     {
              //       setproid1(CPTs[1].id)
              //       setcode1(CPTs[1].dxPointer2)
              //       // setcharges1(CPTs[1].charges)
              //       var dateto1 = Moment(CPTs[1].dosTo).format('YYYY-MM-DD')
              //       setdos1(dateto1)
              //       // setd1(CPTs[1].dxPointer1)

              //     }
              //     if(CPTs.length>2)
              //       {
              //         setproid2(CPTs[2].id)
              //         setcode2(CPTs[2].dxPointer3)
              //         // setcharges2(CPTs[2].charges)
              //         var dateto2 = Moment(CPTs[2].dosTo).format('YYYY-MM-DD')
              //  setdos2(dateto2)
              // //  setd2(CPTs[2].dxPointer1)

              //       }

              
               
              
               
               
              
            

              
              
           
              
              
               
            
              
              
              



              //  console.log('testcheck',CPTs)
              //  var datefrom = Moment(CPTs[0].dosFrom).format('YYYY-MM-DD')
              //  var dateto = Moment(CPTs[0].dosTo).format('YYYY-MM-DD')
              //  var datefrom1 = Moment(CPTs[1].dosFrom).format('YYYY-MM-DD')
              //  var dateto1 = Moment(CPTs[1].dosTo).format('YYYY-MM-DD')
              //  setplaceOfService(CPTs[0].pos)
              //  setdateOfServiceFrom(datefrom)
              //  setdateOfServiceTo(dateto)
              //  setcode(CPTs[0].code)
              //  setmod1(CPTs[0].mod1)
              //  setmod2(CPTs[0].mod2)
              //  setmod3(CPTs[0].mod3)
              //  setmod4( CPTs[0].mod4)
              //  setPointer1(CPTs[0].dxPointer1)
              //  setPointer2(CPTs[0].dxPointer2)
              //  setPointer3(CPTs[0].dxPointer3)
              //  setPointer4(CPTs[0].dxPointer4)
              //  setcharges(CPTs[0].charges)
              //  setunits(CPTs[0].units)
              //  setplaceOfService1(CPTs[1].pos)
              //  setdateOfServiceFrom1(datefrom1)
              //  setdateOfServiceTo1(dateto1)
              //  setcode1(CPTs[1].code)
              //  setmod11(CPTs[1].mod1)
              //  setmod22(CPTs[1].mod2)
              //  setmod33(CPTs[1].mod3)
              //  setmod44(CPTs[1].mod4)
              //  setPointer11(CPTs[1].dxPointer1)
              //  setPointer22(CPTs[1].dxPointer2)
              //  setPointer33(CPTs[1].dxPointer3)
              //  setPointer44(CPTs[1].dxPointer4)
              //  setcharges1(CPTs[1].charges)
              //  setunits1(CPTs[1].units)
              //  setcode2(CPTs[2].code)
              //  setcharges2(CPTs[2].charges)
            }

          })
          .catch((error) => {
            console.error('There was an error!', error)
          })
      fetchData()
      return () => {
        isComponentMounted = false
      }
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [claimId])
  function handleCancel() {
    navigate('/Claim')
  }
  const onclickcopy = () => {
    // fields.forEach(field => setValue(field, ''))
    setValue('_id', '')
  }
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    setIsLoading(true);

    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: '',
      dob: ''
    };
    axios
      // .post(`${process.env.NEXT_PUBLIC_API_URL}/Patients/searchPatient`, sendData,
      // { headers },
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
      )

      .then((response) => {
        setPatientData(response);
        setPatient(true);
        setIsLoading(false);


      })
      .catch((error) => {
        console.error('There was an error!', error);
        setIsLoading(false);

      });
  };
  const [diagnosisList, setDiagnosisList] = useState([]);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Diagnosis/Diagnosis`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.code,
            value: field.code
          });
        });
        setDiagnosisList(prac);

      })
      .catch(error => { });

  }, []);

  const practiceID = localStorage.getItem('default_paractice')
  const [billingProvider, setbillingProvider] = useState([]);
  const [billingProviderID, setbillingProviderID] = useState('');

  const [RENDERINGProvider, setRENDERINGProvider] = useState([]);
  const [RENDERINGProviderID, setRENDERINGProviderID] = useState('');

  const [referringPhysicianName, setreferringPhysicianName] = useState([]);
  const [referringPhysicianNameID, setreferringPhysicianNameID] = useState();

  const [procedureCode1ID, setProcedureCode1ID] = useState("");
  const [procedureCode1List, setProcedureCode1List] = useState([]);

  useEffect(() => {

    axios
      .get(
        `${process.env
          .REACT_APP_API_URL}/Providers/getPracticeProvider?parcticeid=${practiceID}`,
        { headers }
      )
      .then(response => {
        var bprov = [];
        var rprov = [];


        response.forEach(field => {
          bprov.push({
            label: field.name,
            value: field.id
          });
          rprov.push({
            label: field.name,
            value: field.id
          });
        });
        setbillingProvider(bprov);
        // setRENDERINGProvider(rprov)
      })
      .catch(error => { });
    // }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  },
    [practiceID]
  );
  useEffect(() => {

    axios
      .get(
        `${process.env
          .REACT_APP_API_URL}/ReferringPhysicians/ReferringPhysicians?parcticeid=${practiceID}`,
        { headers }
      )
      .then(response => {
        var rprov = [];

        response.forEach(field => {
          rprov.push({
            label: `${field.firstName},${field.lastName}`,
            value: field.id
          });

        });
        setreferringPhysicianName(rprov);

      })
      .catch(error => { });
    // }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  },
    [practiceID]
  );
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Procedures/Procedures`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.code,
            value: field.code,
            qasim: field.default_charges


          });

        });
        setProcedureCode1List(prac);

      })
      .catch(error => { });

  }, []);
  const [urldownload, seturldownload] = useState('')
console.log('urldownload',urldownload)
  const onSubmit = (data) => {
    const postData = data;
    setSubmitting(true)
    postData.practiceId = practiceID
    // postData.charges1 = charges
    // postData.charges2 = charges1
    postData.patientName= Namee
  //  postData.payment = true
    // postData.charges3 = charges2
postData.compeleted= true
    postData.patientId = (patientid > 0 ? patientid : selectpatientid)
    console.log('testpatientid', postData.patientId)

    //  postData.patientId= selectpatientid
    postData.insuranceName = patientinsurancename
    
    if (patientid > 0){
      postData.patientName = Namee
     }
     else{
      postData.patientName = Nameo
     }
    console.log('data Checking......', postData)


    //  for (var i = 0; i < 3; i++) {
    //     if (i == 0) {
    //         if (postData.code != "") {
    //             let procedures ={
    //                 id: proid,
    //                 claimId: claimId,
    //                 // mod1:mod1,
    //                 // mod2:mod2,
    //                 // mod3:mod3,
    //                 // mod4:mod4,
    //                 procedureSequence: 0,
    //                 dxPointer1:d,
    //                 // dxPointer2:Pointer2,
    //                 // dxPointer3:Pointer3,
    //                 // dxPointer4:Pointer4,
    //                 charges: charges,
    //                 // units:units,
    //                 // pos:placeOfService,
    //                 code:code,
    //                 // dosFrom:dateOfServiceFrom,
    //                 dosTo:dos
    //             }
    //             postData.procedures[i] = procedures;
    //         }

    //     } 
    //     else if((i == 1)) {
    //       if (postData.code != "") {
    //         let procedures ={
    //             id: proid1,
    //             claimId: claimId,
    //             // mod1:mod11,
    //             // mod2:mod22,
    //             // mod3:mod33,
    //             // mod4:mod44,
    //             dxPointer1:d1,
    //             procedureSequence: 1,
    //             // dxPointer2:Pointer22,
    //             // dxPointer3:Pointer33,
    //             // dxPointer4:Pointer44,
    //             charges: charges1,
    //             // units:units1,
    //             // pos:placeOfService1,
    //             code:code1,
    //             // dosFrom:dateOfServiceFrom1,
    //             dosTo:dos1

    //         }
        
    //         postData.procedures[i] = procedures;
    //       }
    //     }
    //     else{
    //       let procedures ={
    //         id: proid2,
    //         claimId: claimId,
    //         // mod1:mod11,
    //         // mod2:mod22,
    //         // mod3:mod33,
    //         // mod4:mod44,
    //         dxPointer1:d2,
    //         procedureSequence: 2,

    //         // dxPointer2:Pointer22,
    //         // dxPointer3:Pointer33,
    //         // dxPointer4:Pointer44,
    //         charges: charges2,
    //         // units:units1,
    //         // pos:placeOfService1,
    //         code:code2,
    //         // dosFrom:dateOfServiceFrom1,
    //         dosTo:dos2

    //     }
    
    //     postData.procedures[i] = procedures;
    //     }

    // }
    // const dia = postData.diagnosis
    // dia.id = diagId
    // dia.claimId = claimId
    // dia.dx1 = diagId0
    // dia.dx2 = diagId1
    // dia.dx3 = diagId2
    // dia.dx4 = diagId3
    // dia.dx5 = diagId4
    // dia.dx6 = diagId5
    // dia.dx7 = diagId6
    // dia.dx8 = diagId7
    // dia.dx9 = diagId8
    // dia.dx10 = diagId9
    // dia.dx11 = diagId10
    // dia.dx12 = diagId11

    // console.log('This is post data', postData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/claims/addclaims`,
        postData, { headers }
      )
      .then((response) => {
        setSubmitting(false)
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            

            props.CloseModal()
            props.applyRefresh()
          },
        });
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  const [providerError, setProviderError] = useState(false); 
  const onSubmittoluxbilling = (data) => {
    const postData = data;
    setSubmitting(true);
    postData.submitted = true;
    postData.practiceId = practiceID;
    postData.claimsubmitted = true;
    if (!data.attendingPhysician) {
      setProviderError(true);
      setSubmitting(false);
      return;
    } 
    postData.patientId = patientid > 0 ? patientid : selectpatientid;
    console.log('testpatientid', postData.patientId);
    postData.insuranceName = patientinsurancename;
    console.log('data Checking......', postData);
    const Code = [code, code1, code2];
    const Charges = [charges, charges1,charges2];
    const DiagnosisCode = [d, d1,d2];
    const DOS = [dos, dos1,dos2];
    postData.procedures = Code
    .filter((code, index) => code) 
    .map((code, index) => ({
      code, 
      charges: Charges[index] || null ,
      dosTo: DOS[index] || null ,
      dxPointer1: DiagnosisCode[index] || null ,
    }));
  
    // for (var i = 0; i < 2; i++) {
    //   if (i == 0) {
    //     if (postData.code != "") {
    //       let procedures = {
    //         id: proid,
    //         claimId: claimId,
    //         mod1: mod1,
    //         mod2: mod2,
    //         mod3: mod3,
    //         mod4: mod4,
    //         dxPointer1: code,
    //         dxPointer2: code1,
    //         dxPointer3: code2,
    //         dxPointer4: Pointer4,
    //         charges: charges,
    //         units: units,
    //         pos: placeOfService,
    //         code: code,
    //         dosFrom: dateOfServiceFrom,
    //         dosTo: dateOfServiceTo,
    //       };
  
    //       // Set procedures[i]
    //       postData.procedures[i] = procedures;
    //     }
    //   }
    // }
  
    // const dia = postData.diagnosis;
    // dia.id = diagId;
    // dia.claimId = claimId;
    // dia.dx1 = d;
    // dia.dx2 = d1;
    // dia.dx3 = d2;
    // dia.dx4 = diagId3;
    // dia.dx5 = diagId4;
    // dia.dx6 = diagId5;
    // dia.dx7 = diagId6;
    // dia.dx8 = diagId7;
    // dia.dx9 = diagId8;
    // dia.dx10 = diagId9;
    // dia.dx11 = diagId10;
    // dia.dx12 = diagId11;
  
    console.log('This is post data', postData);
    axios
      .post(`${process.env.REACT_APP_API_URL}/claims/addclaims`, postData, { headers })
      .then((response) => {
        setSubmitting(false);
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            props.CloseModal();
            props.applyRefresh();
          },
        });
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: 'error',
        });
      });
  };
  
  const onSubmitprint = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    setIsLoading(true);
    console.log(value)
    // const sendData = {
    //   ClaimId : claimId
    // }
    axios
    .post(
      `${process.env.REACT_APP_API_URL}/claims/printClaims?ClaimId=${claimId}`,
      null, { headers }
    )
    .then((response) => {
      setSubmitting(false)
      seturldownload(response)
      setIsLoading(false)
      alert.success("Record saved successfully.", {
        type: "success",
        // onClose: () => {
        //   props.CloseModal()
        //   props.applyRefresh()
        // },
      });
    })
    .catch((error) => {
      setSubmitting(false)
      setIsLoading(false)
      alert.success(`Error ${error.message}`, {
        type: 'error',
      })
    })

  }
  const download = (url) => {
    console.log('urlllll',url)
  
    // setSpinner(true)
    setSubmitting(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientDocument/DownloadAWSFile?path=${url}`, {
        headers,
        responseType: 'blob'
      })
      .then(res => {
        // setopendocs(false)
        // setSpinner(false)
        setSubmitting(false)
      
        fileDownload(res,claimId +".pdf")
      })
      .catch(err => {
        setSubmitting(false)
        console.log(err)
      })
  }

  const selectstyle = {
    height: '30px'
  };

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    background: 'white',
    fontSize: '13.6px !important',
    color: 'black !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important'
    },
    '& .super-app-theme--header': {
      backgroundColor: '#A574FD',
      color: 'white'
    }
  };
  const [providerr, setProvider] = useState()
  const [providers, setProviders] = useState([]);
  useEffect(
    () => {
      // setProviders([]);
      // setValue("provider_id", null);
      // if (userPractice != null || userPractice != undefined || userPractice > 0) {
      // POST request using axios inside useEffect React hook
      axios
        .get(
          `${process.env
            .REACT_APP_API_URL}/Providers/getPracticeProvider?parcticeid=${practiceID}`,
          { headers }
        )
        .then(response => {
          var prov = [];
          response.forEach(field => {
            prov.push({
              label: field.name,
              value: field.id
            });
          });
          setProviders(prov);
        })
        .catch(error => { });
      // }
      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    },
    [practiceID]
  );
  const [DownLoadButtonShow ,setDownLoadButtonShow] =useState(false)
  const clearSelection = (e) => {
    setValue('patientName', '');
    setPatientData([]);
    setPatient(false);
    setPatientNamee('');
    setSearchText('');
    setselectpatientid('')
    setRefresh(true)
    onChangeHandle(e.target.value)
  };
  return (
    <React.Fragment>
      <div  >
        <Container>
          {/* <Typography variant='h5' color='black' fontWeight="bold">Claim Registration</Typography> */}
          <Grid container spacing={-2} sx={{ flexGrow: 1, rowGap: "24px" }}>
          <Grid xs={12}>
              <Typography
                level="h2"
                style={{
                  padding: "auto",
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Provider rendering services 
                (Name):
              </Typography>
           
              <Controller
                name="attendingPhysician"
                // onChange={value => setProvider('provider_id', value)}
                {...register("attendingPhysician")}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    default
                    size="small"
                    style={{
                      width: "50%",
                    }}
                    value={providers.filter(function (option) {
                      return option.value == getValues("attendingPhysician");
                    })}
                    onChange={value => {
                      // setValue("renderingProvider", value.value);
                      // setValue("attendingPhysicianName", value.label);
                      setValue("attendingPhysician", value.value);

                      // setProviders(value.value);

                      setProviderError(false)
                    }}
                    options={providers}
                  
                  />
                )}
              />
        
              {providerError && <p style={{ color: 'red', fontSize: '14px' }}>Please select Provider.</p>}
            </Grid>
            
        
             <Grid xs={12} sm={6} md={6} lg={6}>
              <Typography style={{ fontSize: "18px" }}>Patient Name:</Typography>
              <ClickAwayListener onClickAway={() => setPatient(false)}>
                <TextField
                  {...register('patientName')}
                  size="small"
                  autoComplete="off"
                  style={{ width:'99%' }}
                  placeholder="Enter Patient Name"
                  {...(patientid > 0 ?{ value: Namee } :{})}
                  {...(patientid < 0 ?{ value: Nameo } :{})}
                  // disabled={selectpatientid > 0}
             
                  InputProps={{
                    readOnly: claimId > 0,
                    endAdornment:  claimId == 0  && (
                      <InputAdornment position='end'>
                         {selectpatientid > 0 && (
              <CancelOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} onClick={clearSelection} />
            )}
                      </InputAdornment>
                    )
                  }}

            
                  onKeyUp={(ev) => {
                    console.log('ee:', ev.target.value);
                    setSearchText(ev.target.value);
                    setPatientData([]);

                    // dont fire API if the user delete or not entered anything
                    if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                      onChangeHandle(ev.target.value);
                    } else {
                      setPatient(false);

                    }
                  }}
                  error={errors.patientName}
                  helperText={errors.patientName ? errors.patientName.message : ''}

                />
              </ClickAwayListener>
              {isLoading && (
                <CircularProgress size={20} style={{ marginLeft: '-40px', }} />
              )}
              {/* <p style={{ color: "red", fontSize: "14px" }}>
                {errors.patientName?.message}
              </p> */}
              {patient ? (
                <div
                  className="col-xs-6"
                  style={{
                    height: 150,
                    overflowY: 'scroll',
                    padding: '14px 16px',
                    fontSize: '0.875rem',
                    color: '#67748e',
                    borderRadius: '5px',
                    background: 'white',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    position: 'absolute',
                    zIndex: '99',
                    width: '39%',
                    border: '1px solid #6cb5f9'
                  }}
                >
                  {patient ? (
                    patientData.length > 0 ? (
                      patientData.map((s, index) => {
                        var pat = `${patientData[index].lastName}, ${patientData[index].firstName}`
                       
                        const searchTextLower = searchText.toLowerCase();
                        const fullNameLower = pat.toLowerCase();
                        const i = fullNameLower.indexOf(searchTextLower);
                  
                        if (i === -1) {
                          return null;
                        }
                  
                        const p1 = pat.substring(0, i);
                        const p = pat.substring(i, i + searchText.length);
                        const p2 = pat.substring(i + searchText.length);

                        return (
                          <>
                            <p
                              style={patientListStyle}
                              onClick={(e) => {
                                setValue(
                                  'patientName',
                                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                                );
                                setValue('patientName', `${patientData[index].lastName}, ${patientData[index].firstName}`)
                                setPatientData([]);
                                setPatient(false);
                                setPatientNamee(
                                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                                );
                                setselectpatientid(patientData[index].id)
                              }}
                              onBlur={(e) => {
                                setPatientData([]);
                                setPatient(false);
                              }}
                              onChange={(e) => {
                                setPatientData([]);
                                setPatient(false);
                                setPatientNamee('');
                              }}
                            >
                              <span>{p1}</span>
                              <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                              <span>{p2}</span>
                              {/* {s.patientId} */}
                              {/* <Divider variant="hard" /> */}
                            </p>
                          </>
                        );
                      })
                    ) : (
                      <Typography>No Record found</Typography>
                    )
                  ) : null}
                </div>
              ) : (
                ''
              )}
            </Grid>
       
           
            <Grid xs={12} sm={6} md={6} lg={6}>
              <Typography style={{ fontSize: "18px", marginLeft: '20px' }}>DOB:</Typography>
           
              <Controller
                name="dob"
                // {...register("dob")}
                control={control}
                render={({ field }) => (
                  <TextField
                    

                    size="small"
                  

                    {...field}
                    name="dob"
                    style={{ width:'99%' }}
                    type="date"
                    value={patientDOB}
                    onChange={e => {
                      setValue('dob', e.target.value)
                      setpatientDOB(e.target.value)
                    }}

                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6}>
              <Typography style={{ fontSize: "18px" }}>Address:</Typography>
              <Controller
                name="address"
                // {...register("dob")}
                control={control}
                render={({ field }) => (
                  <TextField
                  
                    style={{ width:'99%' }}
                    size="small"
                    fullWidth
                    {...field}
                    name="address"

                    // type="date"
                    value={patientiaddress}
                    // value={`${patientDetails.address}, ${patientDetails.city}, ${patientDetails.state}, ${patientDetails.zipcode}`}
                    // onChange={e => {
                    //   setValue('address', e.target.value)
                    //   setpatientaddress(e.target.value)
                    // }}
                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                  />
                )}
              />


            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6}>
                <Typography style={{ fontSize: "18px" }}> Referring Physician:- </Typography>
                <Controller
                  name="referringPhysician"
                  {...register("referringPhysician")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={referringPhysicianName.find(option => option.value === getValues("referringPhysician"))}
                      onChange={value => {
                        setValue("referringPhysician", value != null ? value.value : "");
                      }}
                      options={referringPhysicianName}
                    />
                  }
                />
              
             </Grid>

            <Grid xs={12} sm={6} md={6} lg={6}>
              <Typography style={{ fontSize: "18px" }}> Insurance</Typography>
              <Controller
                name="insuranceName"
                {...register("insuranceName")}
                control={control}
                render={({ field }) => (
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    // type="date"
                    size="small"
                    
                    {...field}
                    style={{ width:'99%' }}
                    value={patientinsurancename}
                    onChange={e => {
                      setValue('insuranceName', e.target.value)
                      setpatientinsurancename(e.target.value)
                    }}

                    inputProps={{
                      style: {
                        minHeight: '38px',
                        padding: '0 1px'
                      },
                      readOnly: true,  // Corrected prop name
                    }}
                  />
                )}
              />


            </Grid>
          
            <Grid xs={12} sm={6} md={6} lg={6}>
              <Typography style={{ fontSize: "18px" }}>DOS:</Typography>
            
              <Controller
                name="dos"
                // {...register("dob")}
                control={control}
                render={({ field }) => (
                  <TextField
                    
                    size="small"
                    style={{ width:'99%' }}
                    {...field}
                    name="dos"
                    type="date"
                    value={dos ? dos : ''}
                    onChange={e => {
                      setValue('dos', e.target.value)
                      setdos(e.target.value)
                    }}
                    error={errors.dos}

                  />
                )}
              />
              <p style={{ color: "red", fontSize: "14px" }}>
                {errors.dos?.message}
              </p>
            </Grid>
      <Grid xs={12} sm={6} md={6} lg={6}>
              <Typography style={{ fontSize: "18px" }}>Authorization Number:</Typography>
            
              <Controller
                name="authNumber"
                {...register("authNumber")}
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    style={{ width:'99%' }}
                    {...field}
                    type="text"
                  />
                )}
              />
         
            </Grid>
            {/* <Grid item xs={12}>
              <Typography level="body 1">
                (Please send the insurance card as well in case we need to reference)
              </Typography>
            </Grid> */}
            <Grid xs={12}>
              <Typography
                level="h1"
                style={{
                  padding: "auto",
                  textAlign: "center",
                  fontSize: "35px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Claim details you wish to submit
              </Typography>
            </Grid>


            <Grid container >

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                  Procedure code:
                </Typography>
           
                <Controller
                  name="cpt1"
                  {...register("cpt1")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={procedureCode1List.find(option => option.value == code)}
                                                
                      onChange={e => {
                        setcode(e.value);
                          setcharges(e.qasim)
                          setValue("cpt1", e.value)
                        
                      }}
                      // value={procedureCode1List.filter(function (option) {
                      //   return (
                      //     option.value === getValues("cpt1")
                      //   );
                      // })}
                      // onChange={e => {
                      //   console.log('eeee', e)
                      //   setValue("cpt1", e.value);
                      //   setcharges(e.qasim);
                      
                      //   setProcedureCode1ID(e.value);

                      // }}
                      styles={{ control: provided => ({ ...provided, width: '99%' }) }}
                      options={procedureCode1List}
                    />

                  }
                />


              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                  Diagnosis code:
                </Typography>
              

                <Controller
                  name="dx1"
                  {...register("dx1")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={diagnosisList.find(option => option.value === d)}
                                                
                      onChange={e => {
                        setd(e.value);
                        setValue("dx1",e.value)
                         
                        
                      }}                      // value={diagnosisList.find(option => option.value === diagId0)}
                     
                      // onChange={e => {
                       
                      //   setdiagId0(e.value);

                      // }}
                      styles={{ control: provided => ({ ...provided, width: '99%' }) }}

                      options={diagnosisList}
                    />

                  }
                />
              </Grid>
              
              <Grid item xs={12} sm={6} md={4} lg={3} >
                <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                  DOS
                </Typography>


                <Controller
                  name='dos'
                  margin='dense'
                  {...register('dos')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size='small'
                      {...field}
                      style={{ width: '99%' }}
                      type="date"
                      value={dos ? dos : ''}
                      onChange={e => {
                        setValue('dos', e.target.value)
                        setdos(e.target.value)
                      }}

                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                  Charge $
                </Typography>
                <Controller
                  name='charges1'
                  margin='dense'
                  {...register('charges1')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size='small'
                      {...field}
                      style={{ width: '99%' }}
                      value={charges}
                      onChange={e => {
                        setValue('charges1', e.target.value)
                      }}

                    />
                  )}
                />

              </Grid>

            </Grid>

            <Grid container >

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                  Procedure code:
                </Typography>
                <Controller
                  name="cpt2"
                  {...register("cpt2")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={procedureCode1List.find(option => option.value == code1)}
                                                
                      onChange={e => {
                        setcode1(e.value);
                          setcharges1(e.qasim)
                          setValue("cpt2", e.value)
                        
                      }}
                      // value={procedureCode1List.filter(function (option) {
                      //   return (
                      //     option.value === getValues("cpt2")
                      //   );
                      // })}
                      // onChange={e => {
                      //   console.log('eeee', e)
                      //   setValue("cpt2", e.value);
                      //   setcharges1(e.qasim);
                     
                      //   setProcedureCode1ID(e.value);

                      // }}
                      styles={{ control: provided => ({ ...provided, width: '99%' }) }}
                      options={procedureCode1List}
                    />

                  }
                />
                {/* <Controller
                    name="code"
                      control={control}
                      render={({ field }) => (
                       <ReactSelect
                         variant="filled"
                         {...field}
                         default
                          style={{ height: '30px' }}
                          size="small"
                          value={procedureCode1List.find(option => option.value === code1)}
                                                
                           onChange={e => {
                           setcode1(e.value);
                            setcharges1(e.qasim)
                                                  
                             }}
                              options={procedureCode1List}
                          styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                                                
                              />
                             )}
                         /> */}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                  Diagnosis code:
                </Typography>
            
                <Controller
                     name="dx2"
                     {...register("dx2")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={diagnosisList.find(option => option.value === d1)}
                                                
                      onChange={e => {
                        setd1(e.value);
                        setValue("dx2",e.value)
                         
                        
                      }}

                      
                      // value={diagnosisList.find(option => option.value === diagId1)}

                      // onChange={e => {

                      //   setdiagId1(e.value);

                      // }}
                      styles={{ control: provided => ({ ...provided, width: '99%' }) }}

                      options={diagnosisList}
                    />

                  }
                />
              </Grid>

              
              <Grid item xs={12} sm={6} md={4} lg={3} >
                <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                  DOS
                </Typography>

            

                <Controller
                  name='dos1'
                  margin='dense'
                  {...register('dos1')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size='small'
                      {...field}
                      style={{ width: '99%' }}
                      type="date"
                      value={dos1 ? dos1 : ''}
                      onChange={e => {
                        setValue('dos1', e.target.value)
                        setdos1(e.target.value)
                      }}

                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                  Charge $
                </Typography>
             
                <Controller
                  name='charges2'
                  margin='dense'
                  {...register('charges2')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size='small'
                      {...field}
                      style={{ width: '99%' }}
                      value={charges1}
                      onChange={e => {
                        setValue('charges2', e.target.value)
                      }}

                    />
                  )}
                />

              </Grid>




            </Grid>

            <Grid container >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                  Procedure code:
                </Typography>
              
                <Controller
                  name="cpt3"
                  {...register("cpt3")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={procedureCode1List.find(option => option.value == code2)}
                                                
                      onChange={e => {
                        setcode2(e.value);
                          setcharges2(e.qasim)
                          setValue("cpt3", e.value)
                        
                      }}
                      // value={procedureCode1List.filter(function (option) {
                      //   return (
                      //     option.value === getValues("cpt3")
                      //   );
                      // })}
                      // onChange={e => {
                      //   console.log('eeee', e)
                      //   setValue("cpt3", e.value);
                      //   setcharges2(e.qasim);
                      
                      //   setProcedureCode1ID(e.value);

                      // }}
                      styles={{ control: provided => ({ ...provided, width: '99%' }) }}
                      options={procedureCode1List}
                    />

                  }
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                  Diagnosis code:
                </Typography>
         
                <Controller
                name="dx3"
                {...register("dx3")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      value={diagnosisList.find(option => option.value === d2)}
                                                
                      onChange={e => {
                        setd2(e.value);
                        setValue("dx3",e.value)
                        
                      }}
                      // value={diagnosisList.find(option => option.value === diagId2)}
                     
                      // onChange={e => {
                      
                      //   setdiagId2(e.value);

                      // }}
                      styles={{ control: provided => ({ ...provided, width: '99%' }) }}

                      options={diagnosisList}
                    />

                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} >
                <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                  DOS
                </Typography>

             

                <Controller
                  name='dos2'
                  margin='dense'
                  {...register('dos2')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size='small'
                      {...field}
                      style={{ width: '99%' }}
                      type="date"
                      value={dos2}
                      onChange={e => {
                        setValue('dos2', e.target.value)
                        setdos2(e.target.value)
                      }}

                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                  Charge $
                </Typography>
                <Controller
                  name='charges3'
                  margin='dense'
                  {...register('charges3')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size='small'
                      {...field}
                      style={{ width: '99%' }}
                      value={charges2}
                      onChange={e => {
                        setValue('charges3', e.target.value)
                      }}

                    />
                  )}
                />
                {/* <Controller
            name='charge1'
            margin='dense'
          
            control={control}
            render={({ field }) => (
             
              <TextField
              // {...register('charges')}
              fullWidth
              {...field}
              id="standard-basic"
              size="small"
              style={{width:'120px'}}

              variant="filled"
              value={charges2}
              // onChange={e => {
              //     setcharges(e.target.value)

              // }}
              inputProps={{
                  style: {
                      minHeight: '38px',
                      padding: '0 1px'
                  }
              }}
          />
            )}
          /> */}

              </Grid>
            </Grid>
            {UserType == 'Front Office' || UserType == 'Front Office Admin'?(<>
            <Grid container spacing={1}>

          
       
      <Grid item xs={12} >
      <Typography
          sx={{
           
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Front Office Notes:
        </Typography>

      </Grid>
      {/* <Grid item xs={6} >
      <Typography
          sx={{
           
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
           Back Office Notes:
        </Typography>

      </Grid> */}
 

      <Grid item xs={12} style={{marginTop:"10px"}}>
      <Controller
          name='frontnote'
          {...register('frontnote')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px', // set a fixed height here
              }}
              {...field}
              fullWidth
             
            />
          )}
        />

      </Grid>
      {/* <Grid item xs={6} style={{marginTop:"10px"}}>
      <Controller
          name='backnote'
          {...register('backnote')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={5}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '100px', // set a fixed height here
              }}
              {...field}
              fullWidth
              readOnly={true}
            />
          )}
        />

      </Grid> */}
      </Grid>

      
      </>):(<>
        <Grid container spacing={1}>
      <Grid item xs={6} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
         Back Office Notes:
        </Typography>

      </Grid>
      <Grid item xs={6} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
         Front Office Notes:
        </Typography>

      </Grid>
      <Grid item xs={6} style={{marginTop:"10px"}}>
      <Controller
          name='backnote'
          {...register('backnote')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px', // set a fixed height here
              }}
              {...field}
              fullWidth
              
            />
          )}
        />

      </Grid>
      <Grid item xs={6} style={{marginTop:"10px"}}>
      <Controller
          name='frontnote'
          {...register('frontnote')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px', // set a fixed height here
              }}
              {...field}
              fullWidth
              readOnly={true}
             
            />
          )}
        />

      </Grid>
      </Grid>
      
      
      </>)}
            <Grid item xs={0.3}></Grid>
            <Grid item xs={0.7} style={{ marginTop: '3px',float:"right" }}>
              <input
                name='acceptAssignment'
                size='small'
                type='checkbox'
                {...register('acceptAssignment')}
                id='acceptAssignment'
              />

            </Grid>
            <Grid item xs={11}>
              <Typography
                level="body 1"
                style={{
                  fontSize: "18px",

                }}
              >
                <b>Accept Assignment</b> (payment will go directly to your office)
              </Typography>
            </Grid>
            <Grid item xs={0.3}></Grid>
            <Grid item xs={0.7} style={{ marginTop: '3px',float:"right" }}>
              <input
                name='dontAcceptAssignment'
                size='small'
                type='checkbox'
                {...register('dontAcceptAssignment')}
                id='dontAcceptAssignment'
              />

            </Grid>
            <Grid item xs={11}>
              <Typography
                level="body 1"
                style={{
                  fontSize: "18px",

                }}
              >
                <b>Do NOT Accept Assignment</b> (payment issued to the patient)
              </Typography>
            </Grid>
            <Grid item xs={1} ></Grid>

            <Grid item xs={2}></Grid>
            {submittedprop == true?(<>
              <Grid item xs={10}>
<Typography style={{fontWeight:'bold', color:'red'}}> This Record already Submit</Typography>

              </Grid>
            </>):(<>
            
            </>)}
         

            

            <Grid container spacing={1}>
            {UserType == 'Front Office' ?(<>
              <Grid item xs={3} ></Grid>
            </>):(<>

              {DownLoadButtonShow && (

              <Grid item xs={2} >

              <Button
              // className='Button'
              startIcon={<Download />}
              onClick={() => {
              download(`${urldownload}`)
              }}
              variant="gradient"
              disabled={submitting}
              style={{
              marginTop: '0px',
              marginBottom: '55px',
              float: 'right',
              // marginRight: '32px',
              width: '120px',
              height: '35px',
              backgroundColor: 'red',
              color: 'white',
              fontSize: '14px'
              }}
              >

              Download 
              </Button>

              </Grid>
              )}

                <Grid item xs={2} >

                <Button
                  className='Button'
                  
                  // onClick={onSubmitprint}
                  onClick={() => {
                    onSubmitprint();
                    setDownLoadButtonShow(true);
                  }}
                  variant="gradient"
                  disabled={submitting}
                  style={{
                    marginTop: '0px',
                    marginBottom: '55px',
                    float: 'right',
                    // marginRight: '62px',
                    width: '120px',
                    float:'right',
                    height: '35px',
                    // backgroundColor: '#A574FD',
                    color: 'white',
                    fontSize: '14px'
                  }}
                >
                  <PrintIcon
                    fontSize="medium"
                    style={{
                      color: 'white',
                      paddingRight: '5px',
                      paddingBottom: '2px'
                    }}
                  ></PrintIcon>
                  Print 
                </Button>

                </Grid>
            </>)}
            {UserType == 'Front Office' || UserType == 'Front Office Admin' && submittedprop == false ?(<>

              <Grid item xs={4} lg={4} sm={4}>

           <Button
                className='Button'
                onClick={handleSubmit(onSubmittoluxbilling)}
                variant="gradient"
                disabled={submitting}
                style={{
                  marginTop: '0px',
                  marginBottom: '55px',
                  float: 'right',
                  marginRight: '12px',
                  width: '240px',
                  height: '35px',
                  // backgroundColor: '#A574FD',
                  color: 'white',
                  fontSize: '14px'
                }}
              >
                <SaveOutlinedIcon
                  fontSize="medium"
                  style={{
                    color: 'white',
                    paddingRight: '5px',
                    paddingBottom: '2px'
                  }}
                ></SaveOutlinedIcon>
                Submit to luxbilling
              </Button>

          </Grid>
            
            </>):(<>
            {UserType == 'Admin' && submittedprop == false ?(
              <>
              <Grid item xs={4} lg={4} sm={4}>

<Button
     className='Button'
     onClick={handleSubmit(onSubmittoluxbilling)}
     variant="gradient"
     disabled={submitting}
     style={{
       marginTop: '0px',
       marginBottom: '55px',
       float: 'right',
       marginRight: '12px',
       width: '300px',
       height: '35px',
       // backgroundColor: '#A574FD',
       color: 'white',
       fontSize: '14px'
     }}
   >
     <SaveOutlinedIcon
       fontSize="medium"
       style={{
         color: 'white',
         paddingRight: '5px',
         paddingBottom: '2px'
       }}
     ></SaveOutlinedIcon>
     Submit Claim to Luxbilling
   </Button>

</Grid>
            
            </>):(<>
             
            
            </>)}
             
            
            
            </>)}
            <Grid item xs={4} >
            
            <Button
              className='Button'
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '55px',
                float: 'right',
                marginRight: '12px',
                width: '300px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px',
                marginLeft:'15px'
              }}
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{
                  color: 'white',
                  paddingRight: '5px',
                  paddingBottom: '2px'
                }}
              ></SaveOutlinedIcon>
              Save and mark claim paid
              
             
            </Button>

          </Grid>

            </Grid>
           
            
           
        
          
          </Grid>
        </Container>
      </div>
    </React.Fragment>

  )

}

export default ClaimRegistration