// ** React Imports
import { useState, Fragment, useEffect } from 'react'
import * as React from 'react'
// ** Next Import
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import Download from '@mui/icons-material/Download'
import fileDownload from 'js-file-download';
import Headroom from "react-headroom";

import ClickAwayListener from '@mui/material/ClickAwayListener';
// ** MUI Imports
import Tooltip from "@mui/material/Tooltip";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Moment from 'moment'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
// import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
// ** Icons Imports
// import CogOutline from 'mdi-material-ui/CogOutline'
// import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
// import EmailOutline from 'mdi-material-ui/EmailOutline'
// import AccountOutline from 'mdi-material-ui/AccountOutline'
// import MessageOutline from 'mdi-material-ui/MessageOutline'
// import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import axios from 'axios'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import MailIcon from '@mui/icons-material/Mail'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Backdrop } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// ** Context
// import { useAuth } from 'src/hooks/useAuth'
import { Autocomplete, Button, Grid, TextareaAutosize, TextField, InputLabel } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import { useAlert } from 'react-alert'
import { Container } from '@mui/system'
import { WEBKIT } from 'stylis'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import AddTaskIcon from '@mui/icons-material/AddTask'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Documents from '../../../Pages/Documents';
// import { ChartLineStacked } from 'mdi-material-ui'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const NotificationDropdown = (props) => {

  const [refresh, setRefresh] = useState(false)

  //  const history = createBrowserHistory({ forceRefresh: true })
  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const [anchorEl, setAnchorEl] = useState(null)

  const router = useNavigate()

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleDropdownClose = url => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [Delbyid, setDelbyid] = useState(false);
  const [Patientiidresolve, setPatientiidresolve] = useState('');
  const [subject1, setsubject1] = useState('');
  const [Name1, setName1] = useState('');
  const [assginedto, setassginedto] = useState('');
  const [commentsnotifcation, setcommentsnotifcation] = useState('');
  const [date, setdate] = useState('')
  const handleDelete = () => {
    const postData = {
      id: Delbyid,
      resolved: false,
      patientName: Name1,
      patientId: Patientiidresolve,
      subject: subject1,
      assignedTo: assginedto,
      comments: commentsnotifcation,
      createdDate: date,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Notifications/AddNotifications`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(true);
        RefreshApis();
        console.log("Response:", response);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    // Close the dialog
    setDialogOpen(false);
  };

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }


  //// Add Modal
  const [TmdId, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)

  const [open, setOpen] = React.useState(false)
  const handleOpen = (row) => {
    setOpen(true)
    setSelectedRowData(row);

  }

  const handleClose = () => {
    reset();
    setOpen(false);
    setdueDate('')
    setsubject('')
    setIsSubmitting(false)
    setAssignedTo('')
    setSelectedRowData({});

  };
  const [openDocument, setOpenDocument] = React.useState(false);
  const handleCloseDocument = () => { setOpenDocument(false) }
  const Modelstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: '#F0FFFF',
    border: '2px solid #377562',
    boxShadow: 24,
    p: 2,
    overflow: 'auto'
  }
  const style = {
    position: 'absolute',
    marginLeft: '5px',
    // marginRight: '100px',
    // py: 2,
    // px: 4,
    width: '100%',
    height: '100%',
    // display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
    RefreshApis()

  };
  const alert = useAlert()
  const [spinner, setSpinner] = useState(false)

  const [submitting, setSubmitting] = useState(false)
  const validationSchema = Yup.object({
    // emailAddress: Yup.string().required('Email is required'),
    // emailAddress: Yup.string()
    subject: Yup.string()
      .required('Subject is required'),
      patientName: Yup.string()
      .required('Patient name is required')
      .test('patient-selected', 'Please select a valid patient', function (value) {

        return !!this.parent.patientId && !!value;
      })
    //   .required('Email is invalid')
    //   .email('Email is invalid'),
    // patientName: Yup.string().required('Patient Name is required'),
    // assignedTo: Yup.string().required('Please Select a User'),
    // createdDate: Yup.date().required('Please Select a date'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
  })
  const download = (url, filename) => {
    console.log(url)
    console.log(filename)
    setSpinner(true)
    setSubmitting(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientDocument/DownloadAWSFile?path=${url}`, {
        headers,
        responseType: 'blob'
      })
      .then(res => {
        setopendocs(false)
        setSpinner(false)
        setSubmitting(false)
        console.log(res.data)
        fileDownload(res, filename)
      })
      .catch(err => {
        setSubmitting(false)
        console.log(err)
      })
  }
  const [dob, setdob] = React.useState()
  const [dueDate, setdueDate] = React.useState()
  const [patientData, setPatientData] = React.useState([])
  const [patient, setPatient] = React.useState(false)
  const [patientSelected, setPatientSelected] = React.useState(false)
  const [patientId, setPatientId] = React.useState('')
  const [openDetails, setOpenDetails] = React.useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [CountMessage, setMessage] = useState('New');

  // const [jsonR, setJsonR] = useState([]);
  // const newPost = {
  //   lastName: '',
  //   firstName: '',
  // };

  // useEffect(() => {
  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}/Patients/searchPatient`, newPost, { headers })
  //     .then(response => {
  //       setJsonR(response);
  //       console.log('autoData', response);
  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error);
  //     });
  // }, []); // Fix: Add newPost to the dependency array
  const searchData = {
    id: 0,
    patientId: '',
    firstName: '',
    lastName: '',
  }

  // useEffect(() => {
  //   // Filter patientData to remove the selected patient
  //   if (selectedPatient) {
  //     setFilteredOptions(
  //       patientData.filter(
  //         option => 
  //           `${option.firstName} ${option.lastName}` !== `${selectedPatient.firstName} ${selectedPatient.lastName}`,
  //         )
  //             );
  //   } else {
  //     // If no patient is selected, show all options
  //     setFilteredOptions(patientData);
  //   }
  // }, [selectedPatient, patientData]);

  const onOptionChange = (event, value) => {

    setSelectedPatient(value); // Update the selected patient state
    // Handle patient selection if needed
    if (value && value.id > 0) {
      console.log('Selected Value:', value);
      setPatientId(value.id)
      // setPatientId(value.patientName)
    }
    else {
      setPatientId(0)
    }
  };


  function applyRefresh() {
    setRefresh(!refresh)
    // console.log('save k bd data Refresh ho gya')
  }
  ///Save Apis
  const searchValues = {
    patientId: patientId,
    description: '',
    assignedTo: '',
    resolvedDate: '',
    createdDate: '',
    userType: '',
    comments: '',
    subject: '',
  };


  // Notification Dropdown
  const [patientName, setPatientName] = useState('')
  const [patientID, setPatientID] = React.useState(props.patientId)
  const [isLoading, setLoading] = useState('')
  const [isLoading1, setLoading1] = useState('')
  const [patientNamee, setPatientNamee] = useState('')
  const [Notification, setNotification] = useState(null);
  const [Data , setData] = useState(null);
  const [Notification1, setNotification1] = useState(null)


  const [Totalcount, setTotalcount] = useState(0)
  const [TotalcountResolved, setTotalcountResolved] = useState(0)

  const [selectedRow, setselectedRow] = useState('')
  const [selectedindex, setselectedindex] = useState('')
  const [id, setid] = useState('')
  const [documentsName, setdocumentsName] = useState('')
  console.log(documentsName, 'documentsNamedocumentsName')
  const checkstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    padding: '20px',
    bgcolor: "background.paper",
    border: "2px solid #377562",
    boxShadow: 24,

  };
  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?resolved=${true}`, { headers })
  //     .then(response => {
  //       console.log('response.mydata: ', response)
  //       setNotification(response)
  //       setTotalcount(response?.length)
  //       setLoading(false)
  //        setRefresh(true)

  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error)
  //     })
  // }, [])

  // useEffect(() => {
  //   if (Totalcount > 0) {
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?resolved=${true}`, { headers })
  //       .then(response => {

  //         alert.success('New notification added!.', {
  //           type: 'success',
  //           onClose: () => {
  //             setTotalcount(Totalcount + 1)
  //           }

  //         })

  //       })
  //       .catch(error => {
  //         console.error('There was an error!', error)
  //       })
  //   }
  // }, [])

  const handleRemove = selectedIndex => {
    const updatedNotification = Data.filter((_, index) => index !== selectedIndex)
    setData(updatedNotification)
  }
  const [Bool, setBool] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = (data) => {
    const postData = {
      ...searchValues,
      description: data.description,
      assignedTo: assignedTo,
      patientId: patientID,
      patientName: data.patientName,
      resolvedDate: data.resolvedDate,
      createdDate: data.createdDate,
      userType: data.userType,
      comments: data.comments,
      subject: subject,
      id: TmdId ,
    };
    // if (Bool == true) {
    //   postData.id= TmdId ;
    //   postData.description = selectedRowData.description;
    //   postData.assignedTo = assignedTo
    //   postData.patientId = patientID;
    //   // postData.id = selectedRowData.id;
    //   postData.inactive = false;
    //   postData.patientName = selectedRowData.patientName;
    //   postData.resolvedDate = selectedRowData.resolvedDate;
    //   postData.createdDate = selectedRowData.createdDate;
    //   postData.userType = selectedRowData.userType;
    //   postData.subject = selectedRowData.subject;
    //   postData.parentId = selectedRowData.id;

    // }
    // console.log('Onsubmit',postData)
    setIsSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/Notifications/AddNotifications`, postData, { headers })
      .then(response => {
        setIsSubmitting(true)
        RefreshApis()
        alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            handleClose()
            applyRefresh()
          }
        })
      })
      .catch(error => {
        alert.success(`Error ${error.message}`, {
          type: 'error'
        });
      })
     
  };
  useEffect(() => {
    setRefresh(true)
  }, [refresh]);

  const [subject, setsubject] = useState('')


  const [resolvedComments, setResolvedComments] = useState('');
  const [resolvedDate, setResolvedDate] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);


  const saveData = data => {
    console.log('testttttt')
    const postData = {
      ...data,
      resolvedDate: resolvedDate,
      resolvedComments: resolvedComments,
      id: selectedRow,
      resolved: true
    }
    if (!resolvedDate) {
      setErrorMessage(true);
      return;
    }
    // if(apptypeError)
    axios
      .post(`${process.env.REACT_APP_API_URL}/Notifications/addNotifications`, postData, { headers })
      .then(response => {
        setid(null)
        Data.splice(selectedindex, 1)
        handleRemove(selectedindex)
        RefreshApis()
        alert.success('Issue resolved successfully.', {
          type: 'success',
          onClose: () => {
            setTotalcount(Totalcount - 1)
            setSubmitting(false)
            setRefresh(true)
            reset()
          }
        })
      })
      .catch(error => {
        setSubmitting(false)
        alert.error(`Error ${error.message}`, {
          type: 'error',
          onClose: () => {
            console.log(`Error Alert: ${error.message}`);
          }
  
        })
        setRefresh(true)
      })
    setRefresh(true)

  }
  // const [admin, setadmin] = useState('')
  const errorMesage = {
    color: 'red',
    fontSize: '13px',
    marign: '0px'
  }
  const zipCodeListStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  }
  const PatientStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  }
  const [practiceID, setPracticeID] = useState("");
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?resolved=${false}`, { headers })
      .then((response) => {
        const filteredNotifications = response.filter(item => item.redbit == false);
          setTotalcount(filteredNotifications?.length);
       
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, []);
  const [timeLeft, setTimeLeft] = useState(120); 
  useEffect(() => {
    const fetchNotifications = () => {
     
      axios
        .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?resolved=${false}`, { headers })
        .then(response => {
          // setNotification(response)
          // setPracticeID(response.practiceId)
          setLoading(false)
          const filteredNotifications = response.filter(item => item.redbit == false);
          setTotalcount(filteredNotifications?.length);
          // RefreshApis()
        })
        .catch(error => {
          console.error('There was an error fetching the notifications!', error);
        });
    };

    fetchNotifications(); 

    const timerId = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
            fetchNotifications(); 
            RefreshApis();
          return 120;
        }
        return prevTime - 1; 
      });
    }, 1000); 

    return () => clearInterval(timerId);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
  };
  const handleDropdownOpenNotification = (event) => {
    console.log('Notification open')
    setNotification(event.currentTarget)
    setLoading(true)
    setRefresh(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?resolved=${false}`, { headers })
      .then(responses => {
        setData(responses)
        setPracticeID(responses.practiceId)
        setLoading(false)
        const filteredNotifications = responses.filter(item => item.redbit == false);
        setTotalcount(filteredNotifications.length);

      })
      .catch(error => {
        console.error('There was an error!', error)
      })

    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getinbox?resolved=${true}`, { headers })
      .then(response => {
        console.log('response.data: ', response)
        setNotification1(response)
        setPracticeID(response.practiceId)
        setTotalcountResolved(response.length)
        // setTotalcount(response?.length)
        setLoading(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })

  }


  const RefreshApis = () => {
    setLoading(true)
    setRefresh(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?resolved=${false}`, { headers })
      .then(res => {
        setData(res)
        setPracticeID(res.practiceId)
        const filteredNotifications = res.filter(item => item.redbit == false);
        setTotalcount(filteredNotifications.length);
        setLoading(false)
        // if (isFirstCall) {
        //   return;
        // } else {
        //   setTotalcount(response?.length);
        // }
        // setIsFirstCall(false);
        // response.forEach(item => {
        //   const documentParts = item.documents?.split('@@');
        //   console.log(documentParts,'documentsName,,')
        //   setdocumentsName(documentParts[0]);

        // });


      })
      .catch(error => {
        console.error('There was an error!', error)
      })

    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getinbox?resolved=${true}`, { headers })
      .then(response => {
        console.log('response.data: ', response)
        setNotification1(response)
        setPracticeID(response.practiceId)
        setTotalcountResolved(response?.length)
 
        // setTotalcount(response?.length)
        setLoading(false)



      })
      .catch(error => {
        console.error('There was an error!', error)
      })

  }
  const onChangeHandle = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    setLoading1(true);
    console.log(value)
    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: '',
      dob: '',
      patientId: '',
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(false)
      )
      .then(response => {
        setPatientData(response)
        setPatient(true)
        setLoading1(false);
      })
      .catch(error => {
        console.error('There was an error!', error)
        setLoading1(false);
      })

  }

  const handleDropdownCloseNotification = url => {
    if (url) {
      router.push(url)
    }
    setNotification(null)
  }
  const [assignedList, setassignedList] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);

  const [practicesList, setpracticesList] = useState([]);

  const practicesListid = localStorage.getItem('default_paractice')
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/UserPractices/users?tokenPracticeId=${practicesListid}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: `${field.fullName}<${field.email}>`,
            value: field.email
          });
        });
        setpracticesList(prac);
        // response.forEach((field) => {
        //   practicesList.push({
        //     label: field.practiceName,
        //     value: field.id,
        //   });
        // });
      })
      .catch(error => { });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  const [opendocs, setopendocs] = useState(false)
  const [urlFile, seturlFile] = useState('')
  const [urldownload, seturldownload] = useState('')
  const [DocName, setDocName] = useState('')
  const [FullDocName, setFullDocName] = useState('')
  console.log(FullDocName, 'urldownload,,')
  const styleofmodal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    // height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #377562',
    boxShadow: 24,
    p: 4
  }
  //Reply Modal 

  const [selectedRowData, setSelectedRowData] = useState({});

  const [OpenReply, setOpenReply] = React.useState(false)
  const handleOpenReply = (row) => {
    setOpenReply(true);
  };
  const handleCloseReply = () => {
    reset();
    setOpenReply(false);

  };
  const ReplyStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 300,
    padding: '10px',
    bgcolor: "background.paper",
    border: "4px solid #377562",
    borderRadius: '8px',
    boxShadow: 24,
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#3C7F6B',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },

  };

  const formatDate = (dateString) => {
    return Moment(dateString).format('YYYY-MM-DD');
  };
  const [ReplyData, setReplyData] = useState(null);
  const [PatientDataName, setPatientDataName] = useState('');
  const [PatientDataId, setPatientdataId] = useState('');
  const [responseIds, setResponseIds] = useState([]);
  const fetchPatientData = async (rowid) => {
    try {
      setOpenReply(true);


      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/Notifications/getNotificationsById?id=${rowid}`,
        { headers }
      );
      setReplyData(response);
      const ids = response.map(item => item.id);
    setResponseIds(ids);

      const postResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/Notifications/addMarkNotifications`,
        { id: rowid, redbit: true },
        { headers }
      );
      RefreshApis()
      // setTotalcount(Totalcount - 1);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  const HandleUnRead = async () => {
    try {

      await axios.post(
        `${process.env.REACT_APP_API_URL}/Notifications/addMarkNotifications`,
        { id: PatientDataId, redbit: false },
        { headers }
      );
      // setTotalcount(Totalcount + 1);
      RefreshApis()

      handleCloseReply();
    } catch (error) {
      console.error('There was an error updating the notification status!', error);
    }
  };

  useEffect(() => {
    if (selectedRowData) {
      setsubject(selectedRowData.subject);
      setValue('subject', selectedRowData.subject)

    }
  }, [selectedRowData]);
  useEffect(() => {
    if (selectedRowData) {
      setPatientID(selectedRowData.patientId);
      setPatientNamee(selectedRowData.patientName)
      setValue('patientName', selectedRowData.patientName || '');
    setValue('patientId', selectedRowData.patientId || null);
    }
  }, [selectedRowData]);
  const [URL, setURL] = useState('')
  const [ImageData, setImageData] = useState('')
  const [DocumentName, setdocumentName] = useState('')

  useEffect(() => {
    if (OpenReply && responseIds?.length > 0) {
      const fetchDocumentData = async () => {
        try {
          const allDocuments = await Promise.all(
            responseIds.map(notificationId =>
              axios.get(`${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocumentList?notificationId=${notificationId}`, { headers })
            )
          );
  
          const documentsData = allDocuments
            .filter(response => response && Array.isArray(response))
            .map(response => response)
            .flat();
  
          setImageData(documentsData);
  
          if (documentsData?.length > 0) {
            setURL(documentsData[0]?.fileUrl || '');  
            setdocumentName(documentsData[0]?.documentName || '');  
          } else {
            setURL(null);
            setdocumentName(null);
          }
        } catch (error) {
          console.error('There was an error fetching document data!', error);
        }
      };
  
      fetchDocumentData();
    }
  }, [OpenReply, responseIds]);
  
  
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseImage = () => {
    setModalOpen(false);
  };

  const isImage = (url) => /\.(jpg|jpeg|png|gif)$/i.test(url);
  const isPDF = (url) => /\.pdf$/i.test(url);
  const isRTF = (url) => /\.rtf$/i.test(url);
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [DocNameee, setDocNameee] = useState('')

  const handleViewClick = (fileUrl, documentName) => {
    var arr = documentName?.split('.');
    setDocNameee(arr?.length > 1 ? arr[1].toLowerCase() : '');

    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientDocument/ViewPatientDocument?path=${fileUrl}`, { headers })
      .then((response) => {
        setFileUrl(response);
        setModalOpen(true);
        var arr = documentName?.split('.');
        setDocNameee(arr?.length > 1 ? arr[1].toLowerCase() : '');
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };


  return (
    <>

      <Modal
        open={modalOpen}
        onClose={handleCloseImage}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '52%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 912,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            borderColor: '#326C1D',
            border: 5,
            padding: '5px',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* <div>

            <iframe
              // height='500'
              // width='530px'
              border='4px solid #000'
              height='500px'
              width='875px'
              src={`${imageee}`}
            ></iframe>

          </div> */}
          <div>
            {(DocNameee === 'jpg' || DocNameee === 'jpeg' || DocNameee === 'png') ? (
              <>

                <Grid item xs={8}>
                  <img
                    height='400px'
                    width='850px'
                    style={{ border: '4px solid #000' }}
                    src={`${fileUrl}`}
                    alt='Document'
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <iframe
                    height='400px'
                    width='850px'
                    style={{ border: '4px solid #000' }}
                    src={`${fileUrl}`}
                  ></iframe>
                </Grid>
              </>
            )}
          </div>
          <Grid item xs={2} style={{ fontWeight: 500, marginLeft: '30px', marginBottom: '25px', marginTop: '10px' }}>

            <Button
              className='Button'
              variant='gradient'
              onClick={handleCloseImage}
              style={{
                color: 'white',
                fontSize: '13px',
                height: '27px',
                marginBottom: '3px',
                float: 'right'
              }}
            >

              Close
            </Button>
          </Grid>

        </Box>
      </Modal>
      <React.Fragment>
        <Badge
          overlap='circular'
          sx={{ mt: 2, cursor: 'pointer' }}
          // badgeContent={<BadgeContentSpan />}
          badgeContent={CountMessage === 'New' ? Totalcount : Totalcount}
          color={Totalcount > 0 ? 'error' : 'success'}
          backgroundColor='success'
          max={999}
          showZero={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <CircleNotificationsIcon
            style={{ color: '#377562' }}
            alt='John Doe'
            onClick={handleDropdownOpenNotification}

            sx={{ width: 40, height: 40 }}
          />
        </Badge>
        <Menu
          anchorEll={Notification}
          open={Boolean(Notification)}
          onClose={() => handleDropdownCloseNotification()}
          sx={{ '& .MuiMenu-paper': { width: 430, mt: 7, height: 400, marginLeft: -15 } }}
          anchorOrigin={{
            vertical: 'top',
            // horizontal: direction === 'ltr' ? 'right' : 'left'
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            // horizontal: direction === 'ltr' ? 'right' : 'left'
            horizontal: 'right'

          }}
        >
          <>
          {/* <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'red',marginLeft:'20px' }}>{formatTime(timeLeft)}</Typography>  */}

            <Box sx={{ pt: 1, pb: 3, }} style={{ backgroundColor: '#377562', height: '49px' }}>
              <Box sx={{ display: 'flex', }}>
                <Grid container>
                     
               
                  <Grid item xs={1} style={{ marginTop: '3px' }}>
                    <Badge
                      overlap='circular'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                    >
                      <CircleNotificationsIcon style={{ color: 'white' }} sx={{ width: '2.5rem', height: '1.5rem' }} />
                    </Badge>

                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', color: 'white' }}>
                      Messages
                 
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      onClick={handleDropdownOpenNotification}
                    >
                      <CachedOutlinedIcon

                        fontSize='medium'
                        style={{
                          color: 'white',
                          marginLeft: '3rem',


                        }}
                      ></CachedOutlinedIcon>
                    </Button>


                  </Grid>
                  <Grid item xs={4}>
                    <Button className='Button' style={{ color: 'white', marginLeft: '3rem' }} onClick={handleOpen}>
                      Add New
                    </Button>
                  </Grid>
                </Grid>

                {/* <Box
                sx={{
                  ml: 3,
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',

                }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '20px', color: 'white' }}>
                  Notifications
                </Typography>
              </Box>
              <Button  style={{ color: 'white', marginLeft: '5rem' }} onClick={handleOpen}>
                Add New
              </Button> */}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={Modelstyle}>
                    <Grid container columnSpacing={1}>
                      <Grid container xs={12} style={{ fontWeight: 500, backgroundColor: '#377562', marginTop: '-1rem' }}>
                        <Grid item xs={12} style={{ fontWeight: 500, backgroundColor: '#377562', padding: '5px', display: "flex" }}>
                          <Modal
                            id="hello-world"
                            open={openDocument}
                            onClose={handleCloseDocument}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            visible={true} title={'Title'} footer={'Footer'}
                          >
                            <Box sx={checkstyle}>

                              <Grid container spacing={1} style={{
                                background: '#377562',
                                position: 'sticky',
                                marginBottom: '14px',
                                padding: '8px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(54, 118, 196, 0.5)',
                                display: 'flex'
                              }} >
                                <Grid item xs={11} >
                                  <Typography style={{
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    marginLeft: '5px',
                                    color: '#fff'
                                  }}>Add Patient Document </Typography>
                                </Grid>
                                <Grid item xs={1} style={{ fontWeight: 500, }}>
                                  {/* <Button
                                    onClick={handleCloseDocument}
                                    variant="gradient"
                                    // disabled={submitting}
                                    style={{
                                      marginTop: "10px",
                                      float: "right",
                                      marginBottom: "5px",
                                      // width: "80px",
                                      height: "35px",
                                      backgroundColor: "#fff",
                                      color: "black",
                                      fontSize: "14px",
                                      cursor: 'pointer'
                                    }}

                                  
                                  > */}
                                  <CancelOutlinedIcon
                                    onClick={handleCloseDocument}
                                    fontSize="medium"
                                    style={{
                                      color: "red",
                                      cursor: 'pointer',
                                      float: 'right',
                                    }}
                                  ></CancelOutlinedIcon>

                                  {/* </Button> */}
                                </Grid>

                              </Grid>
                              <Grid item xs={12}>
                                <Documents callingFrom={"notifcation"}
                                  updateNotificaton={(id, _notifcationId) => {
                                    setcolumnID(_notifcationId)
                                    setValue('id', _notifcationId)

                                  }}
                                  patientID={patientID}
                                  AuthorizationFormByCPTid={TmdId} handleCloseNotification={handleCloseDocument}
                                ></Documents>

                              </Grid>


                            </Box>
                          </Modal>
                          <Grid item xs={6}>
                            <Typography style={{ fontWeight: 'bold', fontSize: '18px', color: 'white' }}>
                              Add Notification
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                              onClick={() => setOpenDocument(true)}
                              className="Button"
                              variant='gradient'
                              color='info'
                              size='small'
                              style={{
                                width: '200px',
                                height: '33px',
                                color: 'white',
                                fontSize: '14px',
                              }}
                            >
                              <AddOutlinedIcon
                                fontSize='medium'
                                style={{
                                  color: 'white',
                                  paddingRight: '4px',
                                  paddingBottom: '2px'
                                }}
                              />
                              Add Document
                            </Button>
                          </Grid>
                          </Grid>

                        </Grid>


                        <Grid item xs={6} style={{ fontWeight: 500, marginTop: '20px' }}>
                          <Typography color='black' fontSize='15px'>
                            Patient Name
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500, marginTop: '20px' }}>
                          <Typography color='black' fontSize='15px'>
                            Subject
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>

                          <ClickAwayListener onClickAway={() => setPatient(false)}>
                            <TextField
                              autoComplete='off'
                              // isClearable
                              {...register('patientName')}
                              size='small'
                              fullWidth
                              placeholder='Patient'
                              {...(selectedRowData.patientName ? { value: selectedRowData.patientName } : {})}
                              //  value={selectedRowData.patientName||''}
                              error={!!errors.patientName}
                              helperText={errors.patientName ? errors.patientName.message : ''}
                              onKeyUp={ev => {
                                console.log('ee:', ev.target.value)
                                // dont fire API if the user delete or not entered anything
                                if (ev.target.value !== null && ev.target.value !== '' && ev.target.value?.length > 0) {
                                  onChangeHandle(ev.target.value)
                                  setSearchText(ev.target.value)



                                } else {
                                  setPatientData([])
                                  setPatientSelected(false)
                                  setPatient(false)
                                  setSearchText('')




                                }
                              }}

                            // error={errors.patientName}
                            />

                          </ClickAwayListener>
                          {isLoading1 && (
                            <CircularProgress size={20} style={{ marginLeft: '-40px', marginTop: '10px' }} />
                          )}

                          {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.patientName?.message}</p> */}

                          {patient ? (
                            <div
                              className='col-xs-6'
                              style={{
                                padding: '5px',
                                fontSize: '0.875rem',
                                color: '#67748e',
                                borderRadius: '5px',
                                background: 'white',
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                position: 'absolute',
                                zIndex: '99',
                                width: '29%',
                                // height:'29%',
                                border: '1px solid #6cb5f9',
                                marginTop: "-10px"
                              }}
                            >
                              {patient ?
                                patientData?.length > 0 ? (
                                  patientData.map((s, index) => {
                                    var pat = `${patientData[index].lastName}, ${patientData[index].firstName}`

                                    const searchTextLower = searchText.toLowerCase();
                                    const fullNameLower = pat.toLowerCase();
                                    const i = fullNameLower.indexOf(searchTextLower);

                                    if (i === -1) {
                                      return null;
                                    }

                                    const p1 = pat.substring(0, i);
                                    const p = pat.substring(i, i + searchText?.length);
                                    const p2 = pat.substring(i + searchText?.length);
                                    return (
                                      <p
                                        // attribZip={s.zipCode}
                                        // attribState={s.state}
                                        // attribCity={s.city}
                                        // attribIndex={index}
                                        style={PatientStyle}
                                        onClick={ev => {
                                          // var sel=ev.target.attributes['attribIndex']
                                          // console.log('Selected: ', patientData[index])
                                          setValue('patientName', `${patientData[index].lastName}, ${patientData[index].firstName}`)
                                          setValue('patientId', patientData[index].id)
                                          setPatientID(patientData[index].id)

                                          setPatientData([])
                                          setPatientSelected(true)
                                          setPatient(false)
                                          setPatientNamee(`${patientData[index].lastName}, ${patientData[index].firstName}`)
                                        }}
                                        onBlur={ev => {
                                          setPatientData([])
                                          setPatient(false)

                                        }}
                                        onChange={ev => {
                                          setPatientData([])
                                          setPatientSelected(false)
                                          setPatient(false)
                                          setPatientNamee('')

                                        }}
                                      >
                                        <span>{p1}</span>
                                        <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                                        <span>{p2}</span>
                                      </p>
                                    )
                                  })

                                ) : (
                                  <Typography>No Record found</Typography>
                                )
                                : null}
                            </div>
                          ) : (
                            ''
                          )}

                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name='subject'
                            {...register('subject')}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                placeholder='Please enter Subject'
                                {...field}
                                fullWidth
                                {...(selectedRowData.subject  ?{ value: selectedRowData.subject } :{})}
                                value={subject}
                                onChange={e => {
                                  setsubject(e.target.value)
                                  setValue('subject', e.target.value)
                                  // setapptypeError(false)  
                                }}
                                error={!!errors.subject}
                                helperText={errors.subject ? errors.subject.message : ''}

                              />
                            )}
                          />

                        </Grid>

                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          <Typography color='black' variant='caption' fontSize='15px'>
                            Created Date
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          <Typography color='black' variant='caption' fontSize='15px'>
                            AssignedTo
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          <Controller
                            name='createdDate'
                            {...register('createdDate')}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                type='date'
                                size='small'
                                fullWidth
                                {...field}
                                placeholder='Enter Due date'
                                // {...(selectedRowData.createdDate ? { value: formatDate(selectedRowData.createdDate) } : {})}

                                onChange={e => {
                                  setValue('createdDate', e.target.value)
                                  setdueDate(e.target.value)
                                }}
                              // error={errors.createdDate}

                              />
                            )}
                          />
                          {/* <p style={{ color: "red", fontSize: "14px" }}>
                {errors.createdDate?.message}
              </p> */}
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {/* <Controller
                      name='assignedTo'
                      {...register('assignedTo')}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type='test'
                          size='small'
                          fullWidth
                          {...field}

                        />
                      )}
                    /> */}
                          <ReactSelect
                            fullWidth
                            value={practicesList.find(option => option.value === assignedTo) || null}
                            onChange={(e) => {
                              setAssignedTo(e.value);
                              setPracticeID(e.value);
                            }}
                            options={practicesList}
                          />

                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: 500, marginTop: '20px' }}>
                          <Typography color='black' variant='caption' fontSize='15px'>
                            Comments
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: 500, }}>
                          <Controller
                            name='comments'
                            {...register('comments')}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size='small'
                                multiline
                                rows={2}
                                value={selectedRowData.comments}
                                placeholder='Please enter Comments'
                                {...field}
                                fullWidth
                                onChange={e => {
                                  setValue('comments', e.target.value)
                                }}

                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} lg={12} sm={12}>
                          <Button
                            className='Button'
                            onClick={handleClose}
                            variant='gradient'
                            style={{
                              marginTop: '30px',
                              marginBottom: '10px',
                              float: 'right',
                              marginLeft: '20px',
                              width: '90px',
                              height: '35px',
                              // backgroundColor: '#63CE13',
                              color: 'white',
                              fontSize: '14px'
                            }}
                          >
                            <CancelOutlinedIcon
                              fontSize='medium'
                              style={{ color: 'white', paddingRight: '5px' }}
                            ></CancelOutlinedIcon>
                            Cancel
                          </Button>
                          <Button
                            className='Button'
                            onClick={handleSubmit(onSubmit)}
                             disabled={isSubmitting == true}
                            variant='gradient'
                            style={{
                              marginTop: '30px',
                              marginBottom: '10px',
                              float: 'right',
                              marginLeft: 'auto',
                              width: '90px',
                              height: '35px',
                              color: 'white',
                              fontSize: '14px'
                            }}
                          >
                            <SaveOutlinedIcon
                              fontSize='medium'
                              style={{
                                color: 'white',
                                paddingRight: '5px',
                                paddingBottom: '2px'
                              }}
                            ></SaveOutlinedIcon>
                             Send
                          </Button>
                        </Grid>
                      </Grid>
                  </Box>
                </Modal>
                <Modal
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  onClose={handleClose}
                  open={opendocs}
                >
                  <Box sx={styleofmodal}>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography id='modal-modal-title' variant='h6' component='h2' style={{ fontWeight: 500, backgroundColor: '#377562', marginBottom: 5, color: 'white' }}>
                          {FullDocName[0]}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {submitting ? (
                          <LoadingButton
                            loading
                            loadingIndicator='Downloading'
                            // style={{ position: 'absolute', right: '28px', bottom: '11px' }}
                            color='primary'
                            variant='contained'
                          >
                            Fetch data
                          </LoadingButton>
                        ) : (
                          <Button
                            className="Button"
                            size='small'
                            startIcon={<Download />}
                            onClick={() => {
                              download(`${urldownload}`, FullDocName)
                            }}
                            variant='contained'
                            disabled={submitting}
                            style={{
                              // backgroundColor: 'primary',
                              color: 'white',
                              float: 'right'
                              // position: 'absolute',
                              // marginRight: '28px',
                              // bottom: '11px'
                            }}
                          >
                            Download
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <CancelOutlinedIcon
                          onClick={() => setopendocs(false)}
                          fontSize='large'
                          color='red'
                          cursor='pointer'
                          style={{ color: 'red', marginBottom: 5, float: 'right', }}
                        ></CancelOutlinedIcon>

                      </Grid>
                      {/* <Grid item xs={2}></Grid> */}

                      {(DocName && DocName == 'jpg') || DocName == 'png' || DocName == 'jpeg' ? (
                        <>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={8}>
                            <img
                              height='500px'
                              width='500px'
                              border='4px solid #000'
                              // frameborder='0'
                              // overflow='hidden'
                              src={`${urlFile}`}
                            // src={`https://images.unsplash.com/photo-1672394423014-e0354d75b123?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60`}
                            ></img>
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <iframe
                              height='500px'
                              width='875px'
                              // frameborder='0'
                              // overflow='hidden'
                              src={`${urlFile}`}
                            // src={`https://images.unsplash.com/photo-1672394423014-e0354d75b123?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60`}
                            ></iframe>
                          </Grid>
                        </>
                      )}
                      {/* <Grid item xs={2}></Grid> */}
                      <Grid item xs={12}></Grid>
                    </Grid>
                  </Box>
                </Modal>
              </Box>
            </Box>
            <Divider sx={{ mt: 0, mb: 1 }} />
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="lab API tabs example"
                >
                  <Tab onClick={() => { setMessage('New') }} value={0} label="Inbox" />
                  <Tab onClick={() => { setMessage('Resolved') }} value={1} label="Sent" />

                </TabList>
              </Box>
              <TabPanel value={0}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <MenuItem sx={{ p: 0, marginRight: '10px', marginTop: '-15px' }}>
                    <Box sx={style}>
                      {Data != null && Data?.length > 0
                        ? Data.map((row, index) => {
                          const documentParts = row.documents?.split('@@');

                          return (
                            <Paper
                              variant='outlined'
                              square
                              style={{
                                // backgroundColor: '#F0F6F8',
                                backgroundColor: row.redbit ? '#F0F6F8' : '#D4E7C5',
                                borderRadius: '15px',
                                marginTop: '5px',
                                borderColor: '#377562',
                                maxWidth: '100%',
                                padding: '.9rem',
                                marginBottom: '1rem',
                                marginLeft: '0 .2rem'
                              }}
                            >

                              <Grid container spacing={2} item xs={12} sm={12} xl={12}>
                                <Grid container>

                                  {/* <Grid item xs={12}>
                                <Typography
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                    marginTop: '10px',
                                    marginLeft: '20px'
                                  }}
                                >
                                  {row.comments}
                                </Typography>
                              </Grid> */}
                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                      Subject:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={9} style={{ fontWeight: 500 }}>
                                    <Typography style={{
                                      fontSize: '14px', maxWidth: '100%',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'pre-wrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block',
                                    }}>{row.subject}</Typography>
                                  </Grid>
                                  <Grid item xs={12} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                                      Patient Name : {row.patientName}
                                    </Typography>
                                  </Grid>
                                  {/* <Grid item xs={2} style={{ fontWeight: 500 }}>
                              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                UserType:
                              </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ fontWeight: 500 }}>
                              <Typography style={{ fontSize: '14px', marginLeft: '30px' }}>{row.userType}</Typography>
                            </Grid> */}


                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                      AssignedTo:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', marginLeft: '30px' }}>
                                      {row.assignedTo}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                      Date:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', marginLeft: '20px', }}>{row.createdDate}</Typography>
                                  </Grid>
                                  {/* <Grid item xs={3} style={{ fontWeight: 500 }}></Grid> */}
                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', }}>
                                      Comments:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8} style={{ fontWeight: 450 }}>
                                    <Typography style={{
                                      fontSize: '14px',
                                      maxWidth: '100%',
                                      marginLeft: '20px',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'pre-wrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block',
                                      textAlign: 'justify'
                                    }}>
                                      {row.comments}</Typography>
                                  </Grid>
                                  <Grid item xs={5} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                      Last Message
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} style={{ fontWeight: 500 }}>
                                    <Typography style={{
                                      fontSize: '14px', maxWidth: '100%',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'pre-wrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block',
                                    }}>{row.lastMessage}</Typography>
                                  </Grid>
                                  <Grid item xs={5} style={{ fontWeight: 500 }}>
                                    {row.documents?.length > 0 && (
                                      <a
                                        href="#"
                                        //  className='Button'
                                        style={{
                                          // backgroundColor: '#70D226',
                                          // backgroundColor: row.id === id ? 'grey' : '#56CA00',
                                          fontSize: '13px',
                                          // height: '27px',
                                          marginBottom: '3px',
                                          marginLeft: '25px',
                                          borderBottom: '1px solid black',
                                          maxWidth: '100%',
                                          wordWrap: 'break-word',
                                          whiteSpace: 'pre-wrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          display: 'block',
                                          // textDecoration: 'underline',// This will remove the default underline of the anchor tag
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault(); // Prevent the default behavior of anchor tag (i.e., navigating to another page)
                                          setopendocs(true);
                                          var url = row.documents.slice('@@');
                                          const splitDocuments = row.documents?.split('@@');
                                          const desiredText = splitDocuments[1];
                                          const pathurl = url[1];
                                          seturldownload(desiredText);
                                          axios
                                            .get(`${process.env.REACT_APP_API_URL}/PatientDocument/ViewPatientDocument?path=${desiredText}`, {
                                              headers
                                            })
                                            .then(response => {
                                              seturlFile(response);
                                            })
                                            .catch(error => {
                                              console.error('There was an error!', error);
                                            });
                                          console.log('CheckAAAAA', row.fileUrl);
                                          setFullDocName(documentParts);
                                        }}
                                      >
                                        {/* {completed ? 'Resolve' : 'Resolved'} */}
                                        {documentParts?.length > 0 ? documentParts[0] : ''}
                                      </a>

                                    )}
                                    {/* <Typography style={{ fontSize: '14px' }}>{documentParts?.length>0?documentParts[0]:''}</Typography> */}
                                  </Grid>
                                  {/* <Grid item xs={6}></Grid> */}
                                  <Grid item xs={2} >{''}</Grid>
                                  <Grid item xs={0.3} style={{ fontWeight: 500 }}>
                                    <Tooltip title="Open Reply">
                                      <RemoveRedEyeIcon style={{ cursor: 'pointer', marginLeft: '-18px', color: 'green' }}
                                        onClick={() => {
                                          setPatientDataName(row.patientName);
                                          setPatientdataId(row.id);
                                          // setPatientdataIds(row.parentId);
                                          fetchPatientData(row.id);
                                          // setIsFirstCall(true)
                                        }} />
                                    </Tooltip>
                                  </Grid>
                                  {/* <Grid item xs={2} style={{ fontWeight: 500, marginRight: '30px' }}>
                                    <Button
                                      className='Button'
                                      variant='gradient'
                                      onClick={() => {
                                        setselectedRow(row.id)
                                        setselectedindex(index)
                                        console.log('Selected Row id is :', row.id)
                                        console.log('Selected Row index is :', index)
                                        setid(row.id)
                                      }}
                                      style={{
                                        // backgroundColor: '#70D226',
                                        // backgroundColor: row.id === id ? 'grey' : '#56CA00',
                                        color: 'white',
                                        fontSize: '13px',
                                        height: '27px',
                                        marginBottom: '3px',
                                        // float:'right'
                                      }}
                                    // disabled={submitting}
                                    >
                                      Resolve
                                    </Button>
                                  </Grid> */}

                                  <Grid item xs={2} style={{ fontWeight: 500, marginLeft: '30px' }}>
                                    <Button
                                      className='Button'
                                      variant='gradient'
                                      onClick={() => {
                                        handleOpen(row);
                                        setBool(true);
                                      }}
                                      style={{
                                        color: 'white',
                                        fontSize: '13px',
                                        height: '27px',
                                        marginBottom: '3px',
                                        float: 'right'
                                      }}
                                    >

                                      Reply
                                    </Button>
                                  </Grid>


                                  {/* <Button
         className='Button'
              onClick={()=>  {
                handleOpen()
                reset()
                }
              }
              variant='gradient'
              style={{
                // backgroundColor: '#70D226',
                // backgroundColor: row.id === id ? 'grey' : '#56CA00',
                color: 'white',
                fontSize: '13px',
                height: '27px',
                marginBottom: '3px',
                marginLeft: '5px'
              }}
              
            >
              REOPEN
            </Button> */}






                                  <Grid item xs={12} style={{ fontWeight: 500 }}>
                                    {row.id === id ? (
                                      <Box
                                        mb={2}
                                        sx={{
                                          marginBottom: '10px',

                                          marginTop: '15px',
                                          marginLeft: '10px'
                                        }}
                                      >
                                        <Grid container columnSpacing={1}>
                                          <Grid item xs={12} style={{ fontWeight: 500 }}>
                                            <Typography component='label' color='black' fontSize='15px'>
                                              Resolve Comments:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Controller
                                              name='resolvedComments'
                                              {...register('resolvedComments')}
                                              control={control}
                                              render={({ field }) => (
                                                <TextareaAutosize
                                                  reset
                                                  aria-label='minimum height'
                                                  minRows={3}
                                                  placeholder='Enter comments'
                                                  style={{
                                                    width: '100%',
                                                    height: '60px',
                                                    borderRadius: '8px',
                                                    border: '2px solid #black',
                                                    overflow: 'scroll'
                                                  }}
                                                  value={resolvedComments}
                                                  onChange={e => {
                                                    setValue('resolvedComments', e.target.value)
                                                    setResolvedComments(e.target.value);
                                                    // setcompletedDate(e.target.value)
                                                    // setcompeleterror(false)
                                                  }}
                                                  // {...field}
                                                  fullWidth
                                                  error={errors.resolvedComments}
                                                />
                                              )}
                                            />
                                            <p style={{ color: 'red', fontSize: '14px' }}>
                                              {errors.resolvedComments?.message}
                                            </p>
                                          </Grid>
                                          <Grid item xs={4} style={{ fontWeight: 500, marginTop: '10px' }}>
                                            <Typography color='black' variant='caption' fontSize='15px'>
                                              Resolve Date:
                                            </Typography>
                                          </Grid>

                                          <Grid item xs={8}>
                                            <Controller
                                              name='resolvedDate'
                                              {...register('date')}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  type='date'
                                                  size='small'
                                                  fullWidth
                                                  {...field}
                                                  value={resolvedDate}
                                                  placeholder='Enter resolve date'
                                                  // value={completedDate}
                                                  onChange={e => {
                                                    setValue('resolvedDate', e.target.value)
                                                    setResolvedDate(e.target.value);
                                                    // setcompletedDate(e.target.value)
                                                    // setcompeleterror(false)
                                                  }}
                                                />
                                              )}
                                            />
                                            {errorMessage && (
                                              <p style={{ color: 'red' }}>Please Select Date</p>
                                            )}
                                          </Grid>

                                          <Grid item xs={8} lg={6} sm={6}>
                                            <Button
                                              className='Button'
                                              onClick={() => saveData(row)}
                                              variant='gradient'

                                              style={{
                                                marginTop: '15px',
                                                marginBottom: '10px',
                                                float: 'right',
                                                width: '87px',
                                                height: '28px',
                                                // backgroundColor: '#56CA00',
                                                color: 'white',
                                                fontSize: '12px',
                                                marginRight: '-50px'
                                              }}
                                            // disabled={submitting}
                                            >
                                              <SaveOutlinedIcon
                                                fontSize='medium'
                                                style={{
                                                  color: 'white',
                                                  paddingRight: '5px',
                                                  paddingBottom: '2px'
                                                }}
                                              ></SaveOutlinedIcon>
                                              Save
                                            </Button>
                                          </Grid>

                                          <Grid item xs={4} lg={6} sm={6}>
                                            <Button
                                              className='Button'
                                              onClick={() => {
                                                setid(null)
                                              }}
                                              variant='gradient'
                                              style={{
                                                marginTop: '15px',
                                                marginBottom: '10px',
                                                float: 'right',
                                                width: '87px',
                                                height: '28px',
                                                backgroundColor: '#56CA00',
                                                color: 'white',
                                                fontSize: '12px',
                                                marginRight: '18px'
                                              }}
                                            >
                                              <CancelOutlinedIcon
                                                fontSize='medium'
                                                style={{
                                                  color: 'white',
                                                  paddingRight: '5px',
                                                  paddingBottom: '2px'
                                                }}
                                              ></CancelOutlinedIcon>
                                              Cancel
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>


                                </Grid>

                                <Divider />
                              </Grid>
                            </Paper>
                          )
                        })
                        : 'No Record Found'}
                      <Divider />
                    </Box>

                  </MenuItem>
                )}
              </TabPanel>
              <TabPanel value={1}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <MenuItem sx={{ p: 0, marginRight: '10px', marginTop: '-15px' }}>
                    <Box sx={style}>
                      {Notification1 != null && Notification1?.length > 0
                        ? Notification1.map((row, index) => {
                          return (
                            <Paper
                              variant='outlined'
                              square
                              style={{
                                backgroundColor: '#F0F6F8',
                                borderRadius: '15px',
                                marginTop: '5px',
                                borderColor: '#377562',
                                maxWidth: '100%',
                                padding: '.9rem',
                                marginBottom: '1rem',
                                marginLeft: '0 .2rem'
                              }}
                            >
                              <Grid container spacing={2} item xs={12} sm={12} xl={12}>
                                <Grid container>
                                  {/* <Grid item xs={12}>
                                    <Typography
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        marginLeft: '10px',
                                        marginTop: '10px',
                                        marginLeft: '20px'
                                      }}
                                    >
                                      {row.comments}
                                    </Typography>
                                  </Grid> */}
                                  <Grid item xs={12} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                                      Patient Name : {row.patientName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                      Subject:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={9} style={{ fontWeight: 500 }}>
                                    <Typography style={{
                                      fontSize: '14px', maxWidth: '100%',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'pre-wrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block',
                                    }}>{row.subject}</Typography>
                                  </Grid>
                                  {/* <Grid item xs={2} style={{ fontWeight: 500 }}>
                              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                UserType:
                              </Typography>
                            </Grid>
                            <Grid item xs={10} style={{ fontWeight: 500 }}>
                              <Typography style={{ fontSize: '14px', marginLeft: '30px' }}>{row.userType}</Typography>
                            </Grid> */}


                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                                      Assigned To:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={9} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', marginLeft: '28px', marginBottom: '5px' }}>
                                      {row.assignedTo}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '25px' }}>
                                      Date:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={9} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px' }}>{row.createdDate}</Typography>
                                  </Grid>
                                  <Grid item xs={3} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '25px' }}>
                                      Comments:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={9} style={{ fontWeight: 500 }}>
                                    <Typography style={{
                                      fontSize: '14px', maxWidth: '100%',
                                      marginLeft: '18px',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'pre-wrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block',
                                    }}>{row.comments}</Typography>
                                  </Grid>
                                  <Grid item xs={6} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '25px' }}>
                                      Resolved Comments:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} style={{ fontWeight: 500 }}>
                                    <Typography style={{
                                      fontSize: '14px', maxWidth: '100%',
                                      marginLeft: '18px',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'pre-wrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block',
                                    }}>{row.resolvedComments}</Typography>
                                  </Grid>
                                  <Grid item xs={4.5} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '25px' }}>
                                      Resolved Date:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7.5} style={{ fontWeight: 500 }}>
                                    <Typography style={{ fontSize: '14px' }}>{row.resolvedDate}</Typography>
                                  </Grid>
                                  <Grid item xs={10}></Grid>
                                  <Grid item xs={2}>
                                    <>
                                      {/* <Button
                                        className='Button'
                                        onClick={() => {
                                          setDialogOpen(true)
                                          setDelbyid(row.id)
                                          setPatientiidresolve(row.patientId)
                                          setsubject1(row.subject)
                                          setName1(row.patientName)
                                          setassginedto(row.assignedTo)
                                          setdate(row.createdDate)
                                          setcommentsnotifcation(row.comments)
                                        }}
                                        variant='gradient'
                                        style={{
                                          marginTop: '30px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          marginLeft: 'auto',
                                          width: '90px',
                                          height: '35px',
                                          color: 'white',
                                          fontSize: '14px'
                                        }}
                                      >
                                        <RestartAltIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></RestartAltIcon>
                                        ReOpen
                                      </Button> */}
                                      {/* <RestartAltIcon
          style={{
            marginRight: '5px',
            float: 'right',
            // marginTop: '5px',
            color: 'red',
            cursor: 'pointer',
          }}
          color="black"
          onClick={() => {setDialogOpen(true)
            setDelbyid(row.id)
            setPatientiidresolve(row.patientId)
            setsubject1(row.subject)
            setassginedto(row.assignedTo)
            setdate(row.createdDate)

          }}
        > </RestartAltIcon> */}

                                      {/* <Dialog
                                        open={isDialogOpen}
                                        onClose={() => setDialogOpen(false)}
                                        aria-labelledby="delete-dialog-title"
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                          invisible: true,
                                        }}
                                        PaperProps={{
                                          style: {
                                            marginRight: '5px',
                                            float: 'right',
                                            marginTop: '5px',
                                            color: 'red',
                                            cursor: 'pointer',
                                          },
                                        }}
                                      >
                                        <div style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center', // Center horizontally
                                          justifyContent: 'center', // Center vertically
                                          borderRadius: '8px',
                                          width: '500px'
                                        }}>

                                          <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
                                          <DialogTitle style={{ fontWeight: 'bold', color: 'black', fontSize: ' 1.25rem' }}>Are you sure?</DialogTitle>
                                          <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal', color: '#444444' }}>
                                            Reopen this Patient!
                                          </h5>
                                          <DialogContent>
                                            <DialogContentText>

                                              <Grid style={{ marginTop: '1rem' }}>
                                                <Button onClick={() => setDialogOpen(false)} style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}>No,cancel!</Button>
                                                <Button onClick={() => handleDelete(row)} style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem', border: '1px solid #b4acec' }}>Yes,Reopen it!</Button>
                                              </Grid>
                                            </DialogContentText>
                                          </DialogContent>
                                        </div>

                                      </Dialog> */}
                                    </>


                                  </Grid>
                                </Grid>

                                <Divider />
                              </Grid>
                            </Paper>
                          )
                        })
                        : 'No Record Found'}
                      <Divider />
                    </Box>

                  </MenuItem>
                )}
                <Dialog
                  open={isDialogOpen}
                  onClose={() => setDialogOpen(false)}
                  aria-labelledby="delete-dialog-title"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {
                      marginRight: '5px',
                      float: 'right',
                      marginTop: '5px',
                      color: 'red',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Center horizontally
                    justifyContent: 'center', // Center vertically
                    borderRadius: '8px',
                    width: '500px'
                  }}>
                    <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
                    <DialogTitle style={{ fontWeight: 'bold', color: 'black', fontSize: '1.25rem' }}>
                      Are you sure?
                    </DialogTitle>
                    <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal', color: '#444444' }}>
                      Reopen this Patient!
                    </h5>
                    <DialogContent>
                      <DialogContentText>
                        <Grid style={{ marginTop: '1rem' }}>
                          <Button
                            onClick={() => setDialogOpen(false)}
                            style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}
                          >
                            No, cancel!
                          </Button>
                          <Button
                            onClick={handleDelete}
                            style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem', border: '1px solid #b4acec' }}
                          >
                            Yes, Reopen it!
                          </Button>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                  </div>
                </Dialog>
              </TabPanel>
            </TabContext>
          </>

        </Menu>
        {/* newAdd */}
        <Badge
          // overlap='circular'
          onClick={handleDropdownOpen}
          sx={{ ml: 2, cursor: 'pointer' }}
          //  badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
        </Badge>
        {/* <Typography sx={{ fontWeight: 600, marginLeft: 5 }}>{admin}</Typography> */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'

          }}
        >
          <>
            <Box sx={{ pt: 2, pb: 3, px: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Badge
                  overlap='circular'
                  badgeContent={<BadgeContentSpan />}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                >
                  {/* <Avatar alt='John Doe' src='/images/avatars/1.png' sx={{ width: '2.5rem', height: '2.5rem' }} /> */}
                </Badge>
                <Box
                  sx={{
                    ml: 3,
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                  }}
                >

                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 0, mb: 1 }} />
          </>
        </Menu>
        <>
          <Modal
            open={OpenReply}
            onClose={handleCloseReply}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={ReplyStyle}>


              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky', padding: '2px' }}
                >
                  <Grid item xs={3}>
                    <Typography style={{ color: 'white', marginTop: '8px', fontWeight: 'bold' }}>Patient Name:</Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                    <Typography style={{ color: 'white', marginTop: '8px', }}>{PatientDataName}</Typography>


                  </Grid>

                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={1}>
                    <Typography style={{ color: 'white', marginTop: '8px', fontWeight: 'bold' }}>ID:</Typography>
                  </Grid>
                  <Grid item xs={1.8}>
                    <Typography style={{ color: 'white', marginTop: '8px', }}>{PatientDataId}</Typography>

                  </Grid>
                  <Grid item xs={0.7}>

                  </Grid>
                  <Grid item xs={0.5} >
                    <CancelOutlinedIcon
                      onClick={handleCloseReply}
                      fontSize="large"
                      color="red"
                      style={{
                        float: 'right',
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </Grid>

                </Grid>
              </Headroom>

              {ReplyData != null && ReplyData?.length > 0
                ? ReplyData.map((row, index) => {
                  const formattedDate = Moment(row.createdDate).format('MM-DD-YYYY');

                  return (
                    <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '5px',
                        borderColor: '#377562',
                        maxWidth: '98%',
                        padding: '.9rem',
                        marginBottom: '1rem',
                        marginLeft: '0 .2rem'
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} style={{ fontWeight: 500 }}>
                          <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                            <b> Subject:</b>  {row.subject}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: 500 }}>
                          <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                            <b> Comments: </b>{row.comments}
                          </Typography>
                        </Grid>


                        <Grid item xs={12} style={{ fontWeight: 500 }}>
                          <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                            <b> Assigned To :</b> {row.assignedTo}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: 500 }}>
                          <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                            <b> Created By:</b> {row.createdBy}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: 500 }}>
                          <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                            <b>Created Date:</b>  {formattedDate}
                          </Typography>
                        </Grid>
                      </Grid>


                    </Paper>
                  )
                })
                : 'No Record Found'}


              {ImageData != null && ImageData?.length > 0 ? (
                ImageData.map((row, index) => (
                  <Paper
                    variant='outlined'
                    square
                    style={{
                      backgroundColor: '#F0F6F8',
                      borderRadius: '15px',
                      marginTop: '5px',
                      borderColor: '#377562',
                      maxWidth: '98%',
                      padding: '.9rem',
                      marginBottom: '1rem',
                      marginLeft: '0 .2rem'
                    }}
                  >
                    <React.Fragment key={row.id}>
                      <Grid item xs={9} style={{ marginTop: '20px', borderRadius: '8px' }}>
                        <Typography
                          variant='h6'
                          component='h2'
                          style={{ fontWeight: 400, backgroundColor: '#377562', marginBottom: 5, color: '#fff', borderRadius: '8px' }}
                        >
                          {row.documentName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} style={{ fontWeight: 500, marginLeft: '30px', marginBottom: '25px' }}>

                        <Button
                          className='Button'
                          variant='gradient'
                          onClick={() => handleViewClick(row.fileUrl, row.documentName)}
                          style={{
                            color: 'white',
                            fontSize: '13px',
                            height: '27px',
                            marginBottom: '3px',
                            float: 'right'
                          }}
                        >

                          View
                        </Button>
                      </Grid>

                      {/* <Grid item xs={12}>
                      <img
                        height='200px'
                        width='200px'
                        style={{ border: '2px solid #000', borderRadius: '8px' }}
                        src={`${imageee}`}
                        alt={`${row.documentName}`}
                      />
                    </Grid> */}
                      {/* <Grid item xs={12}>
                      <iframe
                        height='200'
                        width='200px'
                        src={`${row.fileUrl}`}
                        title={`${row.documentName}`}
                      ></iframe>
                    </Grid> */}
                    </React.Fragment>
                  </Paper>
                ))
              ) : (
                'No Images Found'
              )}
              <Grid container spacing={2}>
                <Grid item xs={11} ></Grid>

                <Grid item xs={1} >
                  <Tooltip title="Unread">
                    <VisibilityOffIcon
                      onClick={HandleUnRead}
                      style={{ cursor: 'pointer', marginLeft: '-18px', color: 'green' }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={10} ></Grid>

                <Grid item xs={2} style={{ fontWeight: 500, marginBottom: '15px' }}>
                  <Button
                    className='Button'
                    variant='gradient'
                    onClick={handleCloseReply}
                    style={{
                      color: 'white',
                      fontSize: '13px',
                      height: '27px',
                      marginBottom: '3px',
                      float: 'right'
                    }}
                  >

                    Close
                  </Button>
                </Grid >
              </Grid >

            </Box>
          </Modal>
        </>


      </React.Fragment>
    </>
  )

}

export default NotificationDropdown