import { useState, useEffect } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Moment from "moment";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const UserRegistration = props => {
  const fields = [
    "city",
    "contactNo",
    "userName",
    "createdBy",
    "createdDate",
    "dateJoined",
    "defaultFacilityId",
    "defaultPracticeId",
    "dob",
    "email",
    "fullName",
    "userName",
    "gender",
    "id",
    "provider_id",
    "isActive",
    "isStaff",
    "isSuperUser",
    "isVerified",
    "lastLogin",
    "password",
    "profilePhoto",
    "state",
    "street",
    "token",
    "updatedBy",
    "updatedDate",
    "userType",
    "zipCode",
    "provider",
    "address",
    "PracticeId",
    "photoUrl",
    "userslinked",
    "linkedUsersId",
    "resetPassword",
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday"
  ];
  const defValues = {
    city: "",
    userName:"",
    contactNo: "",
    createdBy: "",
    userName:"",
    createdDate: "",
    dateJoined: "",
    defaultFacilityId: "",
    defaultPracticeId: "",
    dob: "",
    userslinked: "",
    email: "",
    fullName: "",
    gender: "",
    id: 0,
    provider_id: "",
    isActive: "",
    isStaff: "",
    isSuperUser: "",
    isVerified: "",
    lastLogin: "",
    password: "",
    profilePhoto: "",
    state: "",
    street: "",
    token: "",
    updatedBy: "",
    updatedDate: "",
    userType: "",
    zipCode: "",
    provider: "",
    address: "",
    PracticeId: "",
    photoUrl: "",
    linkedUsersId: "",
    resetPassword: "",
    saturday: "",
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: ""
  };
  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Id",
      flex: 0.2,
      sortable: true
    },
    {
      field: "userEmail",
      headerClassName: "super-app-theme--header",
      headerName: "Email Address",
      sortable: true,
      flex: 0.5
    },
    {
      field: "practiceName",
      headerClassName: "super-app-theme--header",
      headerName: "Practice Name",
      flex: 0.3,
      sortable: true
    },

    {
      field: "providerName",
      headerClassName: "super-app-theme--header",
      headerName: "Default Provider",
      sortable: true,
      flex: 0.3
    },
    {
      field: "createdDate",
      headerClassName: "super-app-theme--header",
      headerName: "Added Date",
      sortable: true,
      flex: 0.3
    },
    {
      field: 'delete',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      id: cell.row.id,
                      deleted: true,
                    
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/UserPractices/addUserPractices`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
  ];
  const [isLoading, setisLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [userName, setuserName] = useState("");
  console.log('userName',userName)
  const [userType, setuserType] = useState("");
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [errorReceived, seterrorReceived] = useState("");
  const [zipDownloaded, setzipDownloaded] = useState(false);
  const [fetchedData, setfetchedData] = useState(false);
  const [userPractice, setUserPractice] = useState(0);
  const [userFacility, setUserFacility] = useState(0);
  const [rows, setRows] = useState(null);
  const [rows1, setRows1] = useState(null);
  const [providerType, setProviderType] = React.useState("");
  const [userChildList, setUsersChildList] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [selectedUserIds, setselectedUserIds] = React.useState([]);
  const [selectUserError, setselectUserError] = useState(false);
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const _userId = location.state.uid;
  const PracticeId = location.state.PracticeId;

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  // const Emailaddress = localStorage.getItem("Emailaddress")
  const [Emailaddress, setEmailaddress] = useState("");
  const [practicesList, setpracticesList] = useState("");
  console.log("practicesList", practicesList);
  const [practiceslistid, setpracticeslistid] = useState([]);
//   const practiceslistid = [];
  console.log("practiceslistid", practiceslistid);
  const facilitiesList = [];
  const [practiceIdError, setPracticeIDError] = useState(false);
  const [providerIdError, setProviderIdError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [practiceID, setPracticeID] = useState("");
  console.log("aaaa", practiceID);
  const [userProvider, setUserProvider] = useState(0);
  const [providers, setProviders] = useState([]);
  const [defaultPracticeError, setDefaultPracticeError] = useState(false);
  const [defaultPracticeErrorId, setDefaultPracticeErrorId] = useState("");
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    userName: Yup.string().required("User Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(3, "Password must be at least 3 characters"),

    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords does not match"
    ),
    defaultPracticeId: Yup.string().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
  const onSubmit = data => {
    var selectedIds = [];
    var unSelectedIds = [];
    selectedUserIds.forEach(field => {
      if (typeof field.value != undefined && field.value != null)
        selectedIds.push(field.value);
      else selectedIds.push(field.id);
    });
    let postData = data;
    console.log("checck selct postData___", postData);
    postData.linkedUsersId = selectedIds;
    console.log("checck selct id________", postData.linkedUsersId);
    postData.unlinkedUsersId = [];
    postData.userType = userType ? userType : "";
    if (isPasswordChange) {
      postData.password = md5(postData.password);
      postData.confirmPassword = md5(postData.confirmPassword);
    } else {
      postData.password = postData.password;
      postData.confirmPassword = postData.confirmPassword;
    }
    if (postData.userType == "" || postData.userType == null) {
      setselectUserError(true);
      return;
    }

    setSubmitting(true);
    seterrorReceived("");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/userRegistration`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/User");
            }
          });
        } else if (response.length > 0) {
          seterrorReceived(response.data);
          setSubmitting(false);
          return;
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const handleUserPractice = event => {
    // console.log('userPractice: ', userPractice)
    event.preventDefault();
    if (
      userPractice == null ||
      userPractice == undefined ||
      userPractice <= 0
    ) {
      setPracticeIDError(true);
      return;
    }
    if (
      userProvider == null ||
      userProvider == undefined ||
      userProvider <= 0
    ) {
      setProviderIdError(true);
      return;
    }
    //
    const duplicate = rows.filter(newVal => {
      return newVal.practiceId == userPractice;
      // comparing category for displaying data
    });
    if (duplicate !== null && duplicate != undefined && duplicate.length > 0) {
      setDuplicateError(true);
      return;
    }
    const postData = {
      UserEmail: Emailaddress,
      // UserName: userName,
      facilityId: userFacility,
      practiceId: userPractice,
      providerId: getValues("provider_id")
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/UserPractices/addUserPractices`,
        postData,
        {
          headers
        }
      )
      .then(response => {
        setSubmitting(true);
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            window.location.reload(false);
          //  setRefresh(!refresh)
          }
        });
      })
      .catch(error => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error"
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUserById?id=${_userId}`, {
        headers
      })
      .then(response => {
        console.log("response.data: ", response);
        setuserType(response["userType"]);
        fields.forEach(field => {
          setValue(field, response[field]);
          setProviderType(response.userType);
          setEmailaddress(response.email)
          if (field === "userName") {
            setuserName(response[field]);
          }
          if (field === "password") {
            setValue("confirmPassword", response[field]);
          }
        });
        setPracticeID(response.defaultPracticeId);

        setDefaultPracticeErrorId(response.defaultPracticeId);
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Practices/getPractices`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.practiceName,
            value: field.id
          });
        });
        setpracticesList(prac);
        // response.forEach((field) => {
        //   practicesList.push({
        //     label: field.practiceName,
        //     value: field.id,
        //   });
        // });
      })
      .catch(error => {});

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  useEffect(
    () => {
      // POST request using axios inside useEffect React hook
      let isComponentMounted = true;
      const fetchData = async () =>
        axios
          .get(
            `${process.env
              .REACT_APP_API_URL}/UserPractices/getUserPractices?userEmail=${Emailaddress}`,
            { headers }
          )
          .then(response => {
            var prac = [];
            if (isComponentMounted) {
            //   response.forEach(field => {
            //     practiceslistid.push({
            //       label: field.practiceName,
            //       value: field.practiceId
            //     });
            //   });
        response.forEach(field => {
          prac.push({
            label: field.practiceName,
            value: field.practiceId
          });
        });
        setpracticeslistid(prac);
            }
          })
          .catch(error => {});
      fetchData();
      return () => {
        isComponentMounted = false;
      };

      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    },
    [Emailaddress, refresh]
  );
  useEffect(
    () => {
      // POST request using axios inside useEffect React hook
      axios
        .get(
          `${process.env
            .REACT_APP_API_URL}/UserPractices/getUserPractices?userEmail=${Emailaddress}`,
          { headers }
        )
        .then(response => setRows(response))
        .catch(error => {});

      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    },
    [userName, refresh]
  );
  useEffect(
    () => {
      setProviders([]);
      setValue("provider_id", null);
      // if (userPractice != null || userPractice != undefined || userPractice > 0) {
      // POST request using axios inside useEffect React hook
      axios
        .get(
          `${process.env
            .REACT_APP_API_URL}/Providers/getPracticeProvider?parcticeid=${practiceID}`,
          { headers }
        )
        .then(response => {
          var prov = [];
          response.forEach(field => {
            prov.push({
              label: field.name,
              value: field.id
            });
          });
          setProviders(prov);
        })
        .catch(error => {});
      // }
      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    },
    [practiceID]
  );
  const [images, setImages] = React.useState(null);
  ///Delete Image
  const deleteimg = () => {
    setImages(null);
    setValue("photoUrl", "");
  };
  ///Upload Image
  const onImageChange = e => {
    setImages(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    convertBase64(file);
    // console.log("64 image",base64)
    console.log(
      "Kdr Sy aya ha Console",
      URL.createObjectURL(e.target.files[0])
    );
  };
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    }).then(users => {
      setValue("base64", users);
    });
  };
  const Input = styled("input")({
    display: "none"
  });

  const [open, setOpen] = React.useState(false);

  function handleCancel() {
    navigate("/User");
  }
  const [tabValue, settabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    settabValue(newValue);
    setValue("defaultPracticeId", "");
    setValue("provider_id", "");
    setPracticeID("");
    setProviderIdError(false);
    setPracticeIDError(false);
    setDefaultPracticeError(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
        
          <Typography variant='h5' color='black' fontWeight="bold">User Registration</Typography>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab value={0} label="USER INFO" />
                <Tab value={1} label="PRACTICES" />
              </TabList>
            </Box>
            <TabPanel value={0}>
              <Grid container columnSpacing={1}>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <label htmlFor="icon-button-file">
                        <Input
                          id="icon-button-file"
                          type="file"
                          onChange={onImageChange}
                        />
                        <Avatar
                          sx={{ bgcolor: "#FCBE13" }}
                          src={images}
                          style={{
                            height: 120,
                            width: 120,
                            borderRadius: "100px"
                          }}
                        >
                          <AddAPhotoIcon
                            fontSize="large"
                            style={{ color: "black", marginBottom: "5px" }}
                          />
                        </Avatar>
                      </label>
                    </Grid>
                    {images && images.length > 0
                      ? <Button
                          onClick={deleteimg}
                          variant="gradient"
                          // disabled={submitting}
                          style={{
                            marginLeft: "20px",
                            height: "35px",
                            width: "70px",
                            color: "black",
                            fontSize: "14px"
                          }}
                          // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                        >
                          <DeleteOutlinedIcon
                            fontSize="medium"
                            style={{ color: "red", marginBottom: "5px" }}
                          />
                          Remove
                        </Button>
                      : null}
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={6}>
                      <Typography
                      component='label'
                        color="black"
                        variant="caption"
                        fontSize="15px"
                      >
                        Full Name
                      </Typography>
              <label   style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>

                      
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                      component='label'
                        color="black"
                        variant="caption"
                        fontSize="15px"
                      >
                        Email
                      </Typography>
              <label   style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>

                    </Grid>
                    <Grid item xs={6} style={{ width: "300px" }}>
                      <Controller
                        name="fullName"
                        {...register("fullName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter FullName"
                            {...field}
                            fullWidth
                            error={errors.fullName}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.fullName?.message}</p>

                    </Grid>
                    <Grid item xs={6} style={{ width: "300px" }}>
                      <Controller
                        name="email"
                        {...register("email")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter email"
                            {...field}
                            fullWidth
                            error={errors.email}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.email?.message}</p>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        color="black"
                        variant="caption"
                        fontSize="15px"
                      >
                        Password
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ width: "300px" }}>
                      <Typography
                        color="black"
                        variant="caption"
                        fontSize="15px"
                      >
                        Confirm Password
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ width: "300px" }}>
                      <Controller
                        name="password"
                        {...register("password")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter Password"
                            {...field}
                            type="password"
                            onChange={e => {
                              setValue("password", e.target.value);
                              setIsPasswordChange(true);
                            }}
                            fullWidth
                            error={errors.password}
                          />}
                      />
                     
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.password?.message}</p>
                    </Grid>
                    <Grid item xs={6} style={{ width: "300px" }}>
                      <Controller
                        name="confirmPassword"
                        {...register("confirmPassword")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter ConfirmPassword"
                            {...field}
                            fullWidth
                            type="password"
                            error={errors.confirmPassword}
                          />
                        }
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.confirmPassword?.message}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container columnSpacing={1}>
              <Grid item xs={4} style={{ width: "300px" }}>
                   
                  <Typography  component='label' color="black" variant="caption" fontSize="15px" >
                    UserName
                  </Typography>
              <label   style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>

                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Typography color="black" variant="caption" fontSize="15px">
                    UserType
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Typography color="black" variant="caption" fontSize="15px">
                    Practices
                  </Typography>
                </Grid>
                
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Controller
                    name="userName"
                    {...register("userName")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Please enter UserName"
                        {...field}
                        fullWidth
                        error={errors.userName}
                      />
                     

                    }
                  />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.userName?.message}</p>

                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Controller
                    name="userType"
                    margin="dense"
                    {...register("userType")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        style={{ marginTop: 0, marginBottom: 0 }}
                        {...field}
                        size="small"
                        options={[
                          { value: "Admin", label: "Admin" },
                          { value: "Front Office Admin", label: "Front Office Admin" },
                          { value: "Front Office", label: "Front Office" },
                          { value: "Back Office Admin", label: "Back Office Admin" },
                          { value: "Back Office User", label: "Back Office User" },

                        ]}
                        value={{ label: getValues("userType") }}
                        onChange={value => {
                          setValue("userType", value.value);
                          setuserType(value.value);
                          setProviderType(value.label);
                        }}
                      />}
                  />
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Controller
                    name="defaultPracticeId"
                    {...register("defaultPracticeId")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        value={practiceslistid.filter(function(option) {
                          return (
                            option.value === getValues("defaultPracticeId")
                          );
                        })}
                        // value={{ label: getValues('defaultPracticeId') }}
                        onChange={e => {
                          setValue("defaultPracticeId", e.value);
                          setPracticeID(e.value);
                          setDefaultPracticeErrorId(e.value);
                          setDefaultPracticeError(false);
                        }}
                        options={practiceslistid}
                      />}
                      error={errors.defaultPracticeId} 
                  />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.defaultPracticeId?.message}</p>
                </Grid>
               
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Typography color="black" variant="caption" fontSize="15px">
                  Address
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Typography color="black" variant="caption" fontSize="15px">
                    ZIP Code
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Typography color="black" variant="caption" fontSize="15px">
                    City
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Controller
                    name="address"
                    {...register("address")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Please enter Address"
                        {...field}
                        fullWidth
                      />}
                  />
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Controller
                    name="zipCode"
                    {...register("zipCode")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="Please enter zipCode"
                        {...field}
                        fullWidth
                      />}
                  />
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Controller
                    name="city"
                    {...register("city")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        // isClearable
                        size="small"
                        placeholder="Please enter City"
                        {...field}
                        fullWidth
                      />}
                  />
                </Grid>
               
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Typography color="black" variant="caption" fontSize="15px">
                    State
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ width: "300px" }}>
                  <Typography color="black" variant="caption" fontSize="15px">
                    Reset Password
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }}>
                  <Controller
                    name="Sate"
                    {...register("state")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        // isClearable
                        size="small"
                        placeholder="Please enter State"
                        {...field}
                        fullWidth
                      />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <input
                    name="resetPassword"
                    type="checkbox"
                    {...register("resetPassword")}
                    id=" resetPassword"
                  />
                </Grid>
                <Grid item xs={4} style={{ width: "300px" }} />
         
                <Grid item xs={12}>
                  <Button
                  className= "Button"
                    onClick={handleCancel}
                    variant="gradient"
                    disabled={submitting}
                    style={{
                      marginTop: "50px",
                      float: "right",
                      width: "90px",
                      height: "35px",
                      backgroundColor: "#A574FD",
                      color: "white",
                      fontSize: "14px"
                    }}
                  >
                    <CancelOutlinedIcon
                      fontSize="medium"
                      style={{ color: "white", paddingRight: "5px" }}
                    />
                    Cancel
                  </Button>
                  <Button
                  className= "Button"
                    onClick={handleSubmit(onSubmit)}
                    variant="gradient"
                    disabled={submitting}
                    style={{
                      marginTop: "50px",
                      float: "right",
                      marginRight: "13px",
                      width: "80px",
                      height: "35px",
                      backgroundColor: "#A574FD",
                      color: "white",
                      fontSize: "14px"
                    }}
                  >
                    <SaveOutlinedIcon
                      fontSize="medium"
                      style={{
                        color: "white",
                        paddingRight: "5px",
                        paddingBottom: "2px"
                      }}
                    />
                    Save
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={1}>
              <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
                style={{
                  paddingTop: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px"
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ width: "300px", alignText: "right" }}
                >
                  <Typography align="right" style={{marginTop:'4px'}}>Practice</Typography>
                </Grid>
                <Grid item xs={3} style={{ width: "300px" }}>
                  <Controller
                    name="UserPractice"
                    {...register("UserPractice")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        onChange={e => {
                          setPracticeIDError(false);
                          setUserPractice(e.value);
                          console.log("Userpractices", e.value);
                          setDuplicateError(false);
                          setPracticeID(e.value);
                        }}
                        options={practicesList}
                      />
                      // <ReactSelect
                      //   onChange={(e) => setUserPractice(e.value)}
                      //   options={practicesList}
                      // />
                    }
                  />
                </Grid>
                <Grid item xs={1} style={{ width: "300px" }}>
                  <Typography style={{marginTop:'4px',marginLeft:'10px'}}>Provider</Typography>
                </Grid>
                <Grid item xs={3} style={{ width: "300px" }}>
                  <Controller
                    name="provider_id"
                    {...register("provider_id")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        default
                        value={providers.filter(function(option) {
                          return option.value === getValues("provider_id");
                        })}
                        onChange={value => {
                          setValue("provider_id", value.value);
                          setUserProvider(value.value);
                          setProviderIdError(false);
                        }}
                        options={providers}
                      />}
                  />
                </Grid>
                <Grid item xs={2} style={{ width: "300px" }}>
                  <Button
                  className="Button"
                    onClick={handleUserPractice}
                    sx={{
                      width: 200,
                      float: "right",
                      marginRight: "50px"
                    }}
                    disabled={submitting}
                    variant="gradient"
                    color="info"
                    size="small"
                    style={{
                      width: "70px",
                      height: "33px",
                      // backgroundColor: "#FCBE13",
                      color: "white",
                      fontSize: "14px"
                    }}
                    // color="warning"
                    // size="small"

                    // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                  >
                    <AddOutlinedIcon
                      fontSize="medium"
                      style={{
                        color: "white",
                        paddingRight: "4px",
                        paddingBottom: "2px"
                      }}
                    />
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                alignContent="left"
                justifyContent="left"
                style={{
                  // paddingTop: '5px',
                  paddingLeft: "100px",
                  paddingRight: "100px"
                }}
              >
                <Grid item xs={2} />
                <Grid item xs={5}>
                  {" "}{practiceIdError
                    ? <Typography
                        color="error"
                        style={{
                          fontSize: "14px",
                          marginTop: "15px",
                          marginLeft: "5px"
                        }}
                      >
                        Please Select Practice!
                      </Typography>
                    : null}
                  {duplicateError
                    ? <Typography
                        color="error"
                        style={{
                          fontSize: "14px",
                          marginTop: "15px",
                          marginLeft: "5px"
                        }}
                      >
                        Practice Already Added! Please Select Another
                      </Typography>
                    : null}
                </Grid>
                <Grid item xs={5}>
                  {" "}{providerIdError
                    ? <Typography
                        color="error"
                        style={{
                          fontSize: "14px",
                          marginTop: "15px",
                          marginLeft: "5px"
                        }}
                      >
                        Please Select Provider!
                      </Typography>
                    : null}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div style={{ height: 400, width: "100%", marginTop: "50px" }}>
                  {rows === null || rows.length === 0
                    ? null
                    : <DataGrid
                        rows={rows}
                        columns={columns}
                        rowHeight={25}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={gridRowStyle}
                      />}
                </div>
              </Grid>
            </TabPanel>
          </TabContext>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default UserRegistration;
