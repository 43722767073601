import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import { Root, customStyles } from "app/shared-components/CustomConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import * as yup from "yup";
import moment from "moment";
import ReactSelect from "react-select";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AppointmentCreate from "../AppointmentCreate";
import { Input, InputLabel } from '@mui/material';
import { Calendar } from "react-date-range";


const Scheduler = () => {
  document.title = "Scheduler | Sterling Center";
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const username = localStorage.getItem('UserName')
  const UserType = localStorage.getItem('User_Type')
  const Userid = localStorage.getItem('userid')


  const customStyles = {
    marginTop: "1px",
    height: "15px",
    control: (provided) => ({
      ...provided,
      color: "black",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues,
  });
  const [refresh, setRefresh] = useState(false)
  const [applyRefresh, setapplyRefresh] = useState(false);
  const applyRefreshh = () => {
    setapplyRefresh(!applyRefresh)
  }
  const errorMesage = {
    color: "red",
    fontSize: "13px",
    marign: "0px",
  };
  const viewNamesObj = {
    dayGridMonth: {
      title: "Month",
      icon: "view_module",
    },
    timeGridWeek: {
      title: "Week",
      icon: "view_week",
    },
    timeGridDay: {
      title: "Day",
      icon: "view_agenda",
      allDays: "false"
    },
  };
  const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #3C78C7s',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow: 'auto',
    zIndex: 300000
  };
  const zipCodeListStyle = {
    cursor: "pointer",
    hover: {
      background: "#3b3b3b !important",
      color: "rgba(5, 152, 236, 0.637) !important",
    },
    color: "green",
    selection: {
      background: "#D7624E",
      color: "white",
    },
  };
  const [SuperviseID, setSuperviseID] = useState(Userid);
  const [TherapistID, setTherapistID] = useState(Userid);

  const calendarRef = useRef();
  const [currentDate, setCurrentDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  console.log('events', events)
  const [isEventDialogOpen, setEventDialogOpen] = useState(false);

  const [AgencyList, setAgencyList] = useState([]);
  const [sTime, setSTime] = useState(null);
  const [eTime, setETime] = useState(null);
  const [recurringEndDateTime, setRecurringEndDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [durationError, setdurationError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);


  const [ProviderId, setProviderId] = useState('');
  const [officeId, setOfficeId] = useState('');

  const [genernal, setgenernal] = useState('');


  const [gentype, setgentype] = useState([]);
  const [office, setOffice] = useState([]);
  const open = Boolean(anchorEl);
  const openOnhover = Boolean(anchorE2);

  const [appid, setappid] = useState(0);
  console.log('checktest', appid)

  const handleDateSelect = (selectInfo) => {

    console.log("Selected Days keya hy", selectInfo);
    const { start, end } = selectInfo;
    // Extract and format time
    const startTime = moment(start, "HH:mm").format("HH:mm:ss");
    const endTime = moment(end, "HH:mm").format("HH:mm:ss");

    setCurrentDate(selectInfo);
    setSTime(startTime);
    setETime(endTime);
    setEventDialogOpen(true);
    setappid(0)
  };
  const handleEventClick = (clickInfo) => {
    console.log('check', clickInfo)
    setappid(clickInfo.event._def.publicId)
    setSelectedEvent(clickInfo.event);
    setEventDialogOpen(true);
  };
  const handleClickhover = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClosehover = () => {
    setAnchorE2(null);
  };


  const handleDates = (rangeInfo) => {
    console.log(rangeInfo, "range info ya rahi");

    const { start, end } = rangeInfo;

    const startDateRangeFormat = moment(start, "YYYY/MM/DD").format(
      "YYYY/MM/DD"
    );
    const endDateRangeFormat = moment(end, "YYYY/MM/DD")
      .subtract(1, "seconds")
      .format("YYYY/MM/DD");

    setStartDateRange(startDateRangeFormat);
    setEndDateRange(endDateRangeFormat);
    setCurrentDate(rangeInfo);
  };

  const handleEventAdd = (addInfo) => {
    // Handle event add logic if needed
    console.log("Added event:", addInfo.event);
  };

  const handleEventChange = (changeInfo) => {
    // Handle event change logic if needed
    console.log("Changed event:", changeInfo.event);
  };

  const closeEventDialog = () => {
    setEventDialogOpen(false);
  };

  const theme = useTheme();
  const calendarApi = calendarRef.current?.getApi();
  console.log('calendarApi', calendarApi)
  const handlePrevClick = () => {
    if (calendarApi) {
      calendarApi.prev();
    }
  };

  const handleNextClick = () => {
    if (calendarApi) {
      calendarApi.next();
    }
  };

  const handleTodayClick = () => {
    if (calendarApi) {
      calendarApi.today();
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeView = (name) => {
    // console.log("changeview called ", name);
    if (calendarApi && calendarApi.changeView) {
      calendarApi.changeView(name);
      handleClose();
    }
  };



  useEffect(() => {
 
    if (startDateRange !== null && endDateRange !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Appointment/getAppointments?appointmentType=${genernal}&providerId=${ProviderId}&locationId=${officeId}&startDate=${startDateRange}&endDate=${endDateRange} `,
          {
            headers,
          }
        )
        .then((response) => {
          // console.log("Get visit with date response", response.data);

          var res = response;
          var arr = [];
          console.log('test1', response)
          console.log('test111', arr)

          res.forEach((e) => {
            arr.push({
              id: e.id,
              title: e.patientName,
              // SuperviseName: e.supervisorName,
              reason: e.appointmentReason,


              // Name:'qasim',
              start: new Date(e.startTime).toISOString(),
              end: new Date(e.endTime).toISOString(),
            });
          });

          // console.log('arrr ma keya data ata hy', arr)
          setEvents(arr);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [appid, gentype, ProviderId, officeId, applyRefresh, startDateRange, endDateRange]);

  const [providerList, setProviderList] = useState([]);
  const practiceId = localStorage.getItem('default_paractice')
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Providers/getProviders?practiceId=${practiceId}`, {
        headers
      })
      .then(response => {
        const prac = [
          { label: 'All', value: null, isTitle: true } // Adding a non-selectable title
        ];
        response.forEach(field => {
          prac.push({
            label: `${field.firstName} ${field.lastName}`,
            value: field.id
          });
        });
        setProviderList(prac);
console.log(prac,'login Success +++:  ')

      })
      .catch(error => { });

  }, []);
  return (
    <>
      <div style={{
        padding: '80px 28px 0px 0px'
      }}>
        <Grid container spacing={1}>
          <Grid item xs={1.5} sm={1.5} xl={1.5}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
              {currentDate?.view.title}
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}>
            <Tooltip title="Previous">
              <ArrowBackIosIcon onClick={handlePrevClick} style={{ fontSize: 'medium' }} >
                {theme.direction === "ltr"
                  ? "heroicons-solid:chevron-left"
                  : "heroicons-solid:chevron-right"}
              </ArrowBackIosIcon>
            </Tooltip>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}>
            <Tooltip title="Next">
              <NavigateNextIcon onClick={handleNextClick} >
                {theme.direction === "ltr"
                  ? "heroicons-solid:chevron-right"
                  : "heroicons-solid:chevron-left"}
              </NavigateNextIcon>
            </Tooltip>
          </Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5}>

            <Tooltip title="Today">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.3 } }}
              >
                <CalendarMonthIcon
                  aria-label="today"
                  onClick={handleTodayClick}
                  size="large"
                >
                </CalendarMonthIcon>
              </motion.div>
            </Tooltip>
          </Grid>
          <Grid item xs={1} >
            <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginTop: '5px' }}>
              Provider
            </Typography>
          </Grid>
          <Grid item xs={2.5} sm={2.5} xl={2.5}>
          <Controller
                name="providerId"
                {...register("providerId")}
                control={control}
                render={({ field }) =>
                  <ReactSelect
                    {...field}
                    fullWidth
                    value={providerList.find(option => option.value === ProviderId)}
                    isOptionDisabled={(option) => option.isTitle}
                    onChange={(selectedOption) => {
                      setValue("providerId", selectedOption.value);
                      setProviderId(selectedOption.value);
                    }}
                    styles={{
                      //  control: (provided) => ({
                      //    ...provided,
                      //    zIndex: 9999, // Set a high z-index value for the control
                      //  }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 1000, // Set a high z-index value for the dropdown menu
                      }),
                    }}

                    options={providerList}
                  />
                 
                }
              />
          </Grid>


          <Grid item xs={3} ></Grid>
          <Grid item xs={2} sm={2} xl={2}>
            <motion.div
              style={customStyles}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
              <Button

                sx={{ minWidth: 90, float: 'right' }}
                className="Button"
                id="view-select-button"
                aria-controls="view-select-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                {viewNamesObj[currentDate?.view.type]?.title}
              </Button>
              <Menu
                id="view-select-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "view-select-button",
                }}
              >
                {Object.entries(viewNamesObj).map(([name, view]) => (
                  <MenuItem key={name} onClick={() => handleChangeView(name)}>
                    <ListItemText
                      primary={view.title}
                      style={{ width: "150px" }}
                    />
                  </MenuItem>
                ))}
              </Menu>

            </motion.div>
          </Grid>


        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} xl={12} style={{ marginTop: '50px' }}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={false}
              initialView="timeGridDay"
              // editable
              selectable
              selectMirror
              dayMaxEvents
              weekends
              slotDuration="00:15:00"
              snapDuration="00:15:00"
              slotMinTime="00:00:00"
              slotMaxTime="24:00:00"
              nowIndicator={true}
              showNonCurrentDates={false}
              clickToCreate={false}
              datesSet={handleDates}
              select={handleDateSelect}
              events={events}

              eventContent={(eventInfo) => {
                const isTimeGridDayView =
                  eventInfo.view.type === "timeGridDay" || eventInfo.view.type === "timeGridWeek";

                return (
                  <Box >
                    <Grid container spacing={1} style={{ backgroundColor: '#56B298' }}>
                      <Grid item xs={12} sm={12} xl={12} >
                        <Typography style={{ color: 'white', textAlign: "left", fontWeight: 'bold' }}>
                          {eventInfo.event.title}
                        </Typography>
                      </Grid>

                      {isTimeGridDayView && (
                        <Grid item xs={12} sm={12} xl={12}>
                          <Typography style={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }}>
                            {eventInfo.event.extendedProps.reason}
                          </Typography>
                        </Grid>
                      )}


                    </Grid>
                  </Box>
                );
              }}

              eventClick={handleEventClick}
              eventAdd={handleEventAdd}
              // onEventDoubleClick={handleEventClick}
              eventChange={handleEventChange}
              initialDate={new Date()}
              ref={calendarRef}
            />
          </Grid>

        </Grid>
        <Modal
          open={isEventDialogOpen}
          onClose={closeEventDialog}
          className="glassmorphic-card"
        >
          <Box
            sx={modalstyle}
          >
            <AppointmentCreate
              CloseModal={closeEventDialog}
              appid={appid}
              sTime={sTime}
              eTime={eTime}
              SuperviseID={SuperviseID}
              ProviderId ={ProviderId}
              applyRefresh={applyRefreshh}
              TherapistID={TherapistID}
            ></AppointmentCreate>


          </Box>
        </Modal>

      </div>


    </>
  );
}

export default Scheduler;
