import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Container } from "reactstrap";
import { Box, Button, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Controller, useForm } from 'react-hook-form'
// import { useRouter, withRouter } from 'next/router'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import TabPanel from "@mui/lab/TabPanel";

import Moment from 'moment'
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
// import { createBrowserHistory } from 'history'
// import { useHistory } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DeleteIcon from '@mui/icons-material/Delete'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import "../Button.css"
import ComItem from '../ComItem';
// const history = useHistory();
const Todolist = () => {
  
  const [isLoading, setLoading] = useState(false)
  document.title = "Todolist | LuxBilling";

  // document.title = "Dashboard | Upzet - React Admin & Dashboard Template";

  // const auth = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJqdGkiOiIwZmRiNWIxMy0wYzExLTQ4ZTQtOTdmMy1hZjhlOGNmOTJlMjgiLCJ2YWxpZCI6IjEiLCJ1c2VyRW1haWwiOiJhbGlAZ21haWwuY29tIiwicHJhY3RpY2VJZCI6IjEiLCJleHBpcnlEYXRlIjoiNy8zLzIwMjgiLCJicm93c2VyIjoiR29vZ2xlIENocm9tZSIsImFwcGxpY2F0aW9uIjoiTHV4QmlsbGluZyIsIklzQWRtaW4iOiJGYWxzZSIsIlByb3ZpZGVySWQiOiIwIiwiZXhwIjoxODQ2MDgwNTE2LCJpc3MiOiJodHRwOi8vd3d3Lkx1eEJpbGxpbmcuY29tIiwiYXVkIjoiaHR0cDovL3d3dy5MdXhCaWxsaW5nLmNvbSJ9.2Uac6a-c-IPRJFzYSvjxpRych9icwhsebjvB2lgZaOs`
  const navigate = useNavigate();
  const [rows, setRows] = useState(null) 
  const [rows1, setRows1] = useState(null) 
  const [resolved, setresolved] = useState(false) 
console.log('resolved',resolved)
  const [refresh, setRefresh] = useState(false)

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const [opentodolist, setopentodolist] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const fields = [
    'id',
    'patientId',
    'patientNoteId',
    'name',
    'shortName',
    'type',
    'phoneNo',
    'fax',
    'emailAddress',
    'city',
    'state',
    'zipCode',
    'deleted',
    'createdBy',
    'createdDate',
    'updatedBy',
    'updatedDate',
    'clientCreatedDate',
    'clientModifiedDate',
    'defaultPOS',
    'practice_id',
    'defaultFacility',
    'npi',
    'fullName',
    'address',
    'date',
    'patientName',
    'comments',
    'dueDate',
    'assignedTo',
    'archive',
    'allData'
  ]
  const defValues = {
    id: 0,
    patientName: '',
    patientId: '',
    patientNoteId: 0,
    address: '',
    fullName: '',
    shortName: '',
    type: '',
    phoneNo: '',
    fax: '',
    emailAddress: '',
    city: '',
    state: '',
    zipCode: '',
    deleted: false,
    createdBy: '',
    practice_id: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    clientCreatedDate: '',
    clientModifiedDate: '',
    defaultPOS: '',
    defaultFacility: '',
    npi: '',
    fullName: '',
    address: '',
    date: '',
    comments: '',
    dueDate: '',
    assignedTo: '',
    archive: '',
    allData: ''
  }
  const [commentId, setID] = React.useState('')
  const columns1 = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.2,
    },
    
    {
        field: 'patientName',
        headerName: 'Patient Name',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.5,
        renderCell: (cellValues) => (
            <Button
            style={{ display: "flex", justifyContent: "flex-start"}}
              size="small"
              variant="text"
              color="info"
              onClick={(event) => {
                // openPracticesRegistration(cellValues.row.id )
                // setopentodolist(true)
                setOpens(true)
                setID(cellValues.row.id)
                setPatientName(cellValues.row.patientName)
                setPatientID(cellValues.row.patientId)
                setresolved(cellValues.row.archive)
              }}
            >{`${cellValues.row.patientName}`}</Button>
          ),
      },
      {
        field: 'assignedTo',
        headerName: 'Assigned To',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.8,
      },
      {
        field: 'Comments',
        headerName: 'Message',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 1,
      },
      {
        field: 'dueDate',
        headerClassName: 'super-app-theme--header',
        headerName: 'Due Date',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.5,
      },
      {
        field: 'deleted',
        headerClassName: 'super-app-theme--header',
        headerName: '',
        sortable: true,
        flex: 0.1,
        // hide: true,
        // hide: Usertype == 'Admin'?false:true,
        
        renderCell: cell => (
        
          <DeleteIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
              cursor: 'pointer'
            }}
               
            onClick={cellValues => {
            
              confirmAlert({
                message: 'Do you want to delete the record.',
                closeOnEscape: true,
                closeOnClickOutside: true,
                overlayClassName: 'overlay-custom-class-name',
  
                buttons: [
                  {
                    label: 'Yes',
                   
                    
                    onClick: () => {
                      const postData = {
                        id: cell.row.id,
                        deleted: true,
                      
                      }
                     
                      axios
                        .post(`${process.env.REACT_APP_API_URL}/PatientToDoList/AddPatientToDoList`, postData, {
                          headers
                        })
                        .then(response => {
                          setRefresh(!refresh)
                          // setOpenNote(false)
                          // console.log('response.data: ', response.data)
                        })
                        .catch(error => {
                          console.error('There was an error!', error)
                        })
                    }
                  },
                  {
                    label: 'No'
                  }
                ]
              })
             
            }}
          ></DeleteIcon>
        )
      }

  ]
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.2,
    },
    
    {
        field: 'patientName',
        headerName: 'Patient Name',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.5,
        renderCell: (cellValues) => (
            <Button
            style={{ display: "flex", justifyContent: "flex-start"}}
              size="small"
              variant="text"
              color="info"
              onClick={(event) => {
                // openPracticesRegistration(cellValues.row.id )
                // setopentodolist(true)
                setOpens(true)
                setID(cellValues.row.id)
                setPatientName(cellValues.row.patientName)
                setPatientID(cellValues.row.patientId)
                setresolved(cellValues.row.archive)
              }}
            >{`${cellValues.row.patientName}`}</Button>
          ),
      },
      {
        field: 'assignedTo',
        headerName: 'Assigned To',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.8,
      },
      {
        field: 'Comments',
        headerName: 'Message',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 1,
      },
      {
        field: 'dueDate',
        headerClassName: 'super-app-theme--header',
        headerName: 'Due Date',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.5,
      },
      {
        field: 'deleted',
        headerClassName: 'super-app-theme--header',
        headerName: '',
        sortable: true,
        flex: 0.1,
        // hide: true,
        // hide: Usertype == 'Admin'?false:true,
        
        renderCell: cell => (
        
          <DeleteIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
              cursor: 'pointer'
            }}
               
            onClick={cellValues => {
            
              confirmAlert({
                message: 'Do you want to delete the record.',
                closeOnEscape: true,
                closeOnClickOutside: true,
                overlayClassName: 'overlay-custom-class-name',
  
                buttons: [
                  {
                    label: 'Yes',
                   
                    
                    onClick: () => {
                      const postData = {
                        id: cell.row.id,
                        deleted: true,
                      
                      }
                     
                      axios
                        .post(`${process.env.REACT_APP_API_URL}/PatientToDoList/AddPatientToDoList`, postData, {
                          headers
                        })
                        .then(response => {
                          setRefresh(!refresh)
                          // setOpenNote(false)
                          // console.log('response.data: ', response.data)
                        })
                        .catch(error => {
                          console.error('There was an error!', error)
                        })
                    }
                  },
                  {
                    label: 'No'
                  }
                ]
              })
             
            }}
          ></DeleteIcon>
        )
      }

  ]
  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  const alert = useAlert()
  const [patientData, setPatientData] = React.useState([])
  const [patient, setPatient] = React.useState(false)
  const [patientSelected, setPatientSelected] = React.useState(false)
  const [patientID, setPatientID] = React.useState('')
  const [dueDate, setDuedate] = React.useState()
  
  const [searchText, setSearchText] = useState('')
  const [patientName, setPatientName] = useState('')
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
//    const openPracticesRegistration = (event, id) => {
//     setopentodolist(true)
//   }

  // const handleClose = () => {
  //   setopentodolist(false)
  //   setrefresh()
  // }
  function setrefresh() {
    setRefresh(!refresh)
  }
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientToDoList/PatientToDoList?Archive=${false}`, {
        headers
      }
      )
      .then((response) => { 
        setRows(response) 
     
        setLoading(false)
      })
     
      .catch((error) => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientToDoList/PatientToDoList?Archive=${true}`, {
        headers
      }
      )
      .then((response) => { 
        setRows1(response) 
        setLoading(false)
      })
     
      .catch((error) => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])

  const checkstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 250,
    bgcolor: "background.paper",
    border: "2px solid #3C7F6B",
    boxShadow: 24,
    
  };
  
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    console.warn('Data: ', postData)
    // if (PracticeId !== undefined && PracticeId != null)
    //     postData.Id = PracticeId
    // else postData.Id = 0
    axios
        .post(
            `${process.env.REACT_APP_API_URL}/PatientToDoList/AddPatientToDoList`,
            postData,
            { headers }
        )
        .then((response) => {
            setSubmitting(false)
            alert.success('Record saved successfully.', {
                type: 'success',
                onClose: () => {
                    // navigate('/Practices')
                    handleClose()
                },
            })
        })
        .catch((error) => {
            setSubmitting(false)
            alert.success(`Error ${error.message}`, {
                type: 'error',
            })
        })
}
  const onChangeHandle = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    setLoading(true);
    console.log(value)
    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: '',
      dob: '',
      patientId:'',
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(false)
      )
      .then(response => {
        setPatientData(response)
        setPatient(true)
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error)
        setLoading(false);
      })

  }
  const PatientStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  }
  const [opens, setOpens] = React.useState(false)
  const handleOpen = () => setOpens(true)
  const handleClose = () => {
    setOpens(false)
    setRefresh(!refresh)
    reset()
  }
  return (
     
      <div className="page-content">
        <Container  >
        <Modal
        id="hello-world"
          open={opens}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle}>

      
           
                <ComItem
                patientId={patientID}
                patientName={patientName}
                commentId={commentId}
                handleClose={handleClose}
                resolved={resolved}
                ></ComItem>
              
          </Box>
        </Modal>
        <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>
                  <Grid>
                    <Button
                    sx={{ mb: 1 }} className="Button" 
                    onClick={() => {
                      reset()
                      fields.forEach(field => setValue(field, ''))
                      setID(0)
                      // if (props.patientId > 0) console.log('patient Name______', props.patientName)
                      setSubmitting(false)
                      setPatientName('')
                      handleOpen()
                      // setdueDate('')
                    }}
                      // onClick={setopentodolist}
                      variant="contained"
                    >
                        <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                      Add New
                    </Button>
                  </Grid>
                  </div>
                  <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="lab API tabs example"
              >
                <Tab value={0} label="New" />
                <Tab value={1} label="Completed" />

              </TabList>
            </Box>
            <TabPanel value={0}>
            <Box mb={3} marginTop={2}>
          {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
            <div style={{ height: 400, width: "100%" }}>
              {rows === null || rows.length === 0 ? (
            <Typography>No Record found</Typography>
          ) : (
                <DataGrid
                rows={rows}
               classes={gridClasses}
                rowHeight={25}
                columns={columns}
               
                fullWidth
                pagination
            autoPageSize
                sx={gridRowStyle}
              />
              )}
            </div>
             )}
          </Box>
            </TabPanel>
            <TabPanel value={1}>
            <Box mb={3} marginTop={2}>
          {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
            <div style={{ height: 400, width: "100%" }}>
              {rows1 === null || rows1.length === 0 ? (
            <Typography>No Record found</Typography>
          ) : (
                <DataGrid
                rows={rows1}
                classes={gridClasses}
                rowHeight={25}
                columns={columns1}
               
                fullWidth
                pagination
            autoPageSize
                sx={gridRowStyle}
              />
              )}
            </div>
             )}
          </Box>
            </TabPanel>
                  </TabContext>
           
         
        </Container>
      </div>

  );
};

export default Todolist;
